import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
import ToptraderListItem from "./ToptraderListItem";
import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import glovar from "./glovar";
//import gsap from "gsap";

export default ({ }) => {
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  /*const todoTopTraders = [
    { id:1, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:2, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:3, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:4, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:5, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:6, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:7, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:8, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:9, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:10, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:12, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:12, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:13, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:14, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:15, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:16, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:17, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:18, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:19, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:20, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:21, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:22, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:23, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:24, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
   
     
    ];*/
  
    const { todoTopTraders, isFetchingTopTraders } = useFetchTodoToptraders()//"IAG.MC,EURUSD=X")

    //const  todoTopTraders = useFetchTodoToptraders()
    //glovar.TOPTRADERSLIST=todoTopTraders
      
  //Slide click
  const slide = (shift) => {
    //console.log("scrollX:"+scrollX)
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  /*const anim = (e) => {
    //gsap.from(e.target, { scale: 1 });
    //gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    //gsap.from(e.target, { scale: 1.5 });
    //gsap.to(e.target, { scale: 1 });
  };*/

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  if(!isFetchingTopTraders) {
  return (
    <div className="App">
      {scrollX !== 0 && (
        <button
        style={{fontSize: '24px', color: "#777", margin:" -0px 8px"}}
          //className="prev"
          onClick={() => slide(-250)}
          //onMouseEnter={(e) => anim(e)}
          //onMouseLeave={(e) => anim2(e)}
        >
        {"<"}
        </button>
      )}
      <ul ref={scrl} onScroll={scrollCheck}>
        {todoTopTraders.map((d, i) => (
            <ToptraderListItem Toptrader={d} index={i}></ToptraderListItem>

        ))}
      </ul>
      {!scrolEnd && (
        <button
        style={{fontSize: '24px', color: "#777", margin:" 0 8px",}}
          //className="next"
          onClick={() => slide(+250)}
          //onMouseEnter={(e) => anim(e)}
          //onMouseLeave={(e) => anim2(e)}
        >
          {" > "}
        </button>
      )}
    </div>
  );
}
}
