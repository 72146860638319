import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
//import PortfolioListItem from "./PortfolioListItem";
//import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import gsap from "gsap";

//import { useFetchTodoPortfolio } from "../utils/useFetchTodoPortfolio";
import { useFetchTodo } from "../utils/useFetchTodo";
import Element from "./element";
import glovar from './glovar';
import {getMONEDADivisa} from './glovar';

export default ({}) => {
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  if(!glovar.CURRENT_FAVORITOS) {
    return null;
  }

  let ARRAY_COTIS="";
  //ARRAY_COTIS="^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X";

  if(glovar.CURRENT_FAVORITOS) {
  for (let i=0; i<glovar.CURRENT_FAVORITOS.length;i++)
       {
       ARRAY_COTIS=ARRAY_COTIS+glovar.CURRENT_FAVORITOS[i].symbol+","; 
       }
      }

  //const { todo, isFetching } = useFetchTodo("^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X")
  const { todo, isFetching } = useFetchTodo(ARRAY_COTIS,1)

  const Tag = ({ data }) => {
    return <li>{data}</li>;
  };

  

 
 


    if (!isFetching) {

//console.log("isFetchingCotis11:"+JSON.stringify(todoCotisPortfolio))



     

      return (
        
        todo.map((todo) => {
          var m=getMONEDADivisa(todo.currency,todo.quoteType);
          return (
          <Element  element={todo} name={todo.shortName} price={todo.regularMarketPrice}
          pordiff={todo.regularMarketChange}
           diff={todo.regularMarketChangePercent} moneda={m}></Element>
          );  
        }) 
       );
      }
    //const  todoTopTraders = useFetchTodoToptraders()

      
  //Slide click
  /*const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };*/

  //Anim
  /*const anim = (e) => {
    //gsap.from(e.target, { scale: 1 });
    //gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    //gsap.from(e.target, { scale: 1.5 });
    //gsap.to(e.target, { scale: 1 });
  };*

  /*const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };*/

  /*return (
    
    
        todoPortfolio.map((d, i) => (
            <PortfolioListItem Toptrader={d} index={i}></PortfolioListItem>

        ))
     
   
  );*/
}
