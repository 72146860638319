import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { BiBitcoin } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import glovar from './glovar';
import { getMONEDADivisa  } from './glovar'
//import eventBus from "./eventBus";
import { FiUser, FiSettings, FiSearch } from "react-icons/fi";
import eventBus from "../eventBus";
import i18n from "../../i18n"

var contadorTraders=0;

function changeMenu(toptraderPos) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showTopTrader2", { tt: glovar.TOPTRADERSLIST[toptraderPos-1] });
  //console.log('commmm pos:'+pos)
  //console.log(glovar.TOPTRADERSLIST[toptraderPos])
  } 

function comm(pos) {
  //glovar.CURRENT_MENU_ID=3;
  //eventBus.dispatch("showTopTrader", { pos: (pos-1) });
  //console.log('commmm pos:'+pos)

  } 


const Wallet = ({
  icon,
  title,
  subtitle,
  percent,
  followers,
  change,
  loss,
  color1,
  color2,
  pos,
  mobile
}) => (
  <Box mr={1} mt={0} mb={3} _hover={{ cursor: "pointer" }}>
    <div onClick= {() =>  changeMenu(pos)}>
    <Flex alignItems="center">

    <Box ml={0} w={"2pc"}>
        <Heading fontSize="md">{pos}</Heading>
        
      </Box>



          {mobile==1&&<Box ml={0} w={"9pc"}>
        <Text fontSize="15" fontWeight="bold">{title}</Text>
        <Text fontSize="12" fontWeight="normal">
            {subtitle}
          </Text>
      </Box>}
      {mobile!=1&&<Box ml={0} w={"18pc"}>
        <Heading fontSize="md">{title}</Heading>
        <Text fontSize="12" fontWeight="normal">
            {subtitle}
          </Text>
      </Box>}

      <Text
          fontSize="12"
          textColor={loss ? "red" : "black"}
          fontWeight="normal"
          w={"4pc"}
        >
          {change}
        </Text>
      <Box
        borderRadius="lg"
        fontSize="14"
        fontWeight="bold"
        textAlign="center"
        w={"5pc"}
        p={1}
        bg={color1}
        textColor={color2}
      >
        {percent}
      </Box>
      <Spacer />
      
     
        <Text fontSize="14" fontWeight="bold" mr={1}>
          {followers}
        </Text>
        <FiUser/>
     
    </Flex>
    </div>
  </Box>
);


function Item(props) {
  //console.log(props.value)



  //if(props.value.idCartera) return <li>{props.value.idCartera}</li>;
  //else return null;

  //if(props.value.stock=="Micron") { return null; }
  //if(props.value.stock=="Citigroup") { return null; }
  //if(props.value.stock=="Verisign") { return null; }
  //if(props.value.stock=="Qualcomm") { return null; }


  
  var moneda=props.value.moneda;
  //contadorTraders=contadorTraders+0.5;

  try { 
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    contadorTraders=contadorTraders+0.5;
    //console.log("developmeeeeent")
    //console.log("NODE_ENV:"+process.env.NODE_ENV)
   } else {
    contadorTraders=contadorTraders+1;
    // production code
    //console.log("producttiooon")
   }
  }
  catch(Exx) { contadorTraders=contadorTraders+1; }

  //try { moneda=getMONEDADivisa(props.value.currency)} catch (Exxx) { console.log("petada moneda:"+Exxx)}

  //if(props.value.regularMarketChange>0) 
  {
   
    return (
<Wallet
          //title="TEF.MC"
          //title={glovar.TOPTRADERS_PRO1[glovar.TOPTRADERS_PRO1.length-2].idCartera}
          //title={props.value.idCartera}
          pos={contadorTraders}
          title={props.value.name}
          subtitle={props.value.symbol}
          icon={<FiArrowUp />}
          change={"+"+Number(props.value.profit).toFixed(0)+moneda}
          percent={Number(props.value.rentab).toFixed(2)+"%"}
          followers={props.value.followers}
          //loss
          color1="#1FB31F"
          color2="#FFFFFF"
          mobile={props.mobile}
        />
    );
  }

}


/*function MyList() {

  return (
   <ul>
     
       {glovar.TOPTRADERSLIST.map((item) => <Item key={item.id} value={item} />)}

     
   </ul>
 );
}*/

function MyFavoritesList(mob) {

  contadorTraders=0;

  

  if(!glovar.TOPTRADERSLIST)
  {
    return null;
  }
  {
  for (let i=0; i<glovar.TOPTRADERSLIST.length;i++)
  {
    return (
      glovar.TOPTRADERSLIST.map((item) => <Item key={item.id} value={item} mobile={1} />)
    );

  }
}
}

export default ({mobile}) => {

  var mob=0;
  try { if(mobile==1) { mob=1; } } catch(Exx) {}

  var CONTAINER_STYLE_NO_PREM="row container_nopremium";
  var CONTAINER_STYLE_PREM="row container";
  var marginLeft=8;

  try {
  if(mobile==1) {
    CONTAINER_STYLE_NO_PREM="row mobcontainer_nopremium";
    CONTAINER_STYLE_PREM="row mobcontainer";
    marginLeft=4;
  }
}
catch(Exxx) {}

  try {
  return (
    <Box ml={marginLeft} mr={4} mb={2} >
      <Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text>{i18n.t("clasif_general")}</Text>
        <Spacer />
        <Text
          fontWeight="semibold"
          textColor="blackAlpha.700"
          _hover={{ cursor: "pointer" }}
        >
          
        </Text>
      </Flex>
      <Stack>
      {(glovar.MODE_USER<2)&&<div class={CONTAINER_STYLE_NO_PREM}>
        <MyFavoritesList mob={mob} />
        </div>}
        {(glovar.MODE_USER>=2)&&<div class={CONTAINER_STYLE_PREM}>
        <MyFavoritesList mob={mob}/>
        </div>}

        
          
       
        
      
        
      </Stack>
    </Box>
  );
  }
  catch(Exx) { return null}
};
