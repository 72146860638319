import {useState, useEffect} from 'react'
import glovar from '../content/glovar';
import { setMYPORTFOLIO } from '../../utils/Common';

export const useFetchTodoNewsSearch = (ticker_news) => {

  const [todoNews, setTodo] = useState([]);
  const [searchedNews, setSearched]= useState(true);
  const [isFetchingNews, setIsFetching] = useState(true);


  var SEARCH_NEWS=false;

  useEffect(() => {
    //if(MODE_FETCH==1) { console.log("useFetchTodoPortfolio toptradeerr!!!") }
    //else { { console.log("useFetchTodoPortfolio user!!!") }}

    

    //if(MODE_FETCH==0) 
    //if(glovar.USER_LANG_PRO1=="ES") 

    var TAG_RSS=false;
    SEARCH_NEWS=false;

    if(glovar.USER_LANG_PRO1=="ES")
    //if(1==2)
    { 
      var listRSStags=[]
      
      try {
       
          for(let j=0;j<glovar.ARRAY_TAGS_ES.length;j++)
          {
            
              if(ticker_news.toLowerCase().includes(glovar.ARRAY_TAGS_ES[j].symbol.toLowerCase())) {
              listRSStags.push(glovar.ARRAY_TAGS_ES[j]);
              //console.log(JSON.stringify(listRSStags));
              TAG_RSS=true;
              setSearched(false);
             }
          }
        

        if(TAG_RSS) {
          setTodo(listRSStags)
          setIsFetching(false)
        }
      }
      catch(Exxx) {}
    }

    if(glovar.USER_LANG_PRO1=="FR")
    //if(1==2)
    { 
      var listRSStags=[]
      
      //console.log("glovar.USER_LANG_PRO1:"+glovar.USER_LANG_PRO1)
      //console.log("ticker_news:"+ticker_news)
      try {
       
          for(let j=0;j<glovar.ARRAY_TAGS_FR.length;j++)
          {
            
              if(glovar.ARRAY_TAGS_FR[j].symbol.toLowerCase().includes(ticker_news.toLowerCase())
              || (ticker_news.toLowerCase().includes('.pa')))
              {
              listRSStags.push(glovar.ARRAY_TAGS_FR[j]);
              //console.log(JSON.stringify(listRSStags));
              TAG_RSS=true;
              setSearched(false);
              }
          }
        

        if(TAG_RSS) {
          setTodo(listRSStags)
          setIsFetching(false)
        }
      }
      catch(Exxx) {}
    }

    
    if(glovar.USER_LANG_PRO1=="DE")
    //if(1==2)
    { 
      var listRSStags=[]
      
      //console.log("glovar.USER_LANG_PRO1:"+glovar.USER_LANG_PRO1)
      //console.log("ticker_news:"+ticker_news)
      try {
       
          for(let j=0;j<glovar.ARRAY_TAGS_DE.length;j++)
          {
            
              if(glovar.ARRAY_TAGS_DE[j].symbol.toLowerCase().includes(ticker_news.toLowerCase())
              || (ticker_news.toLowerCase().includes('.de')) || (ticker_news.toLowerCase().includes('.vi')))
              {
              listRSStags.push(glovar.ARRAY_TAGS_DE[j]);
              //console.log(JSON.stringify(listRSStags));
              TAG_RSS=true;
              setSearched(false);
              }
          }
        

        if(TAG_RSS) {
          setTodo(listRSStags)
          setIsFetching(false)
        }
      }
      catch(Exxx) {}
    }



    //(glovar.USER_LANG_PRO1!="ES") 
    if ((glovar.USER_LANG_PRO1!="ES" && glovar.USER_LANG_PRO1!="FR" && glovar.USER_LANG_PRO1!="DE") || !TAG_RSS)
    //else
    {
     
      const link = "https://thingproxy.freeboard.io/fetch/https://query1.finance.yahoo.com/v1/finance/search?q="+ticker_news;
      //console.log("link:"+link);
       fetch(link).then(response => response.json())
       .then(json => {

            //console.log("complete:"+JSON.stringify(json.news))
            setTodo(json.news)
            setSearched(true);
      })
      .catch((e) => {
      {
 
       let headers = new Headers(); 
       headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
       headers.set('Parameters',btoa(ticker_news));


 fetch(glovar.URL_FETCH_SEARCH_NEWS,{ method:'GET', //mode: 'no-cors',
 headers: headers,
 })
.then(res => res.json())
.then(res => {
   //console.log("ticker:"+ticker_news)
   //console.log("res1:"+JSON.stringify(res))
   
  if(res=="auth/user-not-found" || res=="auth/wrong-password") {
       return;
  }

  if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
    return;
  }

  setTodo(res)
  setSearched(true);
})
.catch((e) => {
  //console.log(e);
 })
 .finally(() => {
  //setIsFetchingSearch(false);
 
 });

}

})
.finally(() => {
setIsFetching(false);
});










}
  /*fetch(glovar.URL_FETCH_COTIS+ARRAY_COTIS, {
  //fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_COTIS, {
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {
    setTodo(json.quoteResponse.result)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });*/


  },[]);
 
  /*
  useEffect(() => {
    fetch(glovar.URL_FETCH_COTIS+ARRAY_COTIS, {
  //fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_COTIS, {
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {
    setTodo(json.quoteResponse.result)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  },[]);
  */
 
 return { todoNews, searchedNews, isFetchingNews }
 
 }
 