import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { BiBitcoin } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import glovar from './glovar';
//import eventBus from "./eventBus";
import { Image } from "@chakra-ui/image";
//import { background } from "@chakra-ui/react";
import i18n from "../../i18n"
import eventBus from "../eventBus";

var contadorTraders=0;
var SHOW_SCREEN_OPTIONS=false;
var POS_SHOW_OPTIONS=0;



//import glovar from './glovar';
//import { getMONEDADivisa  } from './glovar'

function showElement(element) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  //eventBus.dispatch("showElement", { el: element });
  window.location.replace('/symbol/'+element.symbol) 
  //console.log('commmm pos:'+pos)
  } 

 


const Wallet = ({
  element,
  icon,
  pos,
  title,
  acciones,
  sentido,
  subtitle,
  salida,
  entrada,
  benef,
  rent,
  change,
  loss,
  color1,
  color2,
  fent,
  fsal,
  colortext,
  colorback_tit,
  mobile
}) => (
  <Box mr={2} mt={0} mb={5} color={colortext} _hover={{ cursor: "pointer" }} onClick={() => showElement(element)}>
    <Flex alignItems="center" mb={3} >
         

      {!mobile&&<Box ml={0}  w={"11pc"}>
      <Heading fontSize="md"  w={"13pc"}>{title}</Heading>
              <Text fontSize="13" fontWeight="thin">
                {fent}  |  {fsal}
              </Text>
        </Box>}
      
        {mobile&&<Box ml={0}  w={"7.5pc"}>
      <Heading fontSize="sm"  w={"7.5pc"}>{title}</Heading>
              <Text fontSize="11" fontWeight="thin">
                {fent}  |  {fsal}
              </Text>
              <Box
        borderRadius="lg"
        fontSize="12"
        fontWeight="semibold"
        textAlign="center"
        mr={4}
        mt={1}
        w={"4pc"}
        p={1}
        bg={colorback_tit}
        textColor="#FFFFFF"
      >
         {sentido==1 ? "+"+acciones : "-"+acciones}
      </Box>
        </Box>}


        {!mobile&&<Box
        borderRadius="lg"
        fontSize="12"
        fontWeight="semibold"
        textAlign="center"
        mr={4}
        w={"4pc"}
        p={1}
        bg={colorback_tit}
        textColor="#FFFFFF"
      >
         {sentido==1 ? "+"+acciones : "-"+acciones}
      </Box>}
      <Spacer />
      {!mobile&&<Box
        borderRadius="lg"
        fontSize="14"
        fontWeight="semibold"
        textAlign="center"
        w={"10.5pc"}
        p={1}
        ml={0}
        bg={color1}
        textColor={color2}
      >
        {benef}     {rent}
      </Box>}
      {mobile&&<Box
        borderRadius="lg"
        fontSize="14"
        fontWeight="semibold"
        textAlign="center"
        w={"9pc"}
        p={1}
        ml={0}
        bg={color1}
        textColor={color2}
      >
        {benef}     {rent}
      </Box>}
      <Spacer />
      {!mobile&&
       <Box textAlign="right" mr={0} w={"4pc"}>
        <Text fontSize="14" fontWeight="semibold">
          {entrada}
        </Text>
      </Box>}
      <Spacer />
      {!mobile&&<Box textAlign="right" ml={2} w={"4pc"}>
        <Text fontSize="14" fontWeight="semibold">
          {salida}
        </Text>
      </Box>}

      {mobile&&<Box textAlign="right" ml={2} w={"4pc"}>
      <Text fontSize="14" fontWeight="semibold">
          {entrada}
        </Text>
        <Text fontSize="14" fontWeight="semibold">
          {salida}
        </Text>
      </Box>}

    </Flex>



    {(SHOW_SCREEN_OPTIONS && POS_SHOW_OPTIONS==pos )&&<Flex alignItems="center">
         

  

         {!glovar.APP_STATE_RUNNING&&<Box
             _hover={{ cursor: "pointer" }}
             p={1.5}
             m={1}
             marginTop={2}
             ml={0}
             borderRadius="md"
             bg="gray"//bg="#000000"
             textColor="white"
             //onClick={() => showDeletePos(pos)}
             
           >
           <Image src={"assets/ic_delete_forever_white_24dp.png"} w={6}></Image>
           </Box>}
     
           {glovar.APP_STATE_RUNNING&&<Box
             _hover={{ cursor: "pointer" }}
             p={1.5}
             m={1}
             marginTop={2}
             ml={0}
             borderRadius="md"
             bg="#DDDDDD"//bg="#000000"
             textColor="white"
           >
           <Image src={"assets/ic_delete_forever_white_24dp.png"} w={6}></Image>
           </Box>}
         
     
           {glovar.APP_STATE_RUNNING&&<Text style={{fontSize:"0.9em", fontWeight:"normal", marginLeft:"1pc", textAlign:"center", alignContent:"center"}}>
           INFO: Para poder eliminar posición en tu track record es necesario que salgas de la APP.
         </Text>}
     
     
          </Flex>
             }
  </Box>
);


function Item(props) {

  contadorTraders=contadorTraders+0.5;
  //console.log(props.value)


  var REVISEDtitle=props.value.stock; 

   /**if(REVISEDtitle.length>28) {
    REVISEDtitle=REVISEDtitle.substring(0,28);
   }*/

   

   

   var item_color1="#1FB31F";
   var item_colorText="#000000";
   var item_colorback_tit="#000000"

   var itemBenef="+"+Number(props.value.Benf).toFixed(0)+props.value.m;
   var itemAcciones=props.value.acciones;
   
   if(props.value.rent<0) { 
    item_color1="#D61A18"; 
    itemBenef=Number(props.value.Benf).toFixed(0)+props.value.m;
  }

   if(props.grayScale) {
     item_color1="#CCCCCC";
     item_colorback_tit="#CCCCCC";
       //color2="#FFFFFF"
       //colorTitle="#CCCCCC"
       item_colorText="gray.100"
   }
 
   //var toptraderTit=Toptrader.acciones;
   if(props.hidetit) {
     //toptraderTit=""
     itemAcciones="";
     itemBenef="";
   }


  if(props.value.rent>=0) 
  {
   
   

    return (
<Wallet
          //title="TEF.MC"
          //title={glovar.TOPTRADERS_PRO1[glovar.TOPTRADERS_PRO1.length-2].idCartera}
          //title={props.value.idCartera}
          element={props.value}
          pos={contadorTraders}
          title={REVISEDtitle}
          subtitle={props.value.symbol}
          icon={<FiArrowUp />}
          salida={props.value.sal+props.value.m}
          entrada={props.value.ent+props.value.m}
          acciones={itemAcciones}//{props.value.acciones}
          sentido={props.value.sentido}
          fent={props.value.fent}
          fsal={props.value.fsal}
          benef={itemBenef}//{"+"+Number(props.value.Benf).toFixed(0)+props.value.m}
          rent={"+"+Number(props.value.rent).toFixed(2)+"%"}
          //loss
          color1={item_color1}//"#1FB31F"
          color2="#FFFFFF"
          colortext={item_colorText}
          colorback_tit={item_colorback_tit}
          mobile={props.mobile}
        />
    );
  }

  else {

  return (
<Wallet
          //title="TEF.MC"
          //title={glovar.TOPTRADERS_PRO1[glovar.TOPTRADERS_PRO1.length-2].idCartera}
          //title={props.value.idCartera}
          element={props.value}
          pos={contadorTraders}
          title={REVISEDtitle}
          //title={props.value.stock}
          subtitle={props.value.symbol}
          icon={<FiArrowDown />}
          salida={props.value.sal+props.value.m}
          entrada={props.value.ent+props.value.m}
          acciones={itemAcciones}//{props.value.acciones}
          sentido={props.value.sentido}
          fent={props.value.fent}
          fsal={props.value.fsal}
          benef={itemBenef}//{Number(props.value.Benf).toFixed(0)+props.value.m}
          rent={Number(props.value.rent).toFixed(2)+"%"}
          loss
          color1={item_color1} //"#D61A18"
          color2="#FFFFFF"
          colortext={item_colorText}
          colorback_tit={item_colorback_tit}
          mobile={props.mobile}
        />
  );
  }

}



/*function MyList() {

  return (
   <ul>
     
       {glovar.TOPTRADERS_PRO1.map((item) => <Item key={item.id} value={item} />)}

     
   </ul>
 );
}*/


function MyFavoritesList({ tt,grayScale, hidetit,mobile}) {
  //const MyFavoritesList = async (trPos) => {

    var MOBILE_VERSION=mobile;
    var GRAY_SCALE=false;
    if (grayScale) { GRAY_SCALE=true; }
    var HIDE_TITBENEF=false;
    if(hidetit) {HIDE_TITBENEF=true; }

    

    var toptrader = tt;

  //console.log(toptrader)
  
  var CARTERA_INFO_HISTORIAL_TEXT='['+toptrader.historial+']';
  //console.log('##CCARTERA_INFO_CARTERA_TEXT:'+CARTERA_INFO_CARTERA_TEXT);
   
  var CARTERA_INFO_HISTORIAL = JSON.parse(CARTERA_INFO_HISTORIAL_TEXT);
  
  

  if(!CARTERA_INFO_HISTORIAL)
  {
    return null;
  }
  {
  for (let i=0; i<CARTERA_INFO_HISTORIAL.length;i++)
  {
    return (
      CARTERA_INFO_HISTORIAL.map((item) => <Item key={item.id} value={item} grayScale={GRAY_SCALE} 
                                                 mobile={MOBILE_VERSION} hidetit={HIDE_TITBENEF}/>)
    );
  }
}
}

  //export default ({ traderPos }) => {
  //contadorTraders=0;
  export default ({ toptrader, grayScale, hidetitbenef,mobile }) => {
  
    //var TOPTRADER=toptrader;
    var MOBILE_VERSION=mobile;
  
    var GRAY_SCALE=false;
    if (grayScale) { GRAY_SCALE=true; }
    var HIDE_TITBENEF=false;
    if(hidetitbenef) {HIDE_TITBENEF=true; }



  try {
  return (
    <Box ml={4} mr={4} mt={2}  >
     <Flex mb={3} textColor="gray.400" fontSize="sm">
     
        <Text>{i18n.t("activo")}</Text>
        
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        {!MOBILE_VERSION&&<Text>{i18n.t("titulos")}</Text>}
        <Spacer />
        <Text>{i18n.t("benef_rent")}</Text>
        <Spacer />
        <Text>{i18n.t("entrada")}</Text>
       <Spacer />
        <Text mr={2}>{i18n.t("salida")}</Text>
        <Text
          fontWeight="semibold"
          textColor="blackAlpha.700"
          _hover={{ cursor: "pointer" }}
        >
          
        </Text>
      </Flex>
      <Stack>
      {<div class="row container_trackrecord">
        
        <MyFavoritesList tt={toptrader} grayScale={GRAY_SCALE} hidetit={HIDE_TITBENEF} mobile={MOBILE_VERSION} />
        </div>}
        
          
       
        
      
        
      </Stack>
    </Box>
  );
  }
  catch(Exx) { return null}
};
