

import { Text } from "@chakra-ui/layout";
//import { Spacer } from "@chakra-ui/layout";
//import { Stack } from "@chakra-ui/layout";
//import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import { VStack } from "@chakra-ui/layout"; 
import { Image } from "@chakra-ui/image";

import Moment from 'react-moment';
import eventBus from "../eventBus";
//import { camelCaseKeys } from "i18n-js/typings/helpers";
//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

  
  export default ({ title, thumbnail, link, published,subtitle }) => {

    
     function caca () {
      try {
        eventBus.dispatch("RSSnew", { data:  subtitle });
      }
      catch(Exx) {}
     }

    return (
        <Box m={3}  _hover={{ cursor: "pointer" }}>
    <div>
    <a
              className="link"
              href={link}
              target="_blank"
              rel="nofollow noopener noreferrer"
              title={title}
              aria-label={link}
              key={link}
              onClick={() => { caca(); }}
            >
    <Flex alignItems="center"  >
           <HStack ml={0}  w="full"  >
        
           <Image src={thumbnail} w="94px" h="76px"></Image>

           <VStack  align={"start"} w="full"  >
           <Text
            fontSize="14"
            textColor="black"
            fontWeight="bold"
            noOfLines={2}
           >
            {title}          
            </Text>
          <HStack w="full" >
          <Text
             w="full"
            fontSize="13"
            textColor="green"
            fontWeight="normal"
           >
            {subtitle}
          </Text>
          
          <Text
            
            w="full"
            textAlign="right"
            fontSize="13"
            textColor="gray"
            fontWeight="normal"
            alignSelf="self-end"          >
            <Moment fromNow>{published}</Moment>
          </Text>
         

          </HStack>

        </VStack>

      </HStack>
    </Flex>
    </a>
    </div>
  </Box>
    );
  };
  