import {Navigate} from 'react-router-dom'
//import {useAuthValue} from './AuthContext'
//import glovar from './components/glovar';
import { getToken, removeUserSession, setUserSession } from './utils/Common';


export default function PrivateRoute({children}) {


  try {
    const token = getToken();
    if (token) 
    {
      //console.log("token:"+token)
      //glovar.USER_LOADED=true;
    }
    else {
      //return <Navigate to='/login' replace/>
      //Don't force go to login when firstime page loaded.
    }
    }

    catch(Exxxs) { return <Navigate to='/login' replace/> }

  /*
  if(!glovar.USER_LOADED)
  {
    console.log("mierdaaaaaaaaaaaaaaaaaaaaaaaaaa")
    return <Navigate to='/login' replace/>
  */
  /*const {currentUser} = useAuthValue()

  if
  if(!currentUser?.emailVerified)
  {
    return <Navigate to='/login' replace/>
  }*/



  return children
}