import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
//import PortfolioListItem from "./PortfolioListItem";
//import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import { useFetchTodoPortfolio } from "../utils/useFetchTodoPortfolio";
//import { useFetchTodo } from "../utils/useFetchTodo";
import { useFetchTodoRSS } from "../utils/useFetchTodoRSS";
//import Element from "./element";
import RSSnew from "./RSSnew";

export default ({listRSS,tag, tagStock}) => {
//RSSNewsList listRSS={listRSStags} tag={TAG_RSS}></RSSNewsList>

  //console.log("stock a mostrar noticies:"+stock);
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);


  if(tag) 
  {

    var todoRSSUnique=[];

    for(let i=0; i<listRSS.length; i++) {
     //console.log(JSON.stringify(listRSS[i]))

     var item=[];
     item.title=listRSS[i].title;
     item.link=listRSS[i].link;
     item.published=listRSS[i].providerPublishTime;
     item.subtitle=listRSS[i].publisher;
     try  { item.thumbnail=listRSS[i].thumbnail.resolutions[1].url; } catch(Exx) {
      item.thumbnail="/assets/blackwhite.png";
      //console.log("No thumbnail:"+JSON.stringify(listRSS[i]));
     }
     try { item.relatedTickers=listRSS[i].relatedTickers; } catch(Exx) {}

     //console.log(JSON.stringify(listRSS[i].thumbnail.resolutions[1].url));

     //console.log(JSON.stringify(listRSS[i].thumbnail));

     todoRSSUnique.push(item)
    }

    

   
    return (
        

      todoRSSUnique.map((todoRSS) => {
         
          /*return (
            <RSSnew title={todoRSS.title} thumbnail="/assets/bluewhite.png" link={todoRSS.link} published={todoRSS.pubDate} subtitle={todoRSS.subtitle} ></RSSnew>
            )*/
              return (
            <RSSnew title={todoRSS.title} thumbnail={todoRSS.thumbnail} link={todoRSS.link} published={todoRSS.pubDate} subtitle={todoRSS.subtitle} ></RSSnew>
            )
        
      })


     


     );

  }
  else {

    var TagRSS=tag;
    if(tagStock==true) {TagRSS=true}
    //console.log("que me llega:"+JSON.stringify(listRSS));
  //Ahora tenemos que discernir si hay tag o no y actualizarlo

  //const { todo, isFetching } = useFetchTodo("^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X")
  const { todoRSS, isFetchingRSS } = useFetchTodoRSS(listRSS,TagRSS)//("https://api.rss2json.com/v1/api.json?rss_url=https://youtube.com/feeds/videos.xml?channel_id=UCZUJ0nkrzeKkse3RLdedxHQ")


  const Tag = ({ data }) => {
    return <li>{data}</li>;
  };

  

 
 


    if (!isFetchingRSS) {
      
      const uniqueIds = [];
  
  const todoRSSUnique = todoRSS.filter(element => {
    const isDuplicate = uniqueIds.includes(element.link);

    if (!isDuplicate) {
      uniqueIds.push(element.link);

      return true;
    }

    return false;
  });

 //console.log("reeeender");

 //todoRSS.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });  

    //console.log("holaaa")
    todoRSSUnique.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });   
    //console.log("holaaa:"+todoRSSUnique.length)

//console.log("isFetchingCotis11:"+JSON.stringify(todoCotisPortfolio))


     

      return (
        

        todoRSSUnique.map((todoRSS) => {
          if(todoRSS.rss_id==3) {
          return (
          <RSSnew title={todoRSS.title} thumbnail={todoRSS.enclosure.link} link={todoRSS.link} published={todoRSS.pubDate} subtitle={todoRSS.subtitle} ></RSSnew>
          );  
          }
          else if(todoRSS.rss_id==4) { 

            //console.log("enclosure:"+todoRSS.src)
            return (
              <RSSnew title={todoRSS.title} thumbnail={todoRSS.thumbnail} link={todoRSS.link} published={todoRSS.pubDate} subtitle={todoRSS.subtitle} ></RSSnew>
              )
          }
          else if(todoRSS.rss_id==6) { 
            return (
              <RSSnew title={todoRSS.title} thumbnail="/assets/bluewhite.png" link={todoRSS.link} published={todoRSS.pubDate} subtitle={todoRSS.subtitle} ></RSSnew>
              )
          }
        })


       


       );
      }
    //const  todoTopTraders = useFetchTodoToptraders()



  /*return (
    
    
        todoPortfolio.map((d, i) => (
            <PortfolioListItem Toptrader={d} index={i}></PortfolioListItem>

        ))
     
   
  );*/
}
}
