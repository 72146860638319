
import React from "react";
import PropTypes from "prop-types";

import { scaleTime } from "d3-scale";
import { curveMonotoneX } from "d3-shape";

import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { createVerticalLinearGradient, hexToRGBA } from "react-stockcharts/lib/utils";

const canvasGradient = createVerticalLinearGradient([
	{ stop: 0, color: hexToRGBA("#b5d0ff", 0.2) },
	{ stop: 0.7, color: hexToRGBA("#6fa4fc", 0.4) },
	{ stop: 1, color: hexToRGBA("#4286f4", 0.8) },
]);

class AreaChart extends React.Component {
	render() 
	{
		try {
		const { data, type, width, ratio,colorLine } = this.props;
		if(data.length<2) { return null; }
		
		return (
			<ChartCanvas ratio={ratio} width={width} height={300}
				margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
				
				mouseMoveEvent={false}
				panEvent={false}
				zoomEvent={false}
				clamp={false}

				seriesName=""
				data={data} type={type}
				xAccessor={d => d.date}
				xScale={scaleTime()}
				//displayXAccessor={d => d.date}
				//xExtents={[new Date(2011, 0, 1), new Date(2013, 0, 2)]}
			>
				<Chart id={0} yExtents={d => d.close}
				>
					<defs>
						<linearGradient id="MyGradient" x1="0" y1="100%" x2="0" y2="0%">
							<stop offset="0%" stopColor={colorLine} stopOpacity={0.1} />
						
						</linearGradient>
					</defs>
					<XAxis axisAt="bottom" orient="bottom" ticks={6}/>
					<YAxis axisAt="left" orient="left" />
					<AreaSeries
						yAccessor={d => d.close}
						fill="url(#MyGradient)"
						stroke={colorLine}//"#67B634"
						strokeWidth={2}
						interpolation={curveMonotoneX}
						//canvasGradient={canvasGradient}
					/>
				</Chart>
			</ChartCanvas>
		);
		}
		catch(Exx) { return null}
	}
}


AreaChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

AreaChart.defaultProps = {
	type: "svg",
};
AreaChart = fitWidth(AreaChart);

export default AreaChart;