import {useState, useEffect} from 'react'
import glovar from '../content/glovar';
import { setMYPORTFOLIO } from '../../utils/Common';

export const useFetchTodoPortfolio = (ARRAY_COTIS,MODE_FETCH) => {

  const [todoCotisPortfolio, setTodo] = useState([]);
  const [isFetchingCotis, setIsFetching] = useState(true);
 

  useEffect(() => {
    //if(MODE_FETCH==1) { console.log("useFetchTodoPortfolio toptradeerr!!!") }
    //else { { console.log("useFetchTodoPortfolio user!!!") }}


    var TIRAR_DE_CACHE=false;

    if(MODE_FETCH==0) { //portfolio_user
    try {
      //console.log("glovar.MOREPOPULAR_COTIS.length:"+JSON.stringify(glovar.MOREPOPULAR_COTIS))
      if(glovar.MYPORTFOLIO_COTIS.length>=1) {
        //console.log("glovar.TOPTRADERSLIST:"+glovar.TOPTRADERSLIST)
        TIRAR_DE_CACHE=true;
        setTodo(glovar.MYPORTFOLIO_COTIS)
        setIsFetching(false)

      }
    }
    catch(Exx) {}
    }

    //TIRAR_DE_CACHE=true;
  
    if(!TIRAR_DE_CACHE) 
    {
      //console.log("download PORTFOLIO!!!")
      
 
    let headers = new Headers(); 
   headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
   headers.set('Parameters',btoa(ARRAY_COTIS));


   fetch(glovar.URL_FETCH_COTIS,{ method:'GET', //mode: 'no-cors',
   headers: headers,
   })
  .then(res => res.json())
  .then(res => {

    if(res=="auth/user-not-found" || res=="auth/wrong-password") {
      //try { setError("ERROR: "+i18n.t("email_try_again"));  } catch(Exx) {setError("ERROR: The email or password you entered is incorrect. Please try again.");   }
      return;
    }

    if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
      //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
      return;
    }
    
    setTodo(res)//.quoteResponse.result)
    if(MODE_FETCH==0) {
      setMYPORTFOLIO(res);
    try { glovar.MYPORTFOLIO_COTIS=res;} catch(exxx) {}
    }
  })
  .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  }
  /*fetch(glovar.URL_FETCH_COTIS+ARRAY_COTIS, {
  //fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_COTIS, {
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {
    setTodo(json.quoteResponse.result)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });*/


  },[]);
 
  /*
  useEffect(() => {
    fetch(glovar.URL_FETCH_COTIS+ARRAY_COTIS, {
  //fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_COTIS, {
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {
    setTodo(json.quoteResponse.result)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  },[]);
  */
 
 return { todoCotisPortfolio, isFetchingCotis }
 
 }
 