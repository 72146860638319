//import logo from './logo.svg';
import './App.css';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import Papa from "papaparse"


import { Heading } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import {Container } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import logo from './logopro1trading.png'
//import {Helmet} from "react-helmet";



import i18n from './i18n';

//import glovar from './glovar';
import glovar from './components/content/glovar';


import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";

import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
//import Profile from './Profile'
import RegisterApp from './RegisterApp'
//import Register from './Register'
//import VerifyEmail from './VerifyEmail';
import ForgotPassword from './ForgotPassword';

import Login from './Login'
//import Stat from "./components/content/stat";
import Element from "./components/content/element";
import RSSnew from "./components/content/RSSnew";
import { useFetchTodo } from "./components/utils/useFetchTodo";
import { useFetchTodoRSS } from "./components/utils/useFetchTodoRSS";
import { useFetchTodoPortfolio } from "./components/utils/useFetchTodoPortfolio";
import { useFetchTodoToptraders } from "./components/utils/useFetchTodoToptraders";
import { useFetchTodoUser } from "./components/utils/useFetchTodoUser";
import { useFetchTodoHistory } from "./components/utils/useFetchTodoHistory";
import { useFetchTodoNewsSearch  } from "./components/utils/useFetchTodoNewsSearch";
//import { useFetchTodoSearch } from "./components/utils/useFetchTodoSearch";

import ToptraderList from "./components/content/ToptraderList";
import PortfolioList from "./components/content/PortfolioList";
import PortfolioTopTraderList from "./components/content/PortfolioTopTraderList";
import PortfolioTopTraderListNoeffect from "./components/content/PortfolioTopTraderListNoeffect";

import TrackRecordTopTraderList from  "./components/content/TrackRecordTopTraderList";
import TopTradersRanking from  "./components/content/TopTradersRanking";
import CarrusselTopTraders from  "./components/content/CarrusselTopTraders";
import ContactsList from  "./components/content/ContactsList";

import RSSNewsList from "./components/content/RSSNewsList";
import FavoritosList from "./components/content/FavoritosList";
import MorePopularList from "./components/content/MorePopularList";

import {useState, useEffect} from 'react'
import Homefrontuser from './components/content/homefrontuser';
import AddContentApp from './components/content/AddContentApp';

import eventBus from "./components/eventBus";
import React from 'react';
import BigCardTopTrader from "./components/content/BigCardTopTrader";
import { getToken, getUser, getEmail, removeUserSession,getModeUser, setUserSession, getPortfolio, getFavoritos, getHistorial,
  getporfolio_ED,getTOPTRADERSLIST,setTOPTRADERSLIST,getLastTimeTOPTRADERSLIST, 
  getMarketCAP,
  //getData,getDataCSV,
  getMOREPOPULAR,getLastTimeMOREPOPULAR, getMYWATCHLIST, getLastTimeMYWATCHLIST, getMYPORTFOLIO,getLastTimeMYPORTFOLIO,
AdComponent728, AdComponent468,AdComponent600, AdComponent320 } from './utils/Common';

  import { getMONEDADivisa } from './components/content/glovar'

  import Chart from "./components/content/Chart";


  import ReactGA from 'react-ga4';
import { json } from 'd3-fetch';
  const TRACKING_ID = "G-MXC10KRDQD"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

//import { getFirestore, collection, getDocs,getDoc,doc } from 'firebase/firestore';
//import {db} from './firebase'

//{1==2&&<BigCardTopTrader    toptraderpos={SHOW_PORTFOLIO_TOPTRADER_POS} current_portfolio_ED={glovar.current_porfolio_ED}/>}

//const { todo, isFetching } = useFetchTodo("^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X")
//const { todoRSS, isFetchingRSS } = useFetchTodoRSS([])//("https://api.rss2json.com/v1/api.json?rss_url=https://youtube.com/feeds/videos.xml?channel_id=UCZUJ0nkrzeKkse3RLdedxHQ")
//const [refresh, setrefresh] = useState([]);

var SHOW_TOPTRADER_PROFILE=null;
var SHOW_TOPTRADER_NO_EFFECT=false;
var TOPTRADER_COTIS=null;
var SHOW_ELEMENT_DATA=null;

//var SHOW_MY_PROFILE=null;
glovar.SHOW_MY_PROFILE=null;

/*async function showAvatar(ARRAY_COTIS) 
{
  console.log("que pasa neeeeeeeeeeeen")
  let githubResponse = await fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_COTIS);
  let githubUser = await githubResponse.json();
  console.log("final awaaaaaaaaaaaaaat")
  return(githubUser)
}*/

var edit_stock=false;
var DATA_COTIS=null;



export default class App extends React.Component {

 
  
  EventGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
   
   /*EventGA = (category, label) => {
    ReactGA.event({
      category: category,
      //action: action,
      label: label
    });
  };*/

//function App() 
//{
  constructor() {
    super();
 
    

  //console.log("rendeeeeeeeeeer todoRSS length:"+todoRSS.length)
  }

  btndownloadApp(app) {
   if(app==1) window.open(glovar.URL_APPSTORE,"_self")
   if(app==2) window.open(glovar.URL_PLAYSTORE,"_self")
  }

  changeSwitchCarteraOLD()
  {
    //SHOW_PUBLIC_CARTERA=!SHOW_PUBLIC_CARTERA;
    this.setState({});
  }


  changemenu(id)  {

    if(id>200) {
      window.location.replace('/')
      if(id==201) { glovar.CURRENT_MENU_ID=1; }
      if(id==202) { glovar.CURRENT_MENU_ID=1; }
      if(id==203) { glovar.CURRENT_MENU_ID=1; }
      else { glovar.CURRENT_MENU_ID=1; }
    }
    if(id>100) {
      if(id==101) window.open(i18n.t("url_twitter"),"_blank")
      if(id==102) window.open(i18n.t("url_instagram"),"_blank")
    }
    else {

     if(id==3) {
       //subo a MONGO DB
       if(glovar.MODE_USER>=1) {
       
        //window.location.replace('/'+glovar.USER_USERNAME) 
        /*glovar.SHOW_PUBLIC_CARTERA=false;
        glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF=true;

        glovar.SHOW_PUBLIC_HISTORIAL=true;
        glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF=true;*/

        glovar.CURRENT_MENU_ID=3;
        this.setState({});
      //console.log("subo a mongo db!!!!!!!!!!")

       /*##################################################*/
       /*# Pugem les dades del usuario al servidor de BD   #*/
       if(1==2) 
       { //ya lo hemos subido una vez
        try 
        {
         var headers = new Headers(); 

         //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
         //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)

         headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
         headers.set('Content-Type', 'application/json');
     
         //glovar.CURRENT_USER=cartera;
         //glovar.current_porfolio_ED=cartera.cartera_ed;
                      
          //console.log(JSON.stringify(headers))

          var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
          carteraPARSED = carteraPARSED.replace('[', '');
          carteraPARSED = carteraPARSED.replace(']', '');

          var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
          historialPARSED = historialPARSED.replace('[', '');
          historialPARSED = historialPARSED.replace(']', '');

         const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
               //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
               //method: "POST",
               method: "PUT",  
               headers: headers,
              
               body: 
               JSON.stringify({ 
                rentab: glovar.current_porfolio_ED.rentab,
                name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                risk_level: glovar.current_porfolio_ED.risk_level,
                max_level: glovar.current_porfolio_ED.max_level,
                countriesID:glovar.current_porfolio_ED.countriesID,
                activos: glovar.current_porfolio_ED.activos,
                op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                sentido: glovar.current_porfolio_ED.sentido,
                temporal: glovar.current_porfolio_ED.temporal,
                historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                capital:glovar.current_porfolio_ED.capital,
                invertido:glovar.current_porfolio_ED.invertido,
                moneda:glovar.current_porfolio_ED.moneda,
                profit:glovar.current_porfolio_ED.profit,
                currency:glovar.current_porfolio_ED.currency,
                priority:glovar.current_porfolio_ED.priority,
                rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
  
           //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
           //descript: "que pasa nen me voy de subidon!"
              })
         }) 

         //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
        }
        catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
      
      
         /*.then(response => response.json())
            .then(json => {
                  console.log("pujat correctament toptrader:"+glovar.TOPTRADERS_PRO1[j].name)
         });*/
        }
        /*##################################################*/
        /*##################################################*/
       }
       else {
        //console.log("para subir debes haber iniciado sesión")
       }

     }
     else { 
     glovar.CURRENT_MENU_ID=id;
    
    //per evitar que peti si no hem visualitzat mai un toptrader

    try {
     if(id==2  && !SHOW_TOPTRADER_PROFILE && glovar.TOPTRADERSLIST.length>1) {
       SHOW_TOPTRADER_PROFILE=glovar.TOPTRADERSLIST[0]
     }
    }
    catch(exxxx) {}
    //setrefresh(id);
    this.setState({});
  }
  }
  }

  logout() {
    glovar.USER_LOADED=false;
    glovar.MODE_USER=0;
    glovar.current_porfolio_ED=null;
    removeUserSession();
    
   
   this.setState({ email: "",
   password: ""});
  }
  

  loadFBData(data) {
    //this.getFBDocs(db);
    //this.getTopTradersLeague(db);
    //this.getRadar(db);
    this.setState({});
  }

  /*  
  async getFBDocs(db) {


  
    //const docRef1 = doc(db, "general", "versions");
    //const docVERSIONS = await getDoc(docRef1);
    
    
    //console.log("glovar.USER_USERNAME:"+glovar.USER_USERNAME);
    glovar.CURRENT_USER=null;
    const queryUser = doc(db, "users", glovar.USER_USERNAME);
    
    //Seguimos manteniendo el user_anom pq no tenemos cartera, favoritos ni historial en el server
  
    //const queryUser = doc(db, "users", glovar.USER_USERNAME);
    
  
    //console.log("antes del doc")
    const docUser = await getDoc(queryUser);
    //console.log("ya tengo el doc!!!")
  
   
    try { glovar.APP_STATE_STATE=docUser.data().appState; } catch(Exxx) { glovar.APP_STATE_STATE="none"; }
    try 
    { 
     if(glovar.APP_STATE_STATE==glovar.APP_STATE_ACTIVE) {  glovar.APP_STATE_RUNNING=true; }
    }
    catch(Exxxs) {}  
  
    
    {
      //console.log('#CARTERA PREEEEEEEEEEMIUM!!!!!!!!!!');
      //console.log(docUser.data().id, " => ", docUser.data().data());
    
      var SHOW_CARTERA=0;
     
      const lang =glovar.APP_LANG;
      var NEW_PRIORITY=-1;
      SHOW_CARTERA=1;
    
      //console.log('(docUser.data().get(lang):'+docUser.data().get('lang'));
    
        
    
      //if(SHOW_CARTERA==1 || docUser.data().get('priority')<1000)
      {
    
       var cartera = {
        rentab: 0,
        name: 'GLOBAL STOCKS',
        idCartera: 'ID_GLOBAL',
        descript: 'Global stock portfolio description',
        risk_level: 3,
        max_level: 5,
        countriesID: ' ',
        activos: '1,2,3',
        op_cerradas: 26,
        rentmedia_op: 13.27,
        sentido: 1,
        temporal: 1,
        historial:'',
        cartera_text:'',
        cartera_ed:[],
        cartera:[],
        favoritos:[], 
        capital:0,
        invertido:0,
        moneda:' ',
        profit:0,
        priority:0,
        rentab_acum:0,
        type_cartera:2, //por defecto solo la pueden ver los premiums!
        op_win: 8,
        followers: 333,
        cap_inicial:1000000,
        ranking:1
      };
    
    
     
      if(NEW_PRIORITY>=0)
      {
       cartera.priority=NEW_PRIORITY;
      }
      else {
       try { cartera.priority=docUser.data().get('priority'); } catch(Exxx) {}
      }
     
    
    
      try { cartera.rentab=docUser.data().rentab; } catch(Exxx) {}
    
    
      try {
       if (glovar.APP_FORCE_LANG=='ES') {  cartera.name=docUser.data().name; }
       else { cartera.name=docUser.data().name_en; }
      }
      catch(car1) { try { cartera.name=docUser.data().name; } catch(Exxx) {}}
      //cartera.name=docUser.data().name');
    
     
      try { cartera.idCartera=docUser.data().idCartera; } catch(Exxx) {}
    
      try {
      if (glovar.APP_FORCE_LANG=='ES') {  cartera.descript=docUser.data().descript; }
      else { cartera.descript=docUser.data().descript_en; }
     }
     catch(car1) { try { cartera.descript=docUser.data().descript; } catch(Exxx) {} }
    
   
      //cartera.descript=docUser.data().descript');
    
      try { cartera.cartera_ed=docUser.data().cartera_ed; } catch(Exxx) {}
  
  
      try {
  
        cartera.favoritos=docUser.data().favoritos;
        
      cartera.risk_level=docUser.data().risk_level;
      cartera.max_level=docUser.data().max_level;
      cartera.countriesID=docUser.data().countriesID;
    
      cartera.activos=docUser.data().activos;
      cartera.op_cerradas=docUser.data().op_cerradas;
      cartera.rentmedia_op=docUser.data().rentmedia_op;
      cartera.sentido=docUser.data().sentido;
      cartera.temporal=docUser.data().temporal;
      cartera.historial=docUser.data().historial;
      cartera.cartera_text=docUser.data().cartera;
    
      cartera.cartera=docUser.data().cartera;
  
      cartera.capital=docUser.data().capital;
      cartera.invertido=docUser.data().invertido;
      cartera.moneda=docUser.data().moneda;
      cartera.profit=docUser.data().profit;
      cartera.rentab_acum=docUser.data().rentab_acum;
    } catch(Exxx) {}
  
      try { cartera.type_cartera=docUser.data().type_cartera_android; if(cartera.type_cartera==null) { cartera.type_cartera=docUser.data().type_cartera; } }
      catch (exxxx) { try { cartera.type_cartera=docUser.data().type_cartera; } catch(Exxx) {} }
    
      //User banneado de carteras FREE con ads
      if(glovar.USER_BANNED_TYPE_CARTERA==1)
      {
       if(cartera.type_cartera>2) { cartera.type_cartera='2'; }
      }
     try {
      cartera.acciones=docUser.data().acciones;
      cartera.coti=docUser.data().coti;
    
      cartera.op_win=docUser.data().op_win;
      cartera.followers=docUser.data().followers;
      cartera.ranking=docUser.data().ranking;
      cartera.id=docUser.data().id;
    } catch(Exxx) {}
      //Falta comprobarlo con la BD
      cartera.subscribed=false;
    
      
    
      try 
      {
       if(glovar.listTopTradersSUBS!=null && glovar.listTopTradersSUBS.length>0)
       {
        for(let z=0; z<glovar.listTopTradersSUBS.length; z++)
        {
         try { if(glovar.listTopTradersSUBS[z].id==cartera.id) { 
           cartera.subscribed=true;
           //console.log('TROBAT SUBSCRIBED!!!')
           break } } catch(Exxxx) {}
        }
       }
      }
      catch(Exxxxa) {}
      //console.log('cartera.type_cartera:'+cartera.type_cartera);
    
      //const ID_CARTERA=docUser.data().idCartera');
    
    
      //glovar.TOPTRADERS_PRO1.push(cartera);
      glovar.CURRENT_USER=cartera;
  
      glovar.current_porfolio_ED=cartera.cartera_ed;
  
      //console.log("vencimiento web:"+docUser.data().vencimiento_web)
      //console.log('#countriesID:'+docUser.get('countriesID'));
      //console.log('#014"op_cerradas:'+docUser.data().get('op_cerradas'));
      //console.log('#### 0 TOP TRADERS!!!!!!!!:'+cartera.idCartera+' '+cartera.subscribed);
      
      //nom=glovar.TOPTRADERS_PRO1[0].idCartera//cartera.idCartera;
      //this.setState({});
     
      
     }
     }//);
  
     
     var ARRAY_COTIS="";
     glovar.CURRENT_FAVORITOS=null;
     glovar.CURRENT_FAVORITOS = [];
  
     glovar.CURRENT_PORTFOLIO=null;
     glovar.CURRENT_PORTFOLIO = [];
  
     glovar.CURRENT_HISTORIAL=null;
     glovar.CURRENT_HISTORIAL = [];
  
     let j=0;
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      //if(glovar.TOPTRADERS_PRO1[j].idCartera==glovar.CURRENT_TRADER)
      try 
      {
        //console.log("SE CUMPLE LA CONDICION!!!")
        //glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial.split('},');
        glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial;
      }
      catch(Exxx) {}
     }
  
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      
  
      //console.log("Hola 0000")
      glovar.CURRENT_FAVORITOS=glovar.CURRENT_USER.favoritos;
      //console.log("Hola 0001:"+glovar.CURRENT_USER.favoritos)
        
  
     }
  
     try 
     {
       
       glovar.CURRENT_PORTFOLIO=glovar.CURRENT_USER.cartera;
       
     
     }
     catch(Exxx) {}
  
     
     glovar.FIREBASE_LOADED=1;
    }*/

  componentDidMount() {

 

    /*<script type="text/javascript">
	atOptions = {
		'key' : 'f29a724eac91492e19bb63a483d5527b',
		'format' : 'iframe',
		'height' : 60,
		'width' : 468,
		'params' : {}
	};
	document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.effectivecreativeformat.com/f29a724eac91492e19bb63a483d5527b/invoke.js"></scr' + 'ipt>');
</script>*/

    /*try { var scr1 = document.createElement("script");
    scr1.innerHTML = `
    var scr = document.createElement("script");
    var atOptions = {
      'key' : 'f29a724eac91492e19bb63a483d5527b',
      'format' : 'iframe',
      'height' : 60,
      'width' : 468,
      'params' : {}
      }
      scr.src =
      "http" +
      (location.protocol === "https:" ? "s" : "") +
      "://www.effectivecreativeformat.com/f29a724eac91492e19bb63a483d5527b/invoke.js";
      document.querySelector("#_MainOuterIndexJs").appendChild(scr);
    `;
    document.querySelector("#_MainOuterIndexJs").appendChild(scr1);
    }
    catch(Exxx) {console.log("petada:"+Exxx)}*/

    /*getData().then(data => {
			DATA_COTIS=data;
      //console.log(JSON.stringify(DATA_COTIS))
		})*/

    
    //getDataCSV("META");
    //console.log("results:"+results)

    /*getDataCSV().then(data => {
			console.log(data);
      //console.log(JSON.stringify(DATA_COTIS))
		})*/

    document.title="Pro1.trading WEB APP"

    document.icon="assets/logopro1trading.png"

    

    try { 
      //var userLang = navigator.language || navigator.userLanguage; 
      var userLang = window.navigator.userLanguage || window.navigator.language;
      //alert(userLang); //works IE/SAFARI/CHROME/FF
      if(userLang.includes('es-') || userLang =="es") { glovar.USER_LANG_PRO1="ES" } 
      else if(userLang.includes('fr-') || userLang =="fr") { glovar.USER_LANG_PRO1="FR" } 
      else if(userLang.includes('de-') || userLang =="de") { glovar.USER_LANG_PRO1="DE" } 

    }
    catch(Exxxx) {}

    

    //var userLang = navigator.language || navigator.userLanguage; 
    //console.log("userLang:"+userLang)
    //console.log("navigator.userAgentData:"+JSON.stringify(navigator.userAgentData))

   
    //console.log("didmouuunt")
    glovar.DEFAULT_INDEX_TABS_FOLLOWS=0;
    glovar.REFRESH_FRONTUSER=false;
    glovar.DONT_REFRESH_FETCH_TODO_USER=false;

    try {
      const token = getToken();
      if (token) 
      {
        //console.log("token:"+token)
        glovar.USER_LOADED=true;
       }
      }
      catch(Exxxs) {}
  
      try {
      const userSession = getUser();
      if (userSession) //&& glovar.USER_USERNAME=='Anonimous') 
      {
        //console.log("userSession:"+userSession)
        glovar.USER_USERNAME=userSession;
       }
      }
      catch(Exxx) {}
  
      try {
        const userEmail = getEmail();
        if (userEmail)// && glovar.USER_EMAIL=='Anonimous') 
        {
          //console.log("userEmail:"+userEmail)
          glovar.USER_EMAIL=userEmail;
         }
        }
        catch(Exxx) {}

        try {
          const modeUser = getModeUser();

          //console.log("glovar.MODE_USER:"+glovar.MODE_USER)
          //if(glovar.CURRENT_FAVORITOS) { console.log("glovar.CURRENT_FAVORITOS.length:"+glovar.CURRENT_FAVORITOS.length); }
          
          if (modeUser && glovar.MODE_USER==0) 
          {
            try { glovar.MODE_USER=parseInt(modeUser); } catch(ex) {glovar.MODE_USER=modeUser;}
           }
           //console.log("modeUser:"+modeUser)

           /*
           //if(glovar.MODE_USER>=1) 
           {
            this.getFBDocs(db).then(() => 
            {
              console.log("finalizado ok!!!!!!!!!")
              this.setState({});
          //eventBus.dispatch("refreshDB", { message: "coupone applied" });
          //navigate('/')
         
        })
        .catch((error) => {
          console.error("petada:"+error);
          //eventBus.dispatch("refreshDB", { message: "coupone applied" });
          //navigate('/')
        });
           }*/
          }
          catch(Exx) {}

          try {

            try {
              //console.log("lleno TOPTRADERLIST")
            const toptraderList = getTOPTRADERSLIST();
            if (toptraderList)// && glovar.USER_EMAIL=='Anonimous') 
            {
              glovar.lastTimeTOPTRADERSLIST=getLastTimeTOPTRADERSLIST();
             //console.log("si que hay guarado!!!")
              //console.log("getLastTimeTOPTRADERSLIST:"+glovar.lastTimeTOPTRADERSLIST)
              var TimeNow=Date.now();
              var difNow=TimeNow-glovar.lastTimeTOPTRADERSLIST;
              //console.log("han pasado los milis:"+difNow)
              //console.log("1glovar.MAXMILIS_REFRESH_TOPTRADERSLIST:"+glovar.MAXMILIS_REFRESH_TOPTRADERSLIST)

              if(difNow<glovar.MAXMILIS_REFRESH_TOPTRADERSLIST) {
                glovar.TOPTRADERSLIST=JSON.parse(toptraderList);
                //console.log("utilizo cache!!")
                
              }
              else {
                //console.log("fuerzo download from server")
                //glovar.TOPTRADERSLIST=null
              }
              
              
              //console.log("cargo lista toptraders")
             }
             else {
               //console.log("no hay nada guardado TOPTRADERSLIST")
             }
            }
            catch(Exx) {}


            try {
              
            const morepularList = getMOREPOPULAR();
            if (morepularList)// && glovar.USER_EMAIL=='Anonimous') 
            {
              glovar.lastTimeMOREPOPULAR_COTIS=getLastTimeMOREPOPULAR();
             //console.log("si que hay guarado!!!")
              //console.log("glovar.lastTimeMOREPOPULAR_COTIS:"+glovar.lastTimeMOREPOPULAR_COTIS)
              var TimeNow=Date.now();
              var difNow=TimeNow-glovar.lastTimeMOREPOPULAR_COTIS;
              //console.log("han pasado los milis:"+difNow)
              //console.log("1glovar.MAXMILIS_REFRESH_MORE_POPULAR:"+glovar.MAXMILIS_REFRESH_MORE_POPULAR)

              if(difNow<glovar.MAXMILIS_REFRESH_MORE_POPULAR) {
                glovar.MOREPOPULAR_COTIS=JSON.parse(morepularList);
                //console.log("utilizo cache   MORE POPULAAR!!")
                
              }
              else {
                //console.log("fuerzo download from server")
                //glovar.TOPTRADERSLIST=null
              }
              
              
              //console.log("cargo lista toptraders")
             }
             else {
               //console.log("no hay nada guardado TOPTRADERSLIST")
             }
            }
            catch(Exx) {}

             
           //console.log("1MODE USER:"+glovar.MODE_USER) 
           //console.log("2MODE USER:"+glovar.MODE_USER) 
           //console.log("3MODE USER:"+glovar.MODE_USER) 
           //console.log("4MODE USER:"+glovar.MODE_USER) 
           //console.log("5MODE USER:"+glovar.MODE_USER) 
           if(glovar.MODE_USER>=1)
           {
          
            try {
              
              const mywatchlistList = getMYWATCHLIST();
              if (mywatchlistList)// && glovar.USER_EMAIL=='Anonimous') 
              {
                glovar.lastTimeMYWATCHLIST_COTIS=getLastTimeMYWATCHLIST();
               //console.log("si que hay guarado!!!")
                //console.log("glovar.lastTimeMOREPOPULAR_COTIS:"+glovar.lastTimeMOREPOPULAR_COTIS)
                var TimeNow=Date.now();
                var difNow=TimeNow-glovar.lastTimeMYWATCHLIST_COTIS;
                //console.log("han pasado los milis:"+difNow)
                //console.log("1glovar.MAXMILIS_REFRESH_MORE_POPULAR:"+glovar.MAXMILIS_REFRESH_MORE_POPULAR)
  
                if(difNow<glovar.MAXMILIS_REFRESH_MY_WATCHLIST) {
                  glovar.MYWATCHLIST_COTIS=JSON.parse(mywatchlistList);
                  //console.log("utilizo cache   MORE POPULAAR!!")
                  
                }
                else {
                  //console.log("fuerzo download from server")
                  //glovar.TOPTRADERSLIST=null
                }
                
                
                //console.log("cargo lista toptraders")
               }
               else {
                 //console.log("no hay nada guardado TOPTRADERSLIST")
               }
              }
              catch(Exx) {}

              try {
              
                const myportfolioList = getMYPORTFOLIO();
                if (myportfolioList)// && glovar.USER_EMAIL=='Anonimous') 
                {
                  glovar.lastTimeMYPORTFOLIO_COTIS=getLastTimeMYPORTFOLIO();
                 //console.log("si que hay guarado!!!")
                  //console.log("glovar.lastTimeMYPORTFOLIO_COTIS:"+glovar.lastTimeMYPORTFOLIO_COTIS)
                  var TimeNow=Date.now();
                  var difNow=TimeNow-glovar.lastTimeMYPORTFOLIO_COTIS;
                  //console.log("han pasado los milis:"+difNow)
                  //console.log("glovar.MAXMILIS_REFRESH_MY_PORTFOLIO:"+glovar.MAXMILIS_REFRESH_MY_PORTFOLIO)
    
                  if(difNow<glovar.MAXMILIS_REFRESH_MY_PORTFOLIO) {
                    glovar.MYPORTFOLIO_COTIS=JSON.parse(myportfolioList);
                    //console.log("refresco glovar.MYPORTFOLIO_COTIS:"+JSON.stringify(myportfolioList))
                  }
                  else {
                  }
                 }
                 else {
                   //console.log("no hay nada guardado TOPTRADERSLIST")
                 }
                }
                catch(Exx) {}

              

            
            const userFavoritos = getFavoritos();
            if (userFavoritos)// && glovar.USER_EMAIL=='Anonimous') 
            {
             //console.log("1userFavoritos:"+userFavoritos)
             glovar.CURRENT_FAVORITOS=JSON.parse(userFavoritos);
            }

            const userPortfolio = getPortfolio();
        if (userPortfolio)// && glovar.USER_EMAIL=='Anonimous') 
        {
          //console.log("userPortfolio:"+JSON.stringify(userPortfolio))
          //console.log("userPortfolio:"+userPortfolio)
          //let userPortfolio1=JSON.stringify(userPortfolio);
          glovar.CURRENT_PORTFOLIO=JSON.parse(userPortfolio);
         }

         const userHistorial = getHistorial();
         if (userHistorial)// && glovar.USER_EMAIL=='Anonimous') 
         {
           //console.log("userPortfolio:"+JSON.stringify(userPortfolio))
           //console.log("userPortfolio:"+userPortfolio)
           //let userPortfolio1=JSON.stringify(userPortfolio);
           glovar.CURRENT_HISTORIAL=JSON.parse(userHistorial);
          }

      

         const userPortfolioED = getporfolio_ED();
         if (userPortfolioED)// && glovar.USER_EMAIL=='Anonimous') 
         {
           //console.log("userPortfolioED:"+JSON.stringify(userPortfolioED))
           //console.log("userPortfolio:"+userPortfolio)
           //let userPortfolio1=JSON.stringify(userPortfolio);
           glovar.current_porfolio_ED=JSON.parse(userPortfolioED);
          }
          else {
            //onsole.log("no hay nada guardado")
          }

          
        
          

         this.setState({})


          }
            
           

          }
          catch(Exx) { /*console.log("petada al parsear:"+Exx)*/}


          //eventBus.dispatch("btnFollow_m0", { data: glovar.USER_LANG_PRO1  });

          try {
            eventBus.on("btnFollow_m0", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnFollowM0_"+data.data, "btnFollow_m0"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


          //eventBus.dispatch("btnFollow_m1", { data1: glovar.USER_USERNAME, data2: TOPTRADER.name   });
          try {
            eventBus.on("btnFollow_m1", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnFollowM_"+glovar.MODE_USER, "btnFollowM"+glovar.MODE_USER); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { this.EventGA("click_element","btnFollowM_"+glovar.MODE_USER+"_"+data.data1+"_"+data.data2, "btnFollowM"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


          try {
            eventBus.on("loginUser", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            //this.showRefreshHomefront()
           
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","LOGINOK_"+data.data, "loginOK"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}
        


          try {
            eventBus.on("RSSnew", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            //this.showRefreshHomefront()
           
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","RSSnew_"+glovar.USER_LANG_PRO1+"_"+data.data, "RSSnew"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}
        
          try {
            eventBus.on("showRefreshHomefront", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            this.showRefreshHomefront()
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


    try {
      eventBus.on("refreshDB", (data) =>
      //this.loadFBData(data)
      this.loadFBData(data) 
      //console.log("refreshDB")
    );
    }
    catch(Exxx) {}
    
    try {
      eventBus.on("gotoTopTraders", (data) =>
      {
        this.changemenu(2);
      //this.showTopTraderPortfolio(data.tt)

      //try { this.EventGA("carroTopTr","carroTopTr_"+glovar.USER_LANG_PRO1, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
      //try { this.EventGA("carroTopTr","carroTopTr_"+data.sym+"to "+data.tt.name, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}



      //console.log("data.sym:"+data.sym)
      }
    );
    }
    catch(Exxx) {}


    try {
      eventBus.on("showTopTraderfromCarr", (data) =>
      {
      this.showTopTraderPortfolio(data.tt)

      try { this.EventGA("carroTopTr","carroTopTr_"+glovar.USER_LANG_PRO1, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
      try { this.EventGA("carroTopTr","carroTopTr_"+data.sym+"to "+data.tt.name, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}



      //console.log("data.sym:"+data.sym)
      }
    );
    }
    catch(Exxx) {}


    try {
      eventBus.on("showTopTrader", (data) =>
      this.showTopTraderPortfolio(data.tt)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    try {
      eventBus.on("showElement", (data) =>
      this.showElement(data.el)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    try {
      eventBus.on("showTopTrader2", (data) =>
      this.showTopTraderPortfolio2(data.tt)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    //console.log("intento cargar la lista de toptraders")
    if(!(glovar.TOPTRADERSLIST.length>1)) 
    {
   //console.log("cargo la lista de toptradersXXX")
    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
   
  fetch(glovar.URL_BACKEND_PROD+'/api/toptraders', { method:'GET', //mode: 'no-cors',
 //fetch('https://p1tback-production.up.railway.app/api/toptraders',{ method:'GET', //mode: 'no-cors',
 headers: headers,
 //credentials: 'user:passwd'
})
.then(res => res.json())
.then(res => {
  //console.log("resposta toptraders:"+res);
  glovar.TOPTRADERSLIST=res;

  //Aqui tenemos que ordenarlo por rent.
  try { glovar.TOPTRADERSLIST.sort(function(a,b){ return parseFloat(b.rentab)  - parseFloat(a.rentab);}) }
  catch(Exxpp) {}

  setTOPTRADERSLIST(glovar.TOPTRADERSLIST);
  //console.log(res)
  //for(let ii=0; ii<res.length; ii++) { console.log("TOPTRADER: "+res[ii].name) }
});
    }

 //var isFetching=true;
 //{glovar.TOPTRADERSLIST, isFetching} = useFetchTodoToptraders();//"IAG.MC,EURUSD=X")


    const uniqueIds = [];
  
  /*const todoRSSUnique = todoRSS.filter(element => {
    const isDuplicate = uniqueIds.includes(element.link);

    if (!isDuplicate) {
      uniqueIds.push(element.link);

      return true;
    }

    return false;
  });*/

 

 //todoRSS.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });  
  /*if (!isFetching) { 
    console.log("holaaa")
    todoRSSUnique.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });   
    console.log("holaaa:"+todoRSSUnique.length)
  }*/
  }

  showRefreshHomefront() {
    glovar.REFRESH_FRONTUSER=true;
    this.setState({})
  }

  showElement(data) {

    try { this.EventGA("click_element","showElement_MAIN", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    try {
      glovar.CURRENT_MENU_ID=4;
      //SHOW_TOPTRADER_NO_EFFECT=false;
      SHOW_ELEMENT_DATA=data;
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
    
    //console.log(SHOW_PORTFOLIO_TOPTRADER_POS)
    
  }


  showTopTraderPortfolio(data) {

    try { this.EventGA("click_element","showTopTraderPortfolio_MAIN", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    //var audio = new Audio('assets/0now.mp3');
    //audio.play()

    try {
      glovar.CURRENT_MENU_ID=2;
      SHOW_TOPTRADER_NO_EFFECT=false;
      SHOW_TOPTRADER_PROFILE=data;
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
    
    //console.log(SHOW_PORTFOLIO_TOPTRADER_POS)
    
  }

 

  showTopTraderPortfolio2(data) {

    try { this.EventGA("click_element","showTopTraderPortfolio_RANKING", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    //var audio = new Audio('assets/0now.mp3');
    //audio.play()

    try {

 
  var todoPortfolio=JSON.parse("["+data.cartera+"]")

  
    //console.log("showTopTraderPortfolio2")
    //console.log(todoPortfolio)

    var ARRAY_PORT_COTIS="";
    for(let j=0; j<todoPortfolio.length; j++) 
    {
      if(todoPortfolio[j].symbol) {
        ARRAY_PORT_COTIS=ARRAY_PORT_COTIS+todoPortfolio[j].symbol+","
      }
    }
    var todoCotisPortfolio

    (async () => {
      //let response = await fetch('/article/promise-chaining/user.json');
      //let user = await response.json();
      //console.log("que pasa neeeeeeeeeeeen")
      //let githubResponse = await fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_PORT_COTIS);

      //NewVersion
      let headers = new Headers(); 
      headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
      headers.set('Parameters',btoa(ARRAY_PORT_COTIS));

      let githubResponse = await fetch(glovar.URL_FETCH_COTIS,{ method:'GET', headers: headers })
      let githubUser = await githubResponse.json();
      todoCotisPortfolio=githubUser;
      //Old version
      /*let githubResponse = await fetch(glovar.URL_FETCH_COTIS+ARRAY_PORT_COTIS);
      let githubUser = await githubResponse.json();
      //console.log("final awaaaaaaaaaaaaaat")
      todoCotisPortfolio=githubUser.quoteResponse.result;
      */

      //console.log("isFetchingCotis11:"+JSON.stringify(todoCotisPortfolio))
      glovar.CURRENT_MENU_ID=2;
      TOPTRADER_COTIS=todoCotisPortfolio;
      SHOW_TOPTRADER_NO_EFFECT=true;
      SHOW_TOPTRADER_PROFILE=data;
      this.setState({});
    })();

    //var todoCotisPortfolio=showAvatar(ARRAY_PORT_COTIS);

      //const { todoCotisPortfolio, isFetchingCotis } = useFetchTodoPortfolio(ARRAY_PORT_COTIS)//"IAG.MC,EURUSD=X")

      
      

      
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  //this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
  }



   
  
   render() {

    const EventGA = (category, action, label) => {
      ReactGA.event({
        category: category,
        action: action,
        label: label
      });
    };

    
    function ShowSearchBar(params) {

      const [apiResult, setApiResult] = useState([]);
      const [texte, setTexte] = useState('');

      var cars = [];

      var MOBILE=false;
      var leftsearchbox='12pc';
      var WITH_BOX_SEARCH='30pc';

      try { if(params.type==1) { 
              MOBILE=true; 
              leftsearchbox='0pc';
              WITH_BOX_SEARCH='22pc'
            } 
          } catch(Exxx) {}

      //https://query1.finance.yahoo.com/v1/finance/search?q=pedo

      function HandleChange(e)
      {

        try { if(e.target.value.length<=0) { setApiResult([])} } catch(Exx) {}
        var SEARCH_STRING=e.target.value;
        
        if(e.target.value.length>0)
        {
     
          const link = "https://thingproxy.freeboard.io/fetch/https://query1.finance.yahoo.com/v1/finance/search?q="+SEARCH_STRING;
          //console.log("link:"+link);

    fetch(link).then(response => response.json())
   .then(json => {
    
    //console.log("complete:"+JSON.stringify(json))
        try {
          glovar.READY_TO_READ=true;
          glovar.LAST_ELEMENT_SEARCH=json;
          //console.log("le doy valor true!!!")
          
        }
        catch (Exx) {}
        setApiResult(json.quotes)
        //setTodo(CHART_COTIS)
        //setIsFetching(false);
        /*results.data.map((d) => {
          //console.log("d")
        });*/
        //console.log("All done!");

   })
   .catch((e) => {
    {
      //var TICKER="AAPL"
      //console.log("ERROR PARSING:"+e)
      let headers = new Headers(); 
     headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
     headers.set('Parameters',btoa(SEARCH_STRING));
  
  
     fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
     headers: headers,
     })
    .then(res => res.json())
    .then(res => {
       //console.log("res:"+res)
      if(res=="auth/user-not-found" || res=="auth/wrong-password") {
           return;
      }
  
      if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
        return;
      }
  
      //console.log(res)
      setApiResult(res)
      //setTodoData(res) 
      //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

      //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
      /*switch(SEARCH_STRING.length) { 
        case 0: setApiResult([]); break;
        case 1: setApiResult(['AAPL']); break;
        case 2: setApiResult(['AAPL', 'DOCN']); break;
        case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
        case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
        default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
      }*/
      //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
    })
    .catch((e) => {
      //console.log(e);
     })
     .finally(() => {
      //setIsFetchingSearch(false);
     
     });
    
    }

   })
   .finally(() => {
   //setIsFetching(false);
   });


    

   
 
  
  
   
  
  }

        //console.log("mierdaaaaaaaaaaa")
        //cars = ['Ford', 'BMW', 'Audi'];

       // const { todoSearch, isFetchingSearch } = useFetchTodoSearch(e.target.value)//"IAG.MC,EURUSD=X")  
    
        /*if(!isFetchingSearch) {
          setApiResult(todoSearch)
        }*/
        if(1==2)
        {
          //var TICKER="AAPL"
          
          let headers = new Headers(); 
         headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
         headers.set('Parameters',btoa(SEARCH_STRING));
      
      
         fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
         headers: headers,
         })
        .then(res => res.json())
        .then(res => {
           //console.log("res:"+res)
          if(res=="auth/user-not-found" || res=="auth/wrong-password") {
               return;
          }
      
          if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
            return;
          }
      
          //console.log(res)
          setApiResult(res)
          //setTodoData(res) 
          //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

          //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
          /*switch(SEARCH_STRING.length) { 
            case 0: setApiResult([]); break;
            case 1: setApiResult(['AAPL']); break;
            case 2: setApiResult(['AAPL', 'DOCN']); break;
            case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
            case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
            default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
          }*/
          //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
        })
        .catch((e) => {
          //console.log(e);
         })
         .finally(() => {
          //setIsFetchingSearch(false);
         
         });
        
        }
    

        //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
        //console.log("X:"+e.target.value)
        /*
        setTexte(e)
        
        
        
        //api(e.target.value)
        //  .then((result)=>{
        //    setApiResult(result);
        //  });
          
          //const queryOptions = { lang: 'es-ES', region: 'ES' };
          //const queryOptions = {  lang: glovar.APP_FORCE_LANG };
          
          //yahooFinance2.autoc(e,queryOptions).then(function(response) {
    
           //const queryOptions = {  lang: glovar.APP_FORCE_LANG, region: 'ES' };
    
           //const queryOptions = { modules: ['price'] }; // defaults
    
           //const queryOptions = {  enableCb:false,newsCount:0, quotesCount:20, lang: glovar.APP_FORCE_LANG, region: 'ES' };
           const queryOptions = {
            quotesCount: 20,
            enableCb: false,
            //enableFuzzyQuery:true,
            newsCount: 0,
            //lang: "es-ES",
            region: "ES"
          };
            yahooFinance2.search(e, queryOptions,{ validateResult: false }).then(function(response) {
            
            //console.log('YAHOO FINANCE:'+JSON.stringify(response.Result))
            //console.log('03YAHOO FINANCE:'+JSON.stringify(response.quotes))
            
            //setApiResult(response.Result)
            setApiResult(response.quotes)
            //console.log('Successfully UNsubscribed to topic:', response);
          })
          .catch(function(error) {
            //console.log('Error UNsubscribing to topic:', error);
          })
          */
      };

      /*function gotoSymbol(props) {
        glovar.LAST_ELEMENT_SEARCH=props.brand;
        glovar.READY_TO_READ=true;
        console.log("holaaa:"+JSON.stringify( glovar.LAST_ELEMENT_SEARCH))
        //window.location.replace('/symbol/'+props.brand.symbol) 
      }*/

      function CardTicker(props) {
        return(
        
        
        <Box alignItems="center"  _hover={{ cursor: "pointer" }}  backgroundColor="#F6F6F6"
        pl={3} pr={3} pt={3} pb={3} mt={2} 
         onClick= {() => { 
          //gotoSymbol(props)
          window.location.replace('/symbol/'+props.brand.symbol) 
          try { EventGA("search_bar","showSearch_"+props.brand.symbol, props.brand.symbol); } catch (exx) { /*console.log("petada event:"+exx)*/}
        }
         }>
            
            <HStack flexDirection="row" justifyContent="space-between">
            <Text>{props.brand.shortname ? `${props.brand.shortname}` : `${props.brand.longname}`}</Text>
            <Flex flexDirection="column" align="flex-end">
            <Text fontWeight="bold">{ props.brand.symbol }</Text>
            <Text >{props.brand.typeDisp}-{props.brand.exchange}</Text>
            </Flex>
            </HStack>
            </Box>);
        //return (<Flex alignItems="center"  >I am a { props.brand }</Flex>);
      }

     /*
      <TouchableOpacity onPress={()=>addToPORTFOLIO(name)}>
      <View style={styles.item}>
        <View style={styles.firstrow}>
        <Text style={styles.name}>{name.shortname ? `${name.shortname}` : `${name.longname}`}</Text>
        <View style={styles.firstcolumn}>
        <Text style={styles.ticker}>{name.symbol}</Text>
        <Text style={styles.equity}>{name.typeDisp}-{name.exchange}</Text>
        </View>
        </View>
      </View>
      </TouchableOpacity>
      */

      return(

        <Box zIndex={20}  ml={leftsearchbox}> 
        <Input
        placeholder={i18n.t("busca_activo")}
         focusBorderColor='gray.400'
         borderColor='gray.400'
        mt='8px'
       
        type="search"
        width={WITH_BOX_SEARCH}//'30pc'
        onChange={HandleChange}
      /> 


<Box background="white" mixHeight={600} width={WITH_BOX_SEARCH}/*'30pc'*/>
        {apiResult.map((result) => <CardTicker brand={result} />)}
        </Box>

      </Box>
      );
    }



    function CarruselTopTradersOLD(params) {

      var llistatTickers=[]
      //console.log("params.data:"+JSON.stringify(params.data[0]))
      //console.log("antes bucleee!")
      for(let z=0;z<params.data.length;z++) {

        //console.log("dentro bucleee!")
        try { 
          //console.log("hola:"+params.data[z].relatedTickers);

          var array=params.data[z].relatedTickers;
          for (let zz=0;zz<array.length;zz++) {

            //console.log("array[zz]:"+array[zz]+" vs "+params.symbol)
            if(!llistatTickers.includes(array[zz]) && params.symbol!=array[zz]) {
               llistatTickers.push(array[zz])
            }
          }

        }
        catch(Exx) {}
        /*
        var array=params.data[z];
        console.log("array.length:"+JSON.stringify(array.size))

        for (let zz=0;zz<array.length;zz++) {

          console.log("array[zz]:"+array[zz])
          if(!llistatTickers.includes(array[zz])) {
             llistatTickers.push(array[zz])
          }
        }*/
      }
      
      //console.log("llistatTickers:"+llistatTickers)
      if(params.data!=null) 
      {
        return(
          <Flex background="white" flexDirection="row" mt={2} >
          {llistatTickers.filter((result, idx) => idx <10).map((result) => 
           
           <Box marginRight={2} p={1} pr={1.5} pl={1.5} _hover={{ cursor: "pointer" }}  rounded={9} background="black" textColor="white" fontWeight="semibold" fontSize={14}
           onClick= {() => { 
            //gotoSymbol(props)
            window.location.replace('/symbol/'+result) 
            try { EventGA("carroussel","carroussel_"+glovar.USER_LANG_PRO1, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { EventGA("carroussel","carrou_"+params.symbol+"to "+result, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
          }
           }
           > {result} </Box>
           
           )}
          </Flex>
        );
      }

    }
  

    function CarruselTickers(params) {

     


      var llistatTickers=[]
      //console.log("params.data:"+JSON.stringify(params.data[0]))
      //console.log("antes bucleee!")
      for(let z=0;z<params.data.length;z++) {

        //console.log("dentro bucleee!")
        try { 
          //console.log("hola:"+params.data[z].relatedTickers);

          var array=params.data[z].relatedTickers;
          for (let zz=0;zz<array.length;zz++) {

            //console.log("array[zz]:"+array[zz]+" vs "+params.symbol)
            if(!llistatTickers.includes(array[zz]) && params.symbol!=array[zz]) {
               llistatTickers.push(array[zz])
            }
          }

        }
        catch(Exx) {}
        /*
        var array=params.data[z];
        console.log("array.length:"+JSON.stringify(array.size))

        for (let zz=0;zz<array.length;zz++) {

          console.log("array[zz]:"+array[zz])
          if(!llistatTickers.includes(array[zz])) {
             llistatTickers.push(array[zz])
          }
        }*/
      }
      
      //console.log("llistatTickers:"+llistatTickers)
      if(params.data!=null) 
      {
        return(
          <Flex background="white" flexDirection="row" mt={2} >
          {llistatTickers.filter((result, idx) => idx <10).map((result) => 
           
           <Box marginRight={2} p={1} pr={1.5} pl={1.5} _hover={{ cursor: "pointer" }}  rounded={9} background="black" textColor="white" fontWeight="semibold" fontSize={14}
           onClick= {() => { 
            //gotoSymbol(props)
            window.location.replace('/symbol/'+result) 
            try { EventGA("carroussel","carroussel_"+glovar.USER_LANG_PRO1, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { EventGA("carroussel","carrou_"+params.symbol+"to "+result, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
          }
           }
           > {result} </Box>
           
           )}
          </Flex>
        );
      }

    }
  

    function ShowFitxaElement(params) {

      //ReactGA.pageview("hola!!!"); //window.location.pathname + window.location.search);
      //console.log("window.location.pathname + window.location.search")
      //Send pageview with a custom path
      try { ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search }); } catch(Exx) {}
      //console.log(window.location.pathname + window.location.search)
      //ReactGA.modalview('/about/contact-us');

      //getDataCSV("AAPL");

      var element;
      var ticker_symbol;
      var FULL_LOAD=false;
      var TAG_RSS=false;

      var FROM_URL_MENU=0;

      try { if(params.fromURLMenu!=null) { FROM_URL_MENU=params.fromURLMenu; } } catch(Exx) {}
      
      console.log("FROM_URL_MENU:"+FROM_URL_MENU);


      let { ticker_param } = useParams();

      if(params.element_data==null) 
      {
        //console.log("URL EXTERNA:"+JSON.stringify(params));
        
        //console.log("ticker:"+ticker_param);
        ticker_symbol=ticker_param;
        FULL_LOAD=true;
      }
      else {
        element=params.element_data;
        ticker_symbol=params.element_data.symbol;
        //console.log("datos:"+JSON.stringify(element));
      }
      try { EventGA("click_element","showFitxaElem_"+glovar.USER_LANG_PRO1, glovar.USER_LANG_PRO1); } catch (exx) { /*console.log("petada event:"+exx)*/}
      try { EventGA("click_element","showFitxaElement_"+ticker_symbol, ticker_symbol); } catch (exx) { /*console.log("petada event:"+exx)*/}

      //console.log("READY_TO_READ:"+ glovar.READY_TO_READ);
      if ( glovar.READY_TO_READ) 
      {

        glovar.READY_TO_READ=false;
        //console.log(glovar.LAST_ELEMENT_SEARCH);
         
      }
      //const { todoHistory, isFetchingHistory } = useFetchTodoHistory(ticker_symbol)//"IAG.MC,EURUSD=X")  

      const { todoHistory, todoData, isFetchingHistory, isFetchingData } = useFetchTodoHistory(FULL_LOAD,ticker_symbol)//"IAG.MC,EURUSD=X")  
    

      //var listRSStags=[]
      //Aqui discernimos si tiene tag o no
      //ticker_symbol,name
      //https://cincodias.elpais.com/tag/rss/meta_platforms/
      TAG_RSS=false;
      //listRSStags=[];


      //filtramos eurostoxx pq no hay news en yahoo
      var ticker_symbol_SEARCH=ticker_symbol;
      
      try { if(ticker_symbol_SEARCH=="^STOXX50E") {ticker_symbol_SEARCH="^FCHI"} } catch(Exxx) {}
      try { if(ticker_symbol_SEARCH=="^NDX") {ticker_symbol_SEARCH="^IXIC"} } catch(Exxx) {}

      //const { todoNews, searchedNews, isFetchingNews } = useFetchTodoNewsSearch(ticker_symbol)//"IAG.MC,EURUSD=X")  
      const { todoNews, searchedNews, isFetchingNews } = useFetchTodoNewsSearch(ticker_symbol_SEARCH)//"IAG.MC,EURUSD=X")  
  
    


      if(FULL_LOAD) {
        if(!isFetchingData) {
          element=todoData;
        }
      }
    //const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(params.OWN_USER,id)//"IAG.MC,EURUSD=X")
    //const { todoHistory, isFetchingHistory } = useFetchTodoHistory(params.element_data.symbol)//"IAG.MC,EURUSD=X")  
    //const { todoHistory, isFetchingHistory } = useFetchTodoHistory("META")//"IAG.MC,EURUSD=X")  


    //if(!isFetchingHistory) 
    if(!FULL_LOAD || !isFetchingData)
    {



      //console.log("POS 1:"+JSON.stringify(DATA_COTIS[0]))
      //console.log("POS2 1:"+JSON.stringify(todoHistory[0]))
      //console.log("POS2 1:"+JSON.stringify(glovar.DATA_COTIS2[0]))
      //element_data
      //paràmetres obtinguts per URL
      //let { id } = useParams();

      //var element=params.element_data;

      //console.log(JSON.stringify(element))
     
     //name={todo.shortName} price={todo.regularMarketPrice} diff={todo.regularMarketChangePercent} moneda={m}
  
     var name=element.shortName;
     var price=element.regularMarketPrice;
     var  diff=element.regularMarketChangePercent;


     var moneda="$"
     try { moneda=getMONEDADivisa(element.currency,element.quoteType); } catch(Exx) {}
     //if(element.currency=="EUR") {moneda="€"}

     //####################//
    //# Check names      #//
    //####################//
    if(name=="IBEX 35...") {name="IBEX 35"; }
    if(name=="ESTX 50 PR.EUR") { name="EuroStoxx 50"}
    

    //if(!isFetchingTopTraders || PROFILE_CACHE) 
    {
  
      let color1="#1FB31F"
      let color2="#FFFFFF"
  
      var subtitle="subtitle"
      var title=name;
      var percent=Number(diff).toFixed(2);
      var change=Number(element.regularMarketChange).toFixed(2);
      var loss=Number(diff).toFixed(2);
      var coti=price;
      var loss=(diff<0);
  
      var simbol="";
  
      try {
      if(coti<1) 
      {
        
          var text = coti.toString();
          var index = text.indexOf(".");
          if(index>=1) {
          var index2 = ((text.length - index - 1));
          if(index2>5) { coti=coti.toFixed(5)} 
          //console.log("decimaleeeeeeeeeeees:"+index2)
          }
      }
      }
      catch(Exxx) {}
  
      //const icon = () => { return(<FiArrowDown />); }
  
      if(diff>0) {
          color1="#1FB31F"
            color2="#FFFFFF"
            simbol="+"
      }
      else {
          color1="#D61A18"
          color2="#FFFFFF"
      }
  
  
      var subtitle="subtitle"
      var title=name;
      var percent=Number(diff).toFixed(2);
      var change=Number(element.regularMarketChange).toFixed(2);
      var loss=Number(diff).toFixed(2);
      var coti=price;
      var loss=(diff<0);
  
      var simbol="";
  
      var RANGO_52S='35%';
  var num52s=35;

  var bookValue='N/A';
  try { if(element.bookValue!=null) { bookValue=(element.bookValue)+moneda; } } catch(Exxx){} //Book Value Per Share


  var priceToBook='N/A'; //(element.priceToBook); //Price/Book
  try { if(element.priceToBook!=null) priceToBook= parseFloat(element.priceToBook).toFixed(4); } catch(Exxx) {}

  var BPA_est_2021='N/A'
  try { if(element.epsCurrentYear!=null) { BPA_est_2021=(element.epsCurrentYear)+moneda; } } catch(Exxxx) {} //EPS

  var PER_est_2021='N/A';  //PE Ratio
  try { if(element.priceEpsCurrentYear!=null) { PER_est_2021=parseFloat((element.priceEpsCurrentYear)).toFixed(2); }} catch(Exxxx) {} //PE Ratio
  
  var BPA_proxanno='N/A';
  try { if(element.epsForward!=null) { BPA_proxanno=(element.epsForward)+moneda;}} catch(Exxxx) {}

  var PER_proxanno='N/A';
  try { if(element.forwardPE!=null) { PER_proxanno=parseFloat((element.forwardPE)).toFixed(2);}} catch(Exxxx) {}
   
  var sharesOutstanding='N/A';
  try { if(element.sharesOutstanding!=null) { sharesOutstanding=element.sharesOutstanding;}} catch(Exxxx) {}

  var firstTrade='N/A'
  
  try { var fecha1=new Date(element.firstTradeDateMilliseconds);
  //if(fecha1!=null && fecha1!='Invalid Date') { firstTrade=moment(fecha1).format('ll'); }; 
  } catch(Exxx) {}


  var PER='N/A';
try { if(element.trailingPE!=null) { PER=element.trailingPE; PER=parseFloat(PER).toFixed(2);}}  catch(Exxx) {}

var benef_accion='N/A';
try { if(element.epsTrailingTwelveMonths!=null) {benef_accion=element.epsTrailingTwelveMonths+moneda}}  catch(Exxx) {}

var Dividendo='N/A';
try { if(element.trailingAnnualDividendRate!=null) {Dividendo=element.trailingAnnualDividendRate+moneda}}  catch(Exxx) {}

var Rent_dividendo='N/A';
try { if(element.trailingAnnualDividendYield!=null) {Rent_dividendo=(element.trailingAnnualDividendYield*100)+'%'; 
                                                      Rent_dividendo=parseFloat(Rent_dividendo).toFixed(2)+'%'; }}  catch(Exxx) {}

var fecha_resultados=i18n.t("Desconocida"); //'Desconocida'
//earningsTimestamp
try { var fecha=new Date(element.earningsTimestampStart);
    //console.log('prefecha:'+element.earningsTimestampStart);
    //console.log('fecha:'+fecha)
      //if(fecha!=null && fecha!='Invalid Date') { fecha_resultados=moment(fecha).format('ll'); }

     fecha=new Date(element.earningsTimestampEnd);
     //if(fecha!=null && fecha!='Invalid Date') { fecha_resultados=fecha_resultados+'-'+moment(fecha).format('ll'); }


}
catch(Exep) {}



var marketCAP=0;
try {
    if(element.marketCap>0)
    {
     marketCAP=element.marketCap;
     marketCAP=getMarketCAP(element.marketCap,'es') 
    }
  }
  catch(Exasdad) { }
  

var rent=element.regularMarketChangePercent;//3.26
var benef=element.regularMarketChange;//25.13

rent= parseFloat(rent).toFixed(2);
benef=parseFloat(benef).toFixed(3);

try { if(element.regularMarketPrice<1) { benef=element.regularMarketChange; benef=parseFloat(benef).toFixed(4); } } catch(Exxx) {}

var LowSesion=element.regularMarketDayLow;
  var HighSesion=element.regularMarketDayHigh;
  var ticker=element.symbol;
  var Volume=element.regularMarketVolume;



  try 
  {
      //var cotix=valor_coti;
      //cotix=12.4;
    var num52=(element.fiftyTwoWeekHigh-element.fiftyTwoWeekLow);
    var num52L=(coti-element.fiftyTwoWeekLow);
    num52s=(num52L/num52)*100;
    //console.log('num52s:'+num52s)
    num52s=parseInt(num52s*0.95);
    //console.log('num52s:'+num52s)
    RANGO_52S=num52s+'%'
    //RANGO_52S="95%"
  }
  catch(exxxxx) { /*console.log('petadaaaaaaa:'+exxxxx)*/ }

      try {
      if(coti<1) 
      {
        
          var text = coti.toString();
          var index = text.indexOf(".");
          if(index>=1) {
          var index2 = ((text.length - index - 1));
          if(index2>5) { coti=coti.toFixed(5)} 
          //console.log("decimaleeeeeeeeeeees:"+index2)
          }
      }
      }
      catch(Exxx) {}
  
      //const icon = () => { return(<FiArrowDown />); }
  
      if(diff>0) {
          color1="#1FB31F"
            color2="#FFFFFF"
            simbol="+"
      }
      else {
          color1="#D61A18"
          color2="#FFFFFF"
      }

      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
      {
  //return( <AddContentApp mode={4}></AddContentApp>);

  return (
    <Box  backgroundColor="#F3F2EF" pb={"120px"}><Box>
   <Flex p={2} backgroundColor="#FFFFFF" maxHeight="80px" flexDirection="row" justifyContent="space-between">
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
        <Menu>
  <MenuButton as={Button}>
    -
  </MenuButton>
  {!glovar.USER_LOADED&&<MenuList>
    <MenuItem  onClick= {() => {
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/login') }
          }>{i18n.t("login")}</MenuItem>
    <MenuItem onClick= {() =>
          {
           // try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/registerApp') 
           if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
          }}>{i18n.t("signup")}</MenuItem>
  </MenuList>}
  {glovar.USER_LOADED&&<MenuList>
    <MenuItem minH='48px' 
    onClick= {() =>{ 
      try { EventGA("button_sup","ClickMBSupAvatar", "ClickSupMBAvatar"); } catch (exx) {} /*this.changemenu(3)*/ }
    }>
    <Avatar w={12} h={10} mr={4}></Avatar>
      <span>{glovar.USER_USERNAME}</span>
    </MenuItem>

    <MenuItem onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>{i18n.t("m_cerrarses")}</MenuItem>
  </MenuList>}
</Menu>
       

           

    </Flex>
    <Box  backgroundColor="#FFFFFF" mt={-2} pb={2} pl={2} alignContent="center">
    <ShowSearchBar type={1}/>
    </Box>
  </Box>

    <SimpleGrid columns={14} gap={0} p={0}>
  
     
      
      {1==1&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2}> 
          
      {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="16pc"> 
          
      
          <HStack ml={4} mr={4} mt={2}  justifyContent="space-between">
           <Text mt={0} fontSize="26" textColor="black"  fontWeight="semibold" > 
            {name}       
           </Text>
          </HStack>
          
         <HStack  ml={4} mr={4} justifyContent="space-between">
         <VStack alignItems='left'>
         <Text fontSize="36" mt={-4} mb={-4} fontWeight="bold" >{coti}{moneda}</Text>
         <Text paddingLeft={1} fontSize="16" textColor="black" mb={-1} fontWeight="normal" > 
            {element.regularMarketDayLow}{moneda} -   {element.regularMarketDayHigh}{moneda}     
           </Text>
           </VStack>
         <div alignItems='right'>
         <Text mt={-2} fontSize="18" align="right" textColor="black" textAlign="right" fontWeight="bold" > 
            {element.symbol}       
           </Text>
         <HStack>
         <Text
             fontSize="16"
             textColor={loss ? "red" : "green"}
             fontWeight="semibold"
             align="right"
             //w={"2pc"}
           >
             {simbol}{change}
           </Text>
         <Box
           borderRadius="lg"
           fontSize="16"
           fontWeight="semibold"
           textAlign="center"
           paddingTop={0.5} paddingBottom={0.5}
           paddingLeft={4}paddingRight={4}
           //w={"4pc"}
           //h={"1.5pc"}
           bg={color1}
           textColor={color2}
         >
           {simbol}{percent}%
         </Box>
        
         </HStack>
         <Text fontSize="13" align="right" textColor="black"  textAlign="right" fontWeight="normal" mb={4} > 
           Vol: {element.regularMarketVolume}       
           </Text>
         </div>
   
   
       
   
         </HStack>

         <CarrusselTopTraders symbol={element.symbol} mobile={1}/>

         {(glovar.MODE_USER<2)&&
         <HStack  background="lightgray"  marginLeft="20px" marginRight="20px" rounded={9} mt="1pc" justifyContent='space-around'>
           <AdComponent320/></HStack>}
   
         {(glovar.MODE_USER<2)&&<Text fontSize="15" align="left" ml={5} mt="1pc"/*"5pc"*/ mb="0px"/*"10px"*/ textColor="#666666"  fontWeight="semibold" > 
         {i18n.t("rango_52s")}  
           </Text>}
   
           {(glovar.MODE_USER>=2)&&<Text fontSize="15" align="left" ml={5} mt="5pc" mb="10px" textColor="#666666"  fontWeight="semibold" > 
         {i18n.t("rango_52s")}  
           </Text>}
   
           <HStack style={{ marginTop:4, marginLeft:20, marginRight:20, height:10, flexDirection: 'row', backgroundColor:'#E0E0E0' }}>
             </HStack>
           
             <HStack style={{ marginLeft:20}}> 
             <Image style={{  marginLeft:RANGO_52S, width: 16, height: 16, marginTop:-10, resizeMode: 'contain', }} 
            src={`${process.env.PUBLIC_URL}/assets/triangleok.png`}/>
             </HStack>
   
           <HStack style={{  marginBottom:"0.5pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: 4 }}>
           <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
           <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
           </HStack>
   
          
         </GridItem>}


            </GridItem>}

            {(FROM_URL_MENU==1)&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1} pt={1} pb={2}> 
            <Text style={{ color: 'black',fontSize: 14, fontWeight:500, marginLeft:"10px",  paddingBottom:"4px"}}>
              TopTraders Ranking</Text>
      <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </GridItem>}

      <GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={2} mt={1}> 
      {!params.OWN_USER&&<AddContentApp mode={5} mobile={1}></AddContentApp>}
      

      </GridItem>
    </SimpleGrid>
  
  
  
  
    <SimpleGrid columns={14} p={0} gap={2} m={0} >

    <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >
  {isFetchingNews&&<Box mt={10}>
  <AddContentApp mode={5}></AddContentApp>
  </Box>}
  {!isFetchingNews&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={0.5} mt={0} >

  <Tabs isFitted  variantColor="red" mr={0} ml={0}  defaultIndex={0} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}></Tab>
    
  </TabList>

  <TabPanels>
    <TabPanel>
      

<Box overflowY="auto"mr={-2} ml={-5}  maxHeight={400}>
<RSSNewsList listRSS={todoNews} tag={searchedNews} tagStock={true}></RSSNewsList>
</Box>
    </TabPanel>
    
  </TabPanels>
</Tabs>





   </GridItem>}
   </GridItem>

    <GridItem colSpan={14} rounded={10}   backgroundColor="#FFFFFF" marginRight={2} marginLeft={2}> 
     


      <VStack mb={0} mt={0}>   
      {1==3&&glovar.DATA_COTIS2&&<Chart  data={glovar.DATA_COTIS2} colorLine={color1} />}
      {1==3&&!glovar.DATA_COTIS2&&<Chart  data={DATA_COTIS} colorLine={color1} />}
      {!isFetchingHistory&&<Chart  data={todoHistory} colorLine={color1} />}
      </VStack> 

      </GridItem>
  
       
      

  <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}  >
  
  <Tabs isFitted  variantColor="red" mr={2} ml={2}  defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("datos_tecnicos")}  </Tab>
    {1==2&&<Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black',  borderBottomColor: "black" /*bg: 'blue.500'*/ }}>{i18n.t("info_fin")}</Tab>}
  </TabList>
  <TabPanels>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    
       <HStack mt="1.2pc" mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('apertura')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketOpen}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cierre_ant')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketPreviousClose}{moneda}</Text>
        </HStack></HStack>
        
        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cap_bursatil')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{marketCAP}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('pres_resultados')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{fecha_resultados}</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekLowChange}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_p_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{(element.fiftyTwoWeekLowChangePercent*100).toFixed(2)}%</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol3m')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume3Month}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol10d')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume10Day}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('per_ratio')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{PER}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('benef_acc')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{benef_accion}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('dividendo')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Dividendo}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('rent_div')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Rent_dividendo}</Text>
        </HStack></HStack>

        <Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>
         <CarruselTickers data={todoNews} symbol={element.symbol}/>
       


    </TabPanel>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    </TabPanel>
  </TabPanels>
</Tabs>
  
     </GridItem>
   </SimpleGrid> 
  
  
  
  
  </Box> 
   );
      }
      else
  {
   return (
    
    <Box  backgroundColor="#F3F2EF" pb={"80px"}>
    <Box>
   <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
       
        <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} h="54px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoFitxDT", "pslogoFitxDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') 
        }} />
           <Spacer />


            <ShowSearchBar/>
       

            <Spacer />
         <HStack fontSize="2xl" spacing="8" marginRight="8pc">
             {1==2&&<FiBell />}
             {1==2&&<FiSettings />}
           
             {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); } catch (exx) {} this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}
            {!glovar.USER_LOADED&&<Box>
              <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
            onClick= {() =>  {
              try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') 
            }}
            style={{  marginRight:"1pc", width: "10pc", textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            if(glovar.REGISTER_ONLINE==true) 
            {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            } }
            }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
             
         </HStack>
   </Flex>
  </Box>
    <SimpleGrid columns={14} gap={1} p={2}>
      <GridItem colSpan={1}>
      <Box mt={4}>
    <VStack>
      <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_briefcase_white_48dp.png`} id={201} />
      <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_podium_white_24dp.png`} id={202} />
      {glovar.MODE_USER>=1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_share_variant_white_24dp.png`} id={3} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_24dp.png`} id={3} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} id={4} />}
    </VStack>
  </Box>
      </GridItem>
     
      
      {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="16pc"> 
          
      
       <HStack ml={4} mr={0} mt={1}  justifyContent="space-between" width="100%">
        <Text width="100%" mt={0} fontSize="28" textColor="black"  fontWeight="semibold" > 
         {name}       
        </Text>
        <Box width="45%">
     
      <AddContentApp mode={7} name={name} symbol={element.symbol}></AddContentApp>
      
      </Box>
       </HStack>
       
      <HStack  ml={4} mr={4} justifyContent="space-between">
      <VStack alignItems='left'>
      <Text fontSize="36" mt={-4} mb={-4} fontWeight="bold" >{coti}{moneda}</Text>
      <Text paddingLeft={1} fontSize="16" textColor="black" mb={-1} fontWeight="normal" > 
         {element.regularMarketDayLow}{moneda} -   {element.regularMarketDayHigh}{moneda}     
        </Text>
        </VStack>
      <div alignItems='right'>
      <Text mt={-4} fontSize="20" align="right" textColor="black" textAlign="right" fontWeight="bold" > 
         {element.symbol}       
        </Text>
      <HStack>
      <Text
          fontSize="16"
          textColor={loss ? "red" : "green"}
          fontWeight="semibold"
          align="right"
          //w={"2pc"}
        >
          {simbol}{change}
        </Text>
      <Box
        borderRadius="lg"
        fontSize="16"
        fontWeight="semibold"
        textAlign="center"
        paddingTop={0.5} paddingBottom={0.5}
        paddingLeft={4}paddingRight={4}
        //w={"4pc"}
        //h={"1.5pc"}
        bg={color1}
        textColor={color2}
      >
        {simbol}{percent}%
      </Box>
     
      </HStack>
      <Text fontSize="13" align="right" textColor="black"  textAlign="right" fontWeight="normal" > 
        Vol: {element.regularMarketVolume}       
        </Text>
      </div>


    

      </HStack>

      
      <CarrusselTopTraders symbol={element.symbol}/>
       

      {(glovar.MODE_USER<2)&&
      <HStack  background="lightgray"  marginLeft="20px" marginRight="20px" rounded={9} mt="0.7pc" justifyContent='space-around'>
        <AdComponent468/></HStack>}

      {(glovar.MODE_USER<2)&&<Text fontSize="15" align="left" ml={5} mt="0pc"/*"5pc"*/ mb="0px"/*"10px"*/ textColor="#666666"  fontWeight="semibold" > 
      {i18n.t("rango_52s")}  
        </Text>}

        {(glovar.MODE_USER>=2)&&<Text fontSize="15" align="left" ml={5} mt="3pc" mb="10px" textColor="#666666"  fontWeight="semibold" > 
      {i18n.t("rango_52s")}  
        </Text>}

        <HStack style={{ marginTop:0, marginLeft:20, marginRight:20, height:10, flexDirection: 'row', backgroundColor:'#E0E0E0' }}>
          </HStack>
        
          <HStack style={{ marginLeft:20}}> 
          <Image style={{  marginLeft:RANGO_52S, width: 16, height: 16, marginTop:-10, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/triangleok.png`}/>
          </HStack>

          {(glovar.MODE_USER<2)&&<HStack style={{  marginBottom:"0pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: -4 }}>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
        </HStack>}

        {(glovar.MODE_USER>=2)&&<HStack style={{  marginBottom:"0.5pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: 4 }}>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
        </HStack>}


       
      </GridItem>}
      <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={0}> 
     


      <VStack mb={2} mt={2}>   
      {1==3&&glovar.DATA_COTIS2&&<Chart  data={glovar.DATA_COTIS2} colorLine={color1} />}
      {1==3&&!glovar.DATA_COTIS2&&<Chart  data={DATA_COTIS} colorLine={color1} />}
      {!isFetchingHistory&&<Chart  data={todoHistory} colorLine={color1} />}
      </VStack> 

      </GridItem>
    </SimpleGrid>
  
  
  
  
    <SimpleGrid columns={14} gap={1} p={2} mt={-1} >
  
  <GridItem colSpan={1}>
   
  
    <Box mt={2}>
  <VStack>
    {1==1&&<ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_twitter_white_24dp.png`} id={101} />}
    {1==1&&<ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_instagram_white_24dp.png`} id={102} />}
    
  </VStack>
  </Box>
    </GridItem>
  
  
    {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="20pc"> 

       
    {(FROM_URL_MENU==1&&1==2)&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1} pt={1} pb={2}> 
            <Text style={{ color: 'black',fontSize: 14, fontWeight:500, marginLeft:"10px",  paddingBottom:"4px"}}>
              TopTraders Ranking</Text>
      <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </GridItem>}

      
    <Tabs isFitted  variantColor="red" mr={2} ml={2}  defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("datos_tecnicos")}  </Tab>
    {1==2&&<Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black',  borderBottomColor: "black" /*bg: 'blue.500'*/ }}>{i18n.t("info_fin")}</Tab>}
  </TabList>
  <TabPanels>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    
       <HStack mt="1.2pc" mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('apertura')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketOpen}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cierre_ant')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketPreviousClose}{moneda}</Text>
        </HStack></HStack>
        
        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cap_bursatil')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{marketCAP}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('pres_resultados')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{fecha_resultados}</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekLowChange}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_p_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{(element.fiftyTwoWeekLowChangePercent*100).toFixed(2)}%</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol3m')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume3Month}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol10d')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume10Day}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('per_ratio')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{PER}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('benef_acc')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{benef_accion}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('dividendo')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Dividendo}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('rent_div')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Rent_dividendo}</Text>
        </HStack></HStack>

        <Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>
         <CarruselTickers data={todoNews} symbol={element.symbol}/>
       


    </TabPanel>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    </TabPanel>
  </TabPanels>
</Tabs>

   
          
         </GridItem>}


  <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={0} mt={0}  >
  {isFetchingNews&&<Box mt={10}>
  <AddContentApp mode={5}></AddContentApp>
  </Box>}
  {!isFetchingNews&&<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={0.5} mt={0} >

  <Tabs isFitted  variantColor="red" mr={0} ml={0}  defaultIndex={0} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("noticias_rel")}</Tab>
    
  </TabList>

  <TabPanels>
    <TabPanel>
      

<Box overflowY="auto"mr={-2} ml={-5}  maxHeight={340}>
<RSSNewsList listRSS={todoNews} tag={searchedNews} tagStock={true}></RSSNewsList>
</Box>
    </TabPanel>
    
  </TabPanels>
</Tabs>





   </GridItem>}
       </GridItem>
   </SimpleGrid> 
  
  
  
  
  </Box> 
   );
  }
    }
  }
      

     


      
    }
  


    function changeSwitchCartera()
    {
      //SHOW_PUBLIC_CARTERA=!SHOW_PUBLIC_CARTERA;
      this.setState({});
    }

    function updateUserPortfolioOptions() {
      try 
          {
           var headers = new Headers(); 
  
           
  
           headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
           headers.set('Content-Type', 'application/json');
       
  
           const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 //method: "POST",
                 method: "PUT",  
                 headers: headers,
                
                 body: 
                 JSON.stringify({ 
                  name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name, (es el id del user)
                  SHOW_PUBLIC_PROFILE_USER: glovar.SHOW_PUBLIC_PROFILE_USER,
                  SHOW_PUBLIC_PROFILE_USER_INFO: glovar.SHOW_PUBLIC_PROFILE_USER_INFO,
                  SHOW_PUBLIC_PROFILE_BTNFOLLOW: glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW,
                  SHOW_PUBLIC_CARTERA: glovar.SHOW_PUBLIC_CARTERA,
                  SHOW_PUBLIC_CARTERA_TIT_BENEF: glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF,
                  SHOW_PUBLIC_CARTERA_ONLY_FOLLOW: glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW: glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL: glovar.SHOW_PUBLIC_HISTORIAL,
                  SHOW_PUBLIC_HISTORIAL_TIT_BENEF: glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF,
                  //NUM_FOLLOWERS: user.NUM_FOLLOWERS,
                  //NUM_VISITAS: user.NUM_VISITAS,
                  //NUM_LIKES: user.NUM_LIKES
                })
           }) 
  
           //console.log("pujat correctament updateUserPortfolioOptions ");  //+glovar.CURRENT_USER.name)
          }
          catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
     }

  
    function PublicPortfolio(params) {
     
      let { id } = useParams();
      

      var show_cartera=false;
      var edit_stock=false;

      const [stock, setStock] = useState(edit_stock) 
      useEffect(() => { setStock(edit_stock); }, [edit_stock]);


      //console.log(params.OWN_USER)
      if(params.OWN_USER==1) {
        id=glovar.USER_USERNAME;
      }
      

      var NOSHOW_NOFOLLOWER=false;
     


      //SHOW_MY_PROFILE
      var PROFILE_CACHE=false;
      const obj = {}
      try { if(JSON.stringify(glovar.SHOW_MY_PROFILE).length>10) { glovar.PROFILE_CACHE=true; } } catch(Exx) {}
      //console.log("PROFILE_CACHE:"+PROFILE_CACHE)
      
      //var SHOW_TOPTRADER_PROFILE;//=glovar.TOPTRADERSLIST[0];

      //const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(params.OWN_USER,id)//"IAG.MC,EURUSD=X")
      const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(false,id,PROFILE_CACHE)//"IAG.MC,EURUSD=X")


      //const  todoTopTraders = useFetchTodoToptraders()
      //glovar.TOPTRADERSLIST=todoTopTraders
        
      
   
  
    if(!isFetchingTopTraders || PROFILE_CACHE) {
  
      
 
  //SHOW_TOPTRADER_PROFILE=todoTopTraders[0];
  if(!PROFILE_CACHE) { 
    try {
         //console.log("testeooo:"+todoTopTraders)
       if(todoTopTraders.length === 0) {
        //console.log("usuario NO existe")
        window.location.replace('/')
        return;
        }
      }
        catch(Exx) { /*console.log("petada:"+Exx)*/}

        glovar.SHOW_MY_PROFILE=todoTopTraders 
  }
  
  //console.log("resposta toptraders:"+JSON.stringify(SHOW_TOPTRADER_PROFILE));
  glovar.SHOW_MY_PROFILE.name=id;

  try {
    glovar.NUM_FOLLOWERS=glovar.SHOW_MY_PROFILE.list_followers.length;
  } catch(Exxx) {}
  
  try {
    glovar.NUM_FOLLOWING=glovar.SHOW_MY_PROFILE.list_following.length;
  } catch(Exxx) {}

  if(!params.OWN_USER) 
  {
    try {
      if(glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW==true) 
    {
      NOSHOW_NOFOLLOWER=true;
      try {
        if(glovar.SHOW_MY_PROFILE.list_followers.includes(glovar.USER_USERNAME)) { NOSHOW_NOFOLLOWER=false; }
      } catch(exx) {}

    }
   } catch(Exx) {NOSHOW_NOFOLLOWER=false; }
  }
  
  if(!params.OWN_USER || !PROFILE_CACHE) 
  {
    glovar.SHOW_PUBLIC_PROFILE_USER= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_USER;
    if(!glovar.SHOW_PUBLIC_PROFILE_USER && !params.OWN_USER) {
      window.location.replace('/')
      return;
    }
    glovar.SHOW_PUBLIC_PROFILE_USER_INFO = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_USER_INFO;
    glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_BTNFOLLOW;

    glovar.SHOW_PUBLIC_CARTERA = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA;
    glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF =glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_TIT_BENEF;

    glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW;
    glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW;

    glovar.SHOW_PUBLIC_HISTORIAL= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL;
    glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL_TIT_BENEF;
  }
  
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    try {
      if(params.OWN_USER) { EventGA("click_element","MYpubPortMB_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
      else { EventGA("click_element","pubPortMB_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
     } catch (exx) { /*console.log("petada event:"+exx)*/}
    


    return (
      <Box  backgroundColor="#F3F2EF" pb={"80px"}><Box>
     <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
        <Image src='assets/logopro1trading.png' h="54px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoPuPortMB", "pslogoPuPortMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') 
        }}
       />   
     </Flex>
    </Box>

      <SimpleGrid columns={14} gap={0} p={0}>
    
       
        
        {1==1&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1}> 
            
      
  
  {(!params.OWN_USER/*glovar.MODE_USER==0*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                                hide_follow={params.OWN_USER}
                                hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                                hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                                hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                                mobile={1}
                                />}
  
              </GridItem>}
        <GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={2} mt={1}> 
        
        
        {!params.OWN_USER&&<AddContentApp mode={5} mobile={1}></AddContentApp>}
        
    
        </GridItem>
      </SimpleGrid>
    
    
    
    
      <SimpleGrid columns={14} p={0} gap={2} mt={0} m={2} >
    
  
     
    
    <GridItem colSpan={14} rounded={10}  gap={1} m={0} mt={0} >
         
    
  
           <Box backgroundColor="#FFFFFF" rounded={10} p={0} pl={4} pr={2} minHeight={"30pc"}>
  
  
          {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
          <Text>{i18n.t("cartera_actual")}</Text>
          </Flex>}
          
        
  
  
          {glovar.MODE_USER>=0 &&<HStack mb={3} textColor="gray.400" fontSize="sm" justifyContent="space-between">
            <Text >{i18n.t("activo")}</Text>
            <Spacer></Spacer> 
           
            <Text >{i18n.t("entrada")}</Text>
            
            
            <Text >{i18n.t("precio")}</Text>
            
            <Text>Obj/Stop</Text>
          
           
          
            <Text >Benef/Rent</Text>
          </HStack>}
         
          {(!params.OWN_USER && !glovar.SHOW_PUBLIC_CARTERA) && 
            <VStack>
              
              <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
                El usuario ha configurado con <b>perfil privado</b> su CARTERA ACTUAL
                </Text>
            <AddContentApp mode={5}></AddContentApp>
            </VStack>
            } 

           {(!params.OWN_USER && glovar.SHOW_PUBLIC_CARTERA && NOSHOW_NOFOLLOWER) && 
            <VStack>
              
              <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
                El usuario ha configurado su CARTERA ACTUAL como visible SOLO PARA SUS SEGUIDORES.
                </Text>
            <AddContentApp mode={5}></AddContentApp>
            </VStack>
            }
          
          
          {(glovar.SHOW_PUBLIC_CARTERA && !params.OWN_USER/*glovar.MODE_USER==0*/ && !NOSHOW_NOFOLLOWER)&&
             <PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}
             mobile={1} >
              </PortfolioTopTraderList>}
          </Box>
        
    
       
            
    
              
    
    
    
      
      
    
    
    </GridItem>
    <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={0} mt={1}  >
    
          
    
          
    
             
             
          
       {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
          <Text>{i18n.t("track_record")}</Text>
          </Flex>}
  
  
         {(!params.OWN_USER && !glovar.SHOW_PUBLIC_HISTORIAL) && 
            <VStack>
              
            <Text background={"black"} color="white" p={2} rounded={10} pl={4} pr={4} marginTop={20} marginBottom={2} fontSize="md" fontWeight="normal">
              El usuario ha configurado con <b>perfil privado</b> su TRACK RECORD
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
            } 
  
  
          {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
          <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
                                          grayScale={!glovar.SHOW_PUBLIC_HISTORIAL} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}>
  
           </TrackRecordTopTraderList>
          
          }
           {(glovar.SHOW_PUBLIC_HISTORIAL && !params.OWN_USER/*glovar.MODE_USER==0*/)&&
           <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
           mobile={1}
           hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}></TrackRecordTopTraderList>}
  
          
    
        
    
        
         
    
              
    
    
    
       </GridItem>
     </SimpleGrid> 
    
    
    
    
    </Box> 
     );
//return( <AddContentApp mode={4}></AddContentApp>);
  } 
  else {

    try {
      if(params.OWN_USER) { EventGA("click_element","MYpubPortDT_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
      else { EventGA("click_element","pubPortDT_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
     } catch (exx) { /*console.log("petada event:"+exx)*/}
    

   return (
    
    <Box  backgroundColor="#F3F2EF" pb={"80px"}>
    <Box>
   <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
        <Image src='assets/logopro1trading.png' h="54px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoPuPortDT", "pslogoPuPortDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') 
        }}
        />
         <Spacer />
           <ShowSearchBar/>
       

            <Spacer />
         <HStack fontSize="2xl" spacing="8" marginRight="8pc">
             {1==2&&<FiBell />}
             {1==2&&<FiSettings />}
             {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); } catch (exx) {} this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}
            {!glovar.USER_LOADED&&<Box>
              <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
            onClick= {() => {
              try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') }
            }
            style={{  marginRight:"1pc", width: "10pc", textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            //try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            //window.location.replace('/registerApp') }
            if(glovar.REGISTER_ONLINE==true) {
              try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/register')
              }
              else {
                try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
                window.location.replace('/registerApp')
              }}
           }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
             
         </HStack>
   </Flex>
  </Box>
    <SimpleGrid columns={14} gap={1} p={2}>
      <GridItem colSpan={1}>
      <Box mt={4}>
    <VStack>
      <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={201} />
      <ItemSibedar icon="assets/ic_podium_white_24dp.png" id={202} />
      {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<ItemSibedar icon="assets/ic_share_variant_white_24dp.png" id={3} />}
      {1==2&&<ItemSibedar icon="assets/ic_compass_white_24dp.png" id={3} />}
      {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
    </VStack>
  </Box>
      </GridItem>
     
      
      {1==1&&<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2}> 
          
      {(params.OWN_USER==1)/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={6} marginBottom={1} marginLeft={4} marginTop={2} > 
      <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_PROFILE_USER}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW=!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

          {i18n.t("permi_seguidores")}
          </Checkbox>

        <HStack  mb={4}>
          
      
  
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_PROFILE_USER}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
          onChange={() => { glovar.SHOW_PUBLIC_PROFILE_USER_INFO=!glovar.SHOW_PUBLIC_PROFILE_USER_INFO; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

           {i18n.t("show_capbenefis")}
          </Checkbox>
          <FormLabel htmlFor='email-alerts1' mb='0' fontSize="sm" fontWeight="semibold">{i18n.t("perfil_publico")}</FormLabel>
          <Switch id='email-alerts1'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_PROFILE_USER}
          isChecked={glovar.SHOW_PUBLIC_PROFILE_USER}
          onChange={() => { 
            glovar.SHOW_PUBLIC_PROFILE_USER=!glovar.SHOW_PUBLIC_PROFILE_USER;
            if(!glovar.SHOW_PUBLIC_PROFILE_USER) {
              glovar.SHOW_PUBLIC_CARTERA=false; 
              glovar.SHOW_PUBLIC_HISTORIAL=false; 
            }
            
            updateUserPortfolioOptions();

            //console.log(glovar.SHOW_PUBLIC_CARTERA)
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </HStack>
        </HStack>
        }

{(!params.OWN_USER/*glovar.MODE_USER==0*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                              hide_follow={params.OWN_USER}
                              hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                              hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                              hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                              />}
                              


{(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                              hide_follow={params.OWN_USER}
                              hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                              hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                              hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                              />}


          


      </GridItem>}
      <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={2}> 
      


      {params.OWN_USER==false&&<AddContentApp mode={5}></AddContentApp>}
      {params.OWN_USER==true&&
      <SimpleGrid columns={4} gap={0} p={0} mt={0} >
        
      <GridItem colSpan={2}>
      <Text fontWeight="normal" fontSize={14}  mt={3} mb={-2} ml={4} textColor="black">{i18n.t("compa_perfil_contac")}</Text>
      <AddContentApp mode={6}></AddContentApp>
      {1==3&&<AddContentApp mode={5}></AddContentApp>}
      </GridItem>
      <GridItem colSpan={2}>     
      
      <Tabs isFitted  colorScheme='black' mr={2} defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14}>{i18n.t("followers")} ({glovar.NUM_FOLLOWERS})</Tab>
    <Tab fontSize={14}>{i18n.t("following")} ({glovar.NUM_FOLLOWING})</Tab>
  </TabList>
  <TabPanels>
    <TabPanel 
    paddingLeft={0.5}
    paddingRight={0.5}
    paddingTop={2}
    >
    <ContactsList toptrader={glovar.SHOW_MY_PROFILE} modelist={glovar.MODE_FOLLOWERS}/>
    </TabPanel>
    <TabPanel
     paddingLeft={0.5}
     paddingRight={0.5}
     paddingTop={2}
     >
    <ContactsList toptrader={glovar.SHOW_MY_PROFILE} modelist={glovar.MODE_FOLLOWING} />
    </TabPanel>
  </TabPanels>
</Tabs>
        
        
      
      </GridItem>
      
      </SimpleGrid>}


      


      </GridItem>
    </SimpleGrid>
  
  
  
  
    <SimpleGrid columns={14} gap={1} p={2} mt={-1} >
  
  <GridItem colSpan={1}>
   
  
    <Box mt={2}>
  <VStack>
    {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
    {1==1&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
    
  </VStack>
  </Box>
    </GridItem>
  
   
  
  <GridItem colSpan={6} rounded={10}  m={2} mt={0} >
       
  

         <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"30pc"}>


        {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
        <Text>{i18n.t("cartera_actual")}</Text>
        </Flex>}
        
        {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={2} marginBottom={2} > 
        <Text fontWeight="semibold"   mt={2} mb={2} ml={0} pt={0} textColor="gray.800">{i18n.t("cartera_actual")}</Text>

        <Flex  mb={2}>
          
        <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}
          onChange={() => { glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF=!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF; 
            //console.log("glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF:"+glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

           Títulos y beneficio  
          </Checkbox>
          
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW=!glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW; 
            
            //console.log("glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW:"+glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Visible solo seguidores  
          </Checkbox>
          
          <FormLabel htmlFor='email-alerts' mb='0'  fontSize="sm" fontWeight="semibold"> visible en perfíl público</FormLabel>
          <Switch id='email-alerts'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_CARTERA}
          isChecked={glovar.SHOW_PUBLIC_CARTERA}
          onChange={() => { 
              glovar.SHOW_PUBLIC_CARTERA=!glovar.SHOW_PUBLIC_CARTERA; 
              if(glovar.SHOW_PUBLIC_CARTERA) { glovar.SHOW_PUBLIC_PROFILE_USER=true }
              updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </Flex>
        </HStack>
        }


        {glovar.MODE_USER>=0 &&<Flex mb={3} textColor="gray.400" fontSize="sm">
          <Text w={"11pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
          <Spacer />
          <Text ml={0}>{i18n.t("titulos")}</Text>
          <Spacer />
          <Text mr={3}>{i18n.t("entrada")}</Text>
          <Spacer />
          
          <Text ml={1}>{i18n.t("precio")}</Text>
          <Spacer />
          <Spacer />
          
          <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
         <Spacer />
         <Spacer />
         
        
          <Text mr={4}>{i18n.t("benef_rent")}</Text>
        </Flex>}
       
        {(!params.OWN_USER && !glovar.SHOW_PUBLIC_CARTERA) && 
          <VStack>
            
            <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
              El usuario ha configurado con <b>perfil privado</b> su CARTERA ACTUAL
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
          } 
  
          {(!params.OWN_USER && glovar.SHOW_PUBLIC_CARTERA && NOSHOW_NOFOLLOWER) && 
          <VStack>
            
            <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" textAlign="center" fontWeight="normal">
            El usuario ha configurado su CARTERA ACTUAL como<br></br> <b>visible solo para sus SEGUIDORES.</b>
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
          } 

       
        {1==3&&(glovar.MODE_USER<=2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE}></PortfolioTopTraderList>}
        {1==3&&(glovar.MODE_USER<=2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect toptrader={glovar.SHOW_MY_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}
        
        {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
        <PortfolioTopTraderListNoeffect toptrader={glovar.SHOW_MY_PROFILE} cotis={glovar.MYPORTFOLIO_COTIS} 
                                        grayScale={!glovar.SHOW_PUBLIC_CARTERA} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}>

         </PortfolioTopTraderListNoeffect>
        
        }
        {(glovar.SHOW_PUBLIC_CARTERA && !params.OWN_USER/*glovar.MODE_USER==0*/&& !NOSHOW_NOFOLLOWER)&&
           <PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF} >
            </PortfolioTopTraderList>}
        </Box>
      
  
     
          
  
            
  
  
  
    
    
  
  
  </GridItem>
  <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >
  
        
  
        
  
           
           
        
     {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
        <Text>{i18n.t("track_record")}</Text>
        </Flex>}

        {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={6} marginBottom={2}  mt={2}  ml={4}> 
        <Text fontWeight="semibold"    mt={2} mb={2} ml={0} pt={0} textColor="gray.800">{i18n.t("track_record")}</Text>

        <Flex  mb={2}>
          
          
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_HISTORIAL}
          marginRight={8}
          size='sm' 
          colorScheme='gray'
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}
          onChange={() => { glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF=!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Títulos y beneficio  
          </Checkbox>

          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW=!glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW; 
           //console.log("lovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW:"+glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Visible solo seguidores  
          </Checkbox>


          <FormLabel htmlFor='email-alerts' mb='0' fontSize="sm" fontWeight="semibold"> visible en perfíl público</FormLabel>
          <Switch id='email-alerts'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_HISTORIAL}
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL}
          onChange={() => { 
            glovar.SHOW_PUBLIC_HISTORIAL=!glovar.SHOW_PUBLIC_HISTORIAL; 
            if(glovar.SHOW_PUBLIC_HISTORIAL) { glovar.SHOW_PUBLIC_PROFILE_USER=true }
            updateUserPortfolioOptions();
            //console.log(glovar.SHOW_PUBLIC_HISTORIAL)
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </Flex>
        </HStack>
        }

       {(!params.OWN_USER && !glovar.SHOW_PUBLIC_HISTORIAL) && 
          <VStack>
            
          <Text background={"black"} color="white" p={2} rounded={10} pl={4} pr={4} marginTop={20} marginBottom={2} fontSize="md" fontWeight="normal">
            El usuario ha configurado con <b>perfil privado</b> su TRACK RECORD
            </Text>
        <AddContentApp mode={5}></AddContentApp>
        </VStack>
          } 


        {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
        <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
                                        grayScale={!glovar.SHOW_PUBLIC_HISTORIAL} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}>

         </TrackRecordTopTraderList>
        
        }
         {(glovar.SHOW_PUBLIC_HISTORIAL && !params.OWN_USER/*glovar.MODE_USER==0*/)&&
         <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}></TrackRecordTopTraderList>}

        
  
      
  
      
       
  
            
  
  
  
     </GridItem>
   </SimpleGrid> 
  
  
  
  
  </Box> 
   );
  }
    }

      

     


      
    }

    /*if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
    
     
//return( <AddContentApp mode={4}></AddContentApp>);
    }*/

      //try { ReactGA.pageview(window.location.pathname + window.location.search); } catch(Exx) {}
      //try { ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search }); } catch(Exx) {}
    
    //i18n.locale = "en";
    //console.log("i18:"+); //=> Hi!
    
    //i18n.locale = "pt-BR";
    //console.log("i18:"+i18n.t("vers_antigua")); //=> Olá!


  const ItemSibedar = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={4}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "lightgray"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
           EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
          catch (exx) { /*console.log("petada event:"+exx)*/}

        this.changemenu(id)
      }}
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );

  const ItemSibedarRRSS = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={3}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "#888888"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
           EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
        catch (exx) { /*console.log("petada event:"+exx)*/}
         this.changemenu(id)}
      }
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );
  
 
  

  if( glovar.CURRENT_MENU_ID==4) {
    return(<ShowFitxaElement element_data={SHOW_ELEMENT_DATA}/>)
    return(null)
  }

  if( glovar.CURRENT_MENU_ID==3) {
    return(<PublicPortfolio OWN_USER={1}/>)
  }
  if(glovar.CURRENT_MENU_ID==2)
  {
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
 //return( <AddContentApp mode={4}></AddContentApp>);
    return (
 <Box  backgroundColor="#F3F2EF" pb={"120px"}><Box>
    <Flex p={4} backgroundColor="#FFFFFF" maxHeight="130px" flexDirection="column" >
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
       <ShowSearchBar type={1}/>
    </Flex>
   </Box>
 
   <SimpleGrid columns={14} gap={0} p={0}>
 
   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1}> 
         
         <BigCardTopTrader  mobile={1} toptrader={SHOW_TOPTRADER_PROFILE} toptraderpos={0} current_portfolio_ED={0}/>
 
     </GridItem> 
  
 
 
     <GridItem colSpan={14} rounded={10}  m={1} mt={1} >
      
 
      <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"25pc"}>
         <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
     <Text>{i18n.t("cartera_actual")}</Text>
       
     </Flex>
     {glovar.MODE_USER==2 &&<HStack mb={3} textColor="gray.400" fontSize="sm" justifyContent="space-between">
             <Text >{i18n.t("activo")}</Text>
             <Spacer></Spacer> 
            
             <Text >{i18n.t("entrada")}</Text>
             
             
             <Text >{i18n.t("precio")}</Text>
             
             <Text>Obj/Stop</Text>
           
            
           
             <Text >Benef/Rent</Text>
           </HStack>}
    
 
    {glovar.MODE_USER<=1&& 
       <AddContentApp mode={3} mobile={1}></AddContentApp>
       } 
     {(glovar.MODE_USER==2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList mobile={1} toptrader={SHOW_TOPTRADER_PROFILE}></PortfolioTopTraderList>}
     {(glovar.MODE_USER==2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect mobile={1} toptrader={SHOW_TOPTRADER_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}
 
     </Box>
   
 </GridItem>

 <GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={1}> 
     
     <TopTradersRanking mobile={1}/>
 
     </GridItem>

 <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1} mt={1}  >
   
      <Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
      <Text>{i18n.t("track_record")}</Text>
        
      </Flex>
      <TrackRecordTopTraderList mobile={1} toptrader={SHOW_TOPTRADER_PROFILE}></TrackRecordTopTraderList>

    

    
     

          



   </GridItem>

 
     </SimpleGrid>
 
 
   </Box>
 
    );
      }
      else {
    return (
      <Router>

      <Routes>
        <Route exact path='/'  element={
            <PrivateRoute>

      <Box  backgroundColor="#F3F2EF" pb={"80px"}>
  <Box>
 <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
      <Image src='assets/logopro1trading.png' h="54px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoToptrDT", "pslogoToptrDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          glovar.CURRENT_MENU_ID=1;
          //changemenu(1)
          window.location.replace('/') 
          //changemenu
        }} />
       <Spacer />


<ShowSearchBar/>


<Spacer />

       <HStack fontSize="2xl" spacing="8" marginRight="8pc">
           {1==2&&<FiBell />}
           {1==2&&<FiSettings />}
           {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); } catch (exx) {} this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}
          {!glovar.USER_LOADED&&<Box>
            <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
          onClick= {() =>{
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
             window.location.replace('/login') }
          }
          style={{  marginRight:"1pc", width: "10pc", textAlign:"center", fontWeight:600 }}>
          {i18n.t("login")}</button>         
          <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
         onClick= {() => {
          //try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          //window.location.replace('/registerApp') 
          if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
         }
         }
          style={{ width: "10pc",  textAlign:"center"}}>
          {i18n.t("signup")}</button>          
          </Box>}

           
       </HStack>
 </Flex>
</Box>
  <SimpleGrid columns={14} gap={1} p={2}>
    <GridItem colSpan={1}>
    <Box mt={4}>
  <VStack>
    <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />
    <ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />
    {glovar.MODE_USER>=1&&<ItemSibedar icon="assets/ic_share_variant_white_24dp.png" id={3} />}
    {1==2&&<ItemSibedar icon="assets/ic_compass_white_24dp.png" id={3} />}
    {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
  </VStack>
</Box>
    </GridItem>
   
    
    <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2}> 
        {1==2&&<Homefrontuser></Homefrontuser>}
        <BigCardTopTrader   toptrader={SHOW_TOPTRADER_PROFILE} toptraderpos={0} current_portfolio_ED={0}/>

    </GridItem> 
    <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={2}> 
    
    <TopTradersRanking/>

    </GridItem>
  </SimpleGrid>




  <SimpleGrid columns={14} gap={1} p={2} mt={-1} >

<GridItem colSpan={1}>
 

  <Box mt={2}>
<VStack>
  {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
  {1==1&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
  
</VStack>
</Box>
  </GridItem>

 

<GridItem colSpan={6} rounded={10}  m={2} mt={0} >
     

       <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"30pc"}>
          <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("cartera_actual")}</Text>
        
      </Flex>
      {glovar.MODE_USER==2 &&<Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text w={"11pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
        <Spacer />
        <Text ml={0}>{i18n.t("titulos")}</Text>
        <Spacer />
        <Text mr={3}>{i18n.t("entrada")}</Text>
        <Spacer />
        
        <Text ml={1}>{i18n.t("precio")}</Text>
        <Spacer />
        <Spacer />
        
        <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
       <Spacer />
       <Spacer />
       
      
        <Text mr={4}>{i18n.t("benef_rent")}</Text>
      </Flex>}
     

     {glovar.MODE_USER<=1&& 
        <AddContentApp mode={3}></AddContentApp>
        } 
      {(glovar.MODE_USER==2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList toptrader={SHOW_TOPTRADER_PROFILE}></PortfolioTopTraderList>}
      {(glovar.MODE_USER==2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect toptrader={SHOW_TOPTRADER_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}

      </Box>
    

   
        

          



  
  


</GridItem>
<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >

      

      

         
         
      
      <Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
      <Text>{i18n.t("track_record")}</Text>
        
      </Flex>
      <TrackRecordTopTraderList toptrader={SHOW_TOPTRADER_PROFILE}></TrackRecordTopTraderList>

    

    
     

          



   </GridItem>
 </SimpleGrid> 




</Box> 
</PrivateRoute>
          }/> 
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement/> }/>
        <Route path="/login" element={<Login/>} />
        <Route path="/registerApp" element={<RegisterApp/>} />
        <Route path="/register" element={<RegisterApp/>} />
        <Route path='/forgotPassword' element={<ForgotPassword/>} /> 
      </Routes>  
    
</Router>
);
        }
  }
  else if(glovar.CURRENT_MENU_ID==1)
  {
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
      return(
      <Router>
      <Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        
  <Route exact path='/'  element={
      <PrivateRoute>
<Box  backgroundColor="#F3F2EF" pb={"100px"}><Box>
    <Flex p={2} backgroundColor="#FFFFFF" maxHeight="80px" flexDirection="row" justifyContent="space-between">
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
        <Menu>
  <MenuButton as={Button}>
    -
  </MenuButton>
  {!glovar.USER_LOADED&&<MenuList>
    <MenuItem  onClick= {() => {
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/login') }
          }>{i18n.t("login")}</MenuItem>
    <MenuItem onClick= {() =>
          {
           // try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/registerApp') 
           if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
          }}>{i18n.t("signup")}</MenuItem>
  </MenuList>}
  {glovar.USER_LOADED&&<MenuList>
    <MenuItem minH='48px' 
    onClick= {() =>{ 
      try { EventGA("button_sup","ClickMBSupAvatar", "ClickSupMBAvatar"); } catch (exx) {} /*this.changemenu(3)*/ }
    }>
    <Avatar w={12} h={10} mr={4}></Avatar>
      <span>{glovar.USER_USERNAME}</span>
    </MenuItem>

    <MenuItem onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>{i18n.t("m_cerrarses")}</MenuItem>
  </MenuList>}
</Menu>
       

           

    </Flex>
    <Box  backgroundColor="#FFFFFF" mt={-2} pb={2} pl={2} alignContent="center">
    <ShowSearchBar type={1}/>
    </Box>
   </Box>
 
   <SimpleGrid columns={14} gap={0} p={0}>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        {(glovar.MODE_USER>=0 || glovar.REFRESH_FRONTUSER)&&<Homefrontuser mobile={1}></Homefrontuser>}
       
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
        </GridItem>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Flex fontSize={14} fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
        {glovar.MODE_USER==0&&<Text mb={-1}>{i18n.t("lo_mas_pop")}</Text>}
        {glovar.MODE_USER>=1&&<Text mb={-1}>{i18n.t("mis_favoritos")}</Text>}
      </Flex>
         
        {glovar.MODE_USER>=1&&<FavoritosList></FavoritosList>}
        {glovar.MODE_USER==0&& 
        <MorePopularList></MorePopularList>
        }
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Text fontSize={16} ml={2}  fontWeight="semibold" textColor="gray.800">{i18n.t("noticias")}</Text>
        <Box overflowY="auto" maxHeight={600}>
<RSSNewsList name={""} price={"19.567,12"} diff={"+6.69%"}></RSSNewsList>
</Box>
          </GridItem>
      

   </SimpleGrid>
   </Box>
      </PrivateRoute>
      
  }/> 

<Route path="/login" element={<Login/>} />
<Route path="/registerApp" element={<RegisterApp/>} />
<Route path="/register" element={<RegisterApp/>} />
<Route path='/forgotPassword' element={<ForgotPassword/>} /> 
</Routes>  
</Router>
 );
    }
    else {
      
    //console.log("reeender glovar.MODE_USER:"+glovar.MODE_USER)
  return (
<Router>

<Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        
  <Route exact path='/'  element={
      <PrivateRoute>

          <Box backgroundColor="#F3F2EF" pb={"80px"}>
      <Box >
     <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
          <Image src='assets/logopro1trading.png' h="54px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoHomeDT", "pslogoHomeDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          glovar.CURRENT_MENU_ID=1;
          //changemenu(1)
          window.location.replace('/') 
          //changemenu
        }} />
           <Spacer />


            <ShowSearchBar/>
       

            <Spacer />


           <HStack fontSize="2xl" spacing="8" marginRight="8pc">
           {1==2&&<FiBell />}
           {1==2&&<FiSettings />}
               
           {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); } catch (exx) {} this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}

          
          {!glovar.USER_LOADED&&<Box>
          <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
          onClick= {() => {
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/login') }
          }
          style={{  marginRight:"1pc", width: "10pc", textAlign:"center", fontWeight:600 }}>
          {i18n.t("login")}</button>         
          <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
         onClick= {() =>
          {
           // try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/registerApp') 
           if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
          }
          }
          style={{ width: "10pc",  textAlign:"center"}}>
          {i18n.t("signup")}</button>         
          </Box>}
               
           </HStack>
     </Flex>
    </Box>
      <SimpleGrid   columns={14} gap={1} p={2}>
        <GridItem colSpan={1}>
        <Box mt={4}>
      <VStack>
        <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />
        <ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />
        {glovar.MODE_USER>=1&&<ItemSibedar icon="assets/ic_share_variant_white_24dp.png" id={3} />}
        {1==2&&<ItemSibedar icon="assets/ic_compass_white_24dp.png" id={3} />}
        {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
      </VStack>
    </Box>
        </GridItem>
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" p={2} m={2}>
        <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("lo_mas_pop")}</Text>
        
      </Flex>
      
        <MorePopularList></MorePopularList>
        </GridItem>
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" m={2}> 
        
        {1==2 &&<Image src='assets/moockup11.png' p={3} marginTop={1} />}
        
        {(glovar.MODE_USER==0 || glovar.REFRESH_FRONTUSER)&&<Homefrontuser></Homefrontuser>}
       
        </GridItem>
       
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" p={2} m={2}>
        <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("mis_favoritos")}</Text>
        
      </Flex>
        {glovar.MODE_USER>=1&&<FavoritosList></FavoritosList>}
        {glovar.MODE_USER==0&& 
        <AddContentApp mode={1}></AddContentApp>
        }
        </GridItem>
       
      </SimpleGrid>


      {1==2&&<SimpleGrid columns={14} gap={0} p={6} paddingTop={4} paddingBottom={4} mt={0} >

<GridItem colSpan={1}>
  <Box mt={2}>
<VStack>
  {1==2&&<ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />}
  {1==2&&<ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />}
  {1==2&&<ItemSibedar icon="assets/ic_compass_white_24dp.png" id={3} />}
  {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
</VStack>
</Box>
  </GridItem>

<GridItem colSpan={12} rounded={10} backgroundColor="#FFFFFF" m={2} mt={-6} height="120px" >        </GridItem>
 </SimpleGrid>}

 

      <SimpleGrid columns={14} gap={1} p={2} mt={-1} >

<GridItem colSpan={1}>
  <Box mt={2}>
<VStack>
  {1==2&&<AdComponent600/>}
  {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
  {1==1&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
  {1==2&&<ItemSibedar icon="assets/ic_compass_white_24dp.png" id={3} />}
  {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
</VStack>
</Box>
  </GridItem>

<GridItem colSpan={6} rounded={10}  m={2} mt={0}  height="300px" >
     

      <Box
      //rounded={10}
        //backgroundColor="yellow"  
        //borderRadius="lg"
        fontSize="13"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="0px"
        w="full"

        height="140px"
        mb={3}
        
      >
        {1==2&&<Image src='assets/moockup4.png' p={0} marginTop={0} />}
        {1==2&&<AdComponent728/>}
        <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </Box>

     
          {1==2&&<Image src='assets/moockup2.png' p={3} marginTop={1} />}

          
          <Box ml={0} mr={0} mt={2} p={2} pl={4} rounded={10} backgroundColor="#FFFFFF" >
          <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("mi_cartera")}</Text>
        
      </Flex>
      {glovar.MODE_USER>=1&&<Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text w={"10pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
        <Spacer />
        <Text ml={0}>{i18n.t("titulos")}</Text>
        <Spacer />
        <Text mr={3}>{i18n.t("entrada")}</Text>
        <Spacer />
        
        <Text ml={1}>{i18n.t("precio")}</Text>
        <Spacer />
        <Spacer />
        
        <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
       <Spacer />
       <Spacer />
       
      
        <Text mr={4}>{i18n.t("benef_rent")}</Text>
      </Flex>}
      <Stack minHeight="25pc">
      {glovar.MODE_USER==0&& 
        <AddContentApp mode={2}></AddContentApp>
        }
      {glovar.MODE_USER>=1&&<PortfolioList name={""} price={"19.567,12"} diff={"+6.69%"}></PortfolioList>}

    
</Stack>
    </Box>
          
  
</GridItem>
<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0} >
<Box overflowY="auto" maxHeight={600}>
<RSSNewsList name={""} price={"19.567,12"} diff={"+6.69%"}></RSSNewsList>
</Box>

   </GridItem>
 </SimpleGrid> 
    </Box> 
    </PrivateRoute>
  }/> 

<Route path="/login" element={<Login/>} />
<Route path="/registerApp" element={<RegisterApp/>} />
<Route path="/register" element={<RegisterApp/>} />
<Route path='/forgotPassword' element={<ForgotPassword/>} /> 
</Routes>  

</Router>
    );
   }
  }
  }
}

//export default App;

//<RSSnew title={todoRSS.title} thumbnail={todoRSS.thumbnail} link={todoRSS.link} ></RSSnew>