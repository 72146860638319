import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
//import {useAuthValue} from './AuthContext'

//import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
//import { Stack } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
//import { VStack } from "@chakra-ui/layout";
//import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

//import glovar from './components/glovar';
import glovar from './components/content/glovar';
import i18n from './i18n'

//import { Spacer } from "@chakra-ui/layout";
//import { Flex } from "@chakra-ui/layout";

import "./App.css";
//import { getToken, removeUserSession, setUserSession } from './utils/Common';


function Register(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [username, setUsername] = useState('') 
  const [error, setError] = useState('')
  //const {setTimeActive} = useAuthValue()
  const navigate = useNavigate()

 

  function btndownloadApp(app) {
    if(app==1) window.open(glovar.URL_APPSTORE,"_self")
    if(app==2) window.open(glovar.URL_PLAYSTORE,"_self")
   }

  return (
    <Box>
      <SimpleGrid columns={6} gap={3} >
      <GridItem colSpan={4} background={"#000000"/*"#F8F8F8"*/} height="100vh">
      <Box   _hover={{ cursor: "pointer" }}>
      <div onClick= {() => window.location.replace('/') }>
      <img className="verticalhorizontal" src='assets/plogo.png' w={100}  />
      </div>
      </Box>
    </GridItem>
    <GridItem colSpan={2}  height="100vh" >
    <div className="formCenterCreateAccount" background={"yellow"}>
    <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"3pc" }}>{i18n.t("create_account")}</Text>
   
        <Text  fontSize="1.1em" marginBottom={10} marginRight={5} fontWeight="normal" textColor="gray.800">
          <b>1.- {i18n.t("decarga_app_store_p1_bold")}</b>{i18n.t("decarga_app_store_p2_normal")}</Text>
          <Text  fontSize="1.1em" marginBottom={10}  marginRight={5}  textColor="gray.800">
          <b>2.- {i18n.t("reg_app_bold")}</b>{i18n.t("indica_nom_e_p")}</Text>
          <Text  fontSize="1.1em" marginBottom={10}  marginRight={5}  textColor="gray.800">
          <b>3.- </b>{i18n.t("una_vez_reg")} <b>{i18n.t("ini_ses_web")}</b>.</Text>

          <Text fontSize="1.1em" fontWeight="bold" marginTop="5pc" marginBottom="1pc" marginLeft="3.5pc" textColor="gray.800">
          {i18n.t("download_pro1_free")}</Text>
          <HStack>
          <Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="35%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
          <Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="35%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
          </HStack>
       
      
    </div>
    </GridItem>
    </SimpleGrid>
    </Box>
  );
}

export default Register