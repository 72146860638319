import {useState, useEffect} from 'react'


export function getMarketCAP(marketcap,locale) 
{
 var money=marketcap;
 var MAGNITUD='M';
 if(money>1000000000) { MAGNITUD='B'; }
 if(money>1000000000000) { MAGNITUD='T'; }

 

 if(MAGNITUD=='M') //150 000 000
 {
  money=money/1000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='B') //150 000 000 000
 {
  money=money/1000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='T') //150 000 000 000 000
 {
  money=money/1000000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
}


export const getURLToptraderPicture = (id) => {

  let url="assets/logopro1trading.png"

  if(id>0 && id<=31) {
    url="assets/prof"+id;
  }
  return url;

 
 }
 

 export var getModelUser = (USER_USERNAME,current_porfolio_ED,portfolio,historial,favoritos) => {

  
  var carteraPARSED=JSON.stringify(portfolio);
  carteraPARSED = carteraPARSED.replace('[', '');
  carteraPARSED = carteraPARSED.replace(']', '');

  var historialPARSED=JSON.stringify(historial);
  historialPARSED = historialPARSED.replace('[', '');
  historialPARSED = historialPARSED.replace(']', '');

  var favoritosPARSED=JSON.stringify(favoritos);
  favoritosPARSED = favoritosPARSED.replace('[', '');
  favoritosPARSED = favoritosPARSED.replace(']', '');

  
  var USER = {
    rentab: current_porfolio_ED.rentab,
    name: USER_USERNAME,//glovar.CURRENT_USER.name,
    idCartera: USER_USERNAME, //glovar.CURRENT_USER.idCartera,
    id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
    descript: current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
    risk_level: current_porfolio_ED.risk_level,
    countriesID:current_porfolio_ED.countriesID,
    activos:  current_porfolio_ED.activos,
    op_cerradas:  current_porfolio_ED.op_cerradas,
    rentmedia_op: current_porfolio_ED.rentmedia_op,
    sentido:  current_porfolio_ED.sentido,
    temporal:  current_porfolio_ED.temporal,
    historial: historialPARSED, //historialPARSED,//JSON.stringify( CURRENT_HISTORIAL),
    cartera: carteraPARSED,//portfolio,//carteraPARSED,//JSON.stringify( CURRENT_PORTFOLIO),// TOPTRADERS_PRO1[j].cartera_text,
    favoritos: favoritosPARSED,//favoritos, //favoritosPARSED,
                //cartera_text: TOPTRADERS_PRO1[j].cartera_text,
                //cartera: TOPTRADERS_PRO1[j].cartera,
    capital: current_porfolio_ED.capital,
    invertido: current_porfolio_ED.invertido,
    moneda: current_porfolio_ED.moneda,
    profit: current_porfolio_ED.profit,
    currency: current_porfolio_ED.currency,
    priority: current_porfolio_ED.priority,
    rentab_acum: current_porfolio_ED.rentab_acum,
    lastDataUpdateCartera_ed: current_porfolio_ED.lastDataUpdateCartera_ed, 
    lastDataUpdateFavoritos: current_porfolio_ED.lastDataUpdateFavoritos, 
    lastDataUpdateHistorial: current_porfolio_ED.lastDataUpdateHistorial,
    lastDataUpdatePortfolio: current_porfolio_ED.lastDataUpdatePortfolio,
    type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
    op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
    followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
    cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
    ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
    FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
  };





  //console.log(url)
  return USER;

 
 }
 