import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import AddContentApp from './components/content/AddContentApp';
import glovar from './components/content/glovar';
//import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
//if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
if((
   //window.location.href=="http://"+glovar.HOME_URL ||
   //window.location.href=="https://"+glovar.HOME_URL ||
   //window.location.href=="http://"+glovar.HOME_URL+"/" ||
   //window.location.href=="https://"+glovar.HOME_URL+"/" ||
   window.location.href==("http://"+glovar.HOME_URL+"/login") ||
   window.location.href==("https://"+glovar.HOME_URL+"/login") ||
   window.location.href==("http://"+glovar.HOME_URL+"/registerApp") ||
   window.location.href==("https://"+glovar.HOME_URL+"/registerApp") ||
   window.location.href==("http://"+glovar.HOME_URL+"/forgotPassword")  ||
   window.location.href==("https://"+glovar.HOME_URL+"/forgotPassword") )
  && 
  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
{

 
  

  root.render(
    <React.StrictMode>
      <ChakraProvider>
      <AddContentApp mode={4}></AddContentApp>
      </ChakraProvider>
    </React.StrictMode>
  );
}
else {
 //</ChakraProvider>title>your keyword rich title of the website and/or webpage</title>
 /*<meta name="description" content="Análisis técnico, cotizaciones, carteras, portfolio, seguimiento de mercados" />
 <meta property="og:title" content="Pro1.trading: El poder de los mercados financieros en tus manos" />
 <meta property="og:url" content="https://app.pro1.trading" />
 <meta property="og:description" content="Análisis técnico, cotizaciones, carteras, portfolio, seguimiento de mercados" />
 <meta property="og:image" content="//pro1.trading/wp-content/uploads/2020/10/logo2.png" />*/
 // console.log("getCurrentUrlxx  :"+window.location.href)

 /*
      <Helmet>
          <meta property="og:url" content="https://app.pro1.trading"/>
      <meta property="og:site_name" content="Pro1.trading"/>
      <meta property="og:title" content="The best partner for your investments/"/>
      <meta property="og:description" content="The most advanced technology at the service of your investments:Tech analysis, quotes, portfolios, portfolios, market monitoring, financial information, news.."/>
      <meta property="og:image" itemprop="image" content="https://pro1.trading/wp-content/uploads/2020/10/logo2.png"/>
      <meta property="og:image:type" content="image/png"/>
      <meta property="og:image:width" content="372"/>
      <meta property="og:image:height" content="128"/>
     
      <meta property="twitter:site" content="Pro1.trading"/>
      <meta property="twitter:creator" content="Pro1.trading"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content="Pro1.trading"/>
      <meta property="twitter:description" content="The most advanced technology at the service of your investments: Tech analysis, quotes, portfolios, portfolios, market monitoring, financial information, news.."/>
      <meta property="twitter:image:src" content="https://pro1.trading/wp-content/uploads/2020/10/logo2.png"/>
      <meta property="twitter:image:width" content="372"/>
   
      </Helmet>
 */
/*root.render(
  <React.StrictMode>
    <ChakraProvider>
    <App />
    </ChakraProvider>
  </React.StrictMode>
);*/
root.render(
  
    <ChakraProvider>
    <App />
    </ChakraProvider>
  
);
}
}
catch(exx) {
  root.render(
    <React.StrictMode>
      <ChakraProvider>
      <App />
      </ChakraProvider>
    </React.StrictMode>
  );
}

/*root.render(
 
    <ChakraProvider>
    <App />
    </ChakraProvider>
 
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
