import { I18n } from "i18n-js";
//import translations from "./translations.json";

//const i18n = new I18n(translations);


/*const i18n = new I18n({
    en: {
      hello: "Hi!",
    },
    "pt-BR": {
      hello: "Olá!",
    },
    "es": {
      hello:"Que pasa nen!"  
    }
  });*/

  //import es from "./locales/es.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
//import de from "./locales/de.json";

const i18n = new I18n({
  ...es,
  //...de,
  ...en,
});


  i18n.defaultLocale = "en";
  i18n.locale = "en";

const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
  }

  //i18n.locale=getNavigatorLanguage().split("-")[0];
  try {
   const currentLocale=getNavigatorLanguage().split("-")[0];
   if(currentLocale.includes("es")) 
   {
     i18n.locale = currentLocale;
   }
   //console.log("NAVIGATOR LANGUAGE:"+getNavigatorLanguage().split("-")[0])

  }
  catch(Exxx) {}



  
export default i18n