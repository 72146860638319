
//require('dotenv').config()
//import dotenv from  'dotenv'
//import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
//dotenv.config()

export function getMONEDADivisa(currency,quoteType)
{
 //console.log('currency:'+currency); 
 //console.log('quoteType:'+quoteType); 
 // (si) "quoteType":"EQUITY" "quoteType":"CURRENCY",
 //(no) "quoteType":"INDEX","quoteType":"FUTURE"
 try {
  if(quoteType=="INDEX" /*|| quoteType=="FUTURE"*/) { return("")}
  else {
    switch(currency)
    {
    case 'USD': return('$'); 
    case 'JPY': return('¥'); 
    case 'GBP': return('£'); 
    case 'GBp': return('p');
    case 'AUD': return('$'); 
    case 'NZD': return('$'); 
    case 'CHF': return('Fr'); 
    case 'HKD': return('HK$'); 
    case 'INR': return('₹'); 
    case 'BRL': return('R$'); 
    case 'ARS': return('$');
    case 'MXN': return('$'); 
    case 'CLP': return('$');
    case 'EUR': return('€'); 

    default: 
   return(' '); 
   }
  }
 } catch(Exxxxc) {return('€'); }
  
}

var URL_API_RSS2JSON="https://api.rss2json.com/v1/api.json?rss_url=";
var URL_RSS_TAGS_CINCODIAS="https://cincodias.elpais.com/tag/rss/";

export default 
{
  //Variables de entorno
  HOME_URL:process.env.REACT_APP_HOME_URL,
  //HOME_URL:"https://app.pro1.trading",
  USER_API_POST:process.env.REACT_APP_USER_API_POST,
  PASS_API_POST:process.env.REACT_APP_PASS_API_POST,
  USER_API:process.env.REACT_APP_USER_API, //"toca.45ls",
  PASS_API:process.env.REACT_APP_PASS_API, //"depeche12.hhy",
  ARRAY_COTIS_MORE_POPULAR:process.env.REACT_APP_ARRAY_COTIS_MORE_POPULAR, //"^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X",
  URL_FETCH_COTIS:process.env.REACT_APP_URL_FETCH_COTIS,
  URL_FETCH_COTIS_SEARCH:process.env.REACT_APP_URL_FETCH_COTIS_SEARCH,

  URL_FETCH_SEARCH_NEWS:process.env.REACT_APP_URL_FETCH_SEARCH_NEWS,

  REGISTER_ONLINE:false,//process.env.REACT_APP_REGISTER_ONLINE,

  URL_API_RSS2JSON:"https://api.rss2json.com/v1/api.json?rss_url=",

  URL_FETCH_COTIS_HISTORY:process.env.REACT_APP_URL_FETCH_COTIS_HISTORY,
  URL_cincodiasRssFeed:process.env.REACT_APP_URL_cincodiasRssFeed,
  URL_cincodiasPortadaRssFeed:process.env.REACT_APP_URL_cincodiasPortadaRssFeed,
  URL_BACKEND_PROD:process.env.REACT_APP_URL_BACKEND_PROD,
  URL_PLAYSTORE: process.env.REACT_APP_URL_PLAYSTORE,//"https://play.google.com/store/apps/details?id=com.pro1trading",
  URL_APPSTORE: process.env.REACT_APP_URL_APPSTORE,
  LINK_URL_TIPOS_SUSCRIPCION_ES: process.env.REACT_APP_LINK_URL_TIPOS_SUSCRIPCION_ES,
  LINK_URL_TIPOS_SUSCRIPCION_EN: process.env.REACT_APP_LINK_URL_TIPOS_SUSCRIPCION_EN,
  MAXMILIS_REFRESH_TOPTRADERSLIST: process.env.REACT_APP_MAXMILIS_REFRESH_TOPTRADERSLIST,
  USER_API_COTIS:process.env.REACT_APP_USER_API_COTIS,
  PASS_API_COTIS:process.env.REACT_APP_PASS_API_COTIS,
  MAXMILIS_REFRESH_MORE_POPULAR:process.env.REACT_APP_MAXMILIS_REFRESH_MORE_POPULAR,
  MAXMILIS_REFRESH_MY_WATCHLIST:process.env.REACT_APP_MAXMILIS_REFRESH_MY_WATCHLIST,  
  MAXMILIS_REFRESH_MY_PORTFOLIO:process.env.REACT_APP_MAXMILIS_REFRESH_MY_PORTFOLIO,
  //////////////////////


  LAST_ELEMENT_SEARCH:[],
  READY_TO_READ:false,

  DATA_COTIS2:[],
  API_KEY_RSS2JSON:"timwdlggleikh7dlsj1raaqgwlicorvukwiq66la",
  USER_LANG:'en-US',
  USER_LANG_PRO1:'EN',
  DEFAULT_INDEX_TABS_FOLLOWS:0,
  MODE_FOLLOWERS:1,
  MODE_FOLLOWING:2,
  MODE_PRE_FOLLOWERS:3,
  MODE_PRE_FOLLOWING:4,
  MODE_TITLE_FOLLOWERS:5,
  MODE_TITLE_FOLLOWING:6,
  MODE_TITLE_PRE_FOLLOWERS:7,
  MODE_TITLE_PRE_FOLLOWING:8,

  NUM_FOLLOWERS:0,
  NUM_FOLLOWING:0,
  NUM_PRE_FOLLOWERS:0,
  NUM_PRE_FOLLOWING:0,
  DONT_REFRESH_FETCH_TODO_USER:false,
  SHOW_MY_PROFILE:null,
  TOAST_PENDIENTE_ID:0,
  STATUS_FOLLOW:0,
  lastTimeMOREPOPULAR_COTIS:0,
  lastTimeMYWATCHLIST_COTIS:0,
  lastTimeMYPORTFOLIO_COTIS:0,
  MOREPOPULAR_COTIS: [],
  MYWATCHLIST_COTIS: [],
  MYPORTFOLIO_COTIS: [],


  SHOW_PUBLIC_PROFILE_USER:false,
  SHOW_PUBLIC_PROFILE_USER_INFO:false,
  SHOW_PUBLIC_PROFILE_BTNFOLLOW:true,

  SHOW_PUBLIC_CARTERA:false,
  SHOW_PUBLIC_CARTERA_TIT_BENEF:true,
  SHOW_PUBLIC_CARTERA_ONLY_FOLLOW:false,
  SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW:false,
  SHOW_PUBLIC_HISTORIAL:false,
  SHOW_PUBLIC_HISTORIAL_TIT_BENEF:true,
  SHOW_TOPTRADER_PROFILE:[],

  lastTimeTOPTRADERSLIST:0,
  REFRESH_FRONTUSER:false,
  GLOBAL_CURRENT_PARTDIVISAS:[],
  CURRENT_FAVORITOS:[],
  CURRENT_HISTORIAL:[],
  CURRENT_PORTFOLIO:[], 
  USER_LOADED:false,
  MODE_USER:0,
  MODE_USER_WEB:0,
  TOPTRADERSLIST: [],
  APP_STATE_ACTIVE:"active",
  APP_STATE_BACKGROUND:"background",
  APP_STATE_STATE:"",
  APP_STATE_RUNNING:false, 
  FORCE_REFRESH_ELEMENT:0,
  
  USER_LOADED:false,
  CURRENT_MENU_ID:1,
  


  ARRAY_TAGS_ES: [ 

    { name:'IBEX 35', symbol:'^IBEX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ibex_35/", 
      relatedTickers:["SAN.MC","IBE.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "ITX.MC", "CABK.MC","^STOXX50E"] },

      { name:'Microsoft', symbol:'MSFT', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"microsoft/", 
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "TSLA", "AAPL", "^NDX","NVDA"] },

    
    /*  
    { name:'Meta Platforms', symbol:'META', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"meta_platforms/" },
    { name:'Microsoft', symbol:'MSFT', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"microsoft/" },
    { name:'Check Point', symbol:'CHKP', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"check_point/" },
      */

      /*{ name:'Apple', symbol:'AAPL', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"apple_computer/" },*/
      { name:'Almirall', symbol:'ALM.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"almirall/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     /* { name:'Amazon', symbol:'AMZN', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amazon/" },
      { name:'Adobe', symbol:'ADBE', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"adobe/" },*/
      { name:'Acciona', symbol:'ANA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acciona/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Aena', symbol:'AENA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"aena_aeropuertos/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'ACS', symbol:'ACS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acs/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "FER.MC", "ANA.MC","^STOXX50E"] },
      { name:'Amadeus', symbol:'AMS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amadeus/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "AENA.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Amper', symbol:'AMP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amper/",
        relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Alphabet', symbol:'GOOG', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"alphabet/" },
      { name:'Acerinox', symbol:'ACX.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acerinox/",
      relatedTickers:["^IBEX","SAN.MC", "MTS.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'ArcelorMittal', symbol:'MTS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"arcelormittal/",
      relatedTickers:["^IBEX","SAN.MC", "ACX.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Bankinter', symbol:'BKT.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"bankinter/" ,
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Banco de Sabadell', symbol:'SAB.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"banco_de_sabadell/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "CABK.MC", "BKT.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Santander', symbol:'SAN.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"banco_de_santander/",
      relatedTickers:["^IBEX","BKT.MC", "BBVA.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'BBVA', symbol:'BBVA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"bbva_banco_bilbao_vizcaya_argentaria/",
      relatedTickers:["^IBEX","SAN.MC", "BKT.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },

      { name:'Caixabank', symbol:'CABK.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"caixabank/",
      relatedTickers:["^IBEX","SAN.MC", "BKT.MC", "CABK.MC", "SAB.MC", "TEF.MC", "BBVA.MC","^STOXX50E"] },

      //{ name:'Coca Cola', symbol:'KO', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"coca_cola/" },
      { name:'Cellnex', symbol:'CLNX.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"cellnex_telecom/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "FER.MC", "IAG.MC", "TEF.MC", "IND.MC","^STOXX50E"] },
      { name:'Enagas', symbol:'ENG.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"enagas/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "NTG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Endesa', symbol:'ELE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"endesa/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "NTG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Easyjet', symbol:'EZJ.L', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"easy_jet/",
      relatedTickers:["^IBEX","IAG.MC", "AF.PA", "ADP.PA", "AENA.MC", "LHA.DE", "CABK.MC","^STOXX50E"] },
      { name:'Ezentis', symbol:'EZE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ezentis_energia/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "AMP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Ercros', symbol:'ECR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ercros/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Fluidra', symbol:'FDR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"fluidra/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Ferrovial', symbol:'FER.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ferrovial/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ACS.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     // { name:'Ferrari', symbol:'RACE', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ferrari/" },
      { name:'Naturgy', symbol:'NTGY.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"gas_natural_fenosa/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ELE.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     // { name:'Google', symbol:'GOOG', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"google" },
     // { name:'Intel', symbol:'INTL', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"intel/" },
      { name:'Indra', symbol:'IDR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"indra/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'IAG', symbol:'IAG.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"iag_international_airlines_group/",
      relatedTickers:["^IBEX","EZJ.L", "AF.PA", "ADP.PA", "AENA.MC", "LHA.DE", "CABK.MC","^STOXX50E"] },
      { name:'Iberdrola', symbol:'IBE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"iberdrola/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ELE.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },

    

      { name:'Colonial', symbol:'COL.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"inmobiliaria_colonial/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "FER.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Laboratios Rovi', symbol:'ROVI.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"laboratorios_rovi/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "GRF.MC", "ALM.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Grifols', symbol:'GRF.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"laboratorios_grifols/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ALM.MC", "ROVI.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Mapfre', symbol:'MAP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"mapfre/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Moderna', symbol:'MRNA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"moderna/" },
      { name:'Melia', symbol:'MEL.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"melia/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Netflix', symbol:'NFLX', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"netflix/" },
      //{ name:'NVIDIA', symbol:'NVDA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"nvidia/" },
      { name:'Orange', symbol:'ORA.PA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"orange/",
      relatedTickers:["^IBEX","DTE.DE", "VOD.L", "T", "VZ", "TEF.MC", "TMUS","^STOXX50E"] },
      { name:'Pharmamar', symbol:'PHM.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"pharmamar/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Qualcomm', symbol:'QCOM', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"qualcomm/" },
      { name:'Repsol', symbol:'REP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"repsol_petroleo/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "IBE.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'REE', symbol:'RED.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"red_electrica_telecomunicaciones/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ENG.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Samsung', symbol:'005930.KS', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"samsung/",
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "TSLA", "MSFT","AAPL", "^NDX","NVDA"] },
      { name:'Sacyr', symbol:'SYCR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"sacyr/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ANA.MC", "FER.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Solaria', symbol:'SLR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"solaria/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "ADX.MC", "TEF.MC", "IBE.MC","^STOXX50E"] },
      { name:'Siemens Gamesa', symbol:'SGRE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"siemens_gamesa/" },
     // { name:'Symantec', symbol:'CHKP', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"symantec/" },
      //{ name:'TSMC', symbol:'TSMC34.SA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"tsmc_taiwanese_semiconductor_manufacturing/" },
     { name:'Tesla', symbol:'TSLA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"tesla_motors/", 
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "MSFT", "AAPL", "^NDX","NVDA"] },

{ name:'Telefonica', symbol:'TEF.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"telefonica/",
relatedTickers:["^IBEX","DTE.DE", "VOD.L", "T", "VZ", "ORA.PA", "TMUS","^STOXX50E"] },
{ name:'Vodafone', symbol:'VOD.L', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"vodafone/",
relatedTickers:["^IBEX","DTE.DE", "ORA.PA", "T", "VZ", "TEF.MC", "TMUS","^STOXX50E"] },



  ],

  ARRAY_TAGS_FR: [ 

    { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'BFM Bourse', 
      rss_url : URL_API_RSS2JSON+"https://www.tradingsat.com/rssbourse.xml", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      /*{ name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'BFMBusiness Actualites', 
      rss_url : URL_API_RSS2JSON+"https://bfmbusiness.bfmtv.com/rss/info/flux-rss/flux-toutes-les-actualites/", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },*/

     /* { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Titres', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/titres.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },*/


     { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'20minutes economie', 
      rss_url : URL_API_RSS2JSON+"https://www.20minutes.fr/feeds/rss-economie.xml", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Marches', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/economie/bourse/marches.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Enterprises', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/economie/entreprises.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'ABC Bourse', 
      rss_url : URL_API_RSS2JSON+"https://www.abcbourse.com/rss/displaynewsrss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'Capital', 
      rss_url : URL_API_RSS2JSON+"https://www.capital.fr/rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },


        
      /*
      { id:1, lang:'ES', checked:true, canalID:24, rss_id : 9, rss_source:'Le Monde Une',   rss_url : 'https://www.lemonde.fr/rss/une.xml' },
      { id:2, lang:'ES', checked:true, canalID:25, rss_id : 9, rss_source:'Le Monde En continu',   rss_url : 'https://www.lemonde.fr/rss/en_continu.xml' },
      { id:3, lang:'ES', checked:true, canalID:26, rss_id : 9, rss_source:'Le Monde Economie',   rss_url : 'https://www.lemonde.fr/economie/rss_full.xml' },
      { id:4, lang:'ES', checked:false, canalID:27, rss_id : 9, rss_source:'Le Monde Enterprises',   rss_url : 'https://www.lemonde.fr/entreprises/rss_full.xml' },
      { id:5, lang:'ES', checked:false, canalID:28, rss_id : 9, rss_source:'Le Monde Argent',   rss_url : 'https://www.lemonde.fr/argent/rss_full.xml' },
  
      //{ id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFMBusiness Actualites',   rss_url : 'https://bfmbusiness.bfmtv.com/rss/info/flux-rss/flux-toutes-les-actualites/' },
      { id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFM Bourse',   rss_url : 'https://www.tradingsat.com/rssbourse.xml' },
      { id:7, lang:'ES', checked:false, canalID:29, rss_id : 3, rss_source:'ABC Bourse',   rss_url : 'https://www.abcbourse.com/rss/displaynewsrss' },
      { id:8, lang:'ES', checked:true, canalID:30, rss_id : 3, rss_source:'Capital',   rss_url : 'https://www.capital.fr/rss' },
      { id:9, lang:'ES', checked:true, canalID:4, rss_id : 3, rss_source:'20minutes economie',   rss_url : 'https://www.20minutes.fr/feeds/rss-economie.xml' },
      { id:10, lang:'ES', checked:true, canalID:5, rss_id : 3, rss_source:'FranceTV Info',   rss_url : 'https://www.francetvinfo.fr/economie.rss' },
      { id:11, lang:'ES', checked:true, canalID:6, rss_id : 3, rss_source:'FranceTV Titres',   rss_url : 'https://www.francetvinfo.fr/titres.rss' },
      //{ id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Marches',   rss_url : 'https://www.francetvinfo.fr/economie/bourse/marches.rss' },
      { id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Enterprises',   rss_url : 'https://www.francetvinfo.fr/economie/entreprises.rss' },
      { id:13, lang:'ES', checked:true, canalID:8, rss_id : 3, rss_source:'FranceTV Monde',   rss_url : 'https://www.francetvinfo.fr/monde.rss' },
      { id:14, lang:'ES', checked:true, canalID:9, rss_id : 3, rss_source:'FranceTV France',   rss_url : 'https://www.francetvinfo.fr/france.rss' },
      */

  ],

  ARRAY_TAGS_DE: [ 

    { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel News', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 
   
      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Politik', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] },  
      
      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Wirtschaft', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/wirtschaft/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Ausland', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/ausland/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      /*{ name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Netzwelt', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/netzwelt/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Panorama', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/panorama/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, */
    ],


 



  FLAGS_COUNTRIES_ID: [
    "/assets/flags/eu.png",
    "/assets/flags/spain.png",
    "/assets/flags/eu.png", 
    "/assets/flags/us.png", 
    "/assets/flags/france.png",
    "/assets/flags/germany.png",
    "/assets/flags/uk.png",
    "/assets/flags/italy.png", 
    "/assets/flags/japan.png",
    "/assets/flags/belgium.png",
    "/assets/flags/hongkong.png", 
    "/assets/flags/brazil.png", 
    "/assets/flags/mexican.png", 
    "/assets/flags/chile.png", 
    "/assets/flags/eu.png",
    "/assets/flags/argentina.png",
    "/assets/flags/canada.png",
    "/assets/flags/south_korea.png",
    "/assets/flags/newzealand.png",
    "/assets/flags/india.png",
    "/assets/flags/australia.png",
    "/assets/flags/norway.png",
    "/assets/flags/sweden.png",
    "/assets/flags/swiss.png",
    "/assets/flags/hungary.png",
    "/assets/flags/china.png",
    "/assets/flags/singapure.png",
    "/assets/flags/australia.png",
    "/assets/flags/philipines.png",
    "/assets/flags/thailand.png",
    "/assets/flags/southafrica.png",
    "/assets/flags/russia.png",
    "/assets/flags/indonesia.png",
    "/assets/flags/netherlands.png" 
    ],
  
  
  AVATAR_TOPTRADERS: [
    "prof1.jpg",
  "prof2.jpg",
  "prof3.jpg",
  "prof4.jpg",
  "prof5.jpg",
  "prof6.jpg",
  "prof7.jpg",
  "prof8.jpg",
  "prof9.jpg",
  "prof10.jpg",
  "prof11.jpg",
  "prof12.jpg",
  "prof13.jpg",
  "prof14.jpg",
  "prof15.jpg",
  "prof16.jpg",
  "prof17.jpg",
  "prof18.jpg",
  "prof19.jpg",
  "prof20.jpg",
  "prof21.jpg",
  "prof22.jpg",
  "prof23.jpg",
  "prof24.jpg",
  "prof25.jpg",
  "prof26.jpg",
  "prof27.jpg",
  "prof28.jpg",
  "prof29.jpg",
  "prof30.jpg",
  "prof31.jpg",
  ],

};
