import {useState, useEffect} from 'react'
import glovar from '../content/glovar';


export const useFetchTodoToptraders = () => {

 
  /*
   let isFetchingTopTraders = false;
    ];*/

   const [todoTopTraders, setTodo] = useState([]);
   const [isFetchingTopTraders, setIsFetching] = useState(true);
 
  useEffect(() => {

    var TIRAR_DE_CACHE=false;

  try {
    if(glovar.TOPTRADERSLIST.length>10) {
    //console.log("glovar.TOPTRADERSLIST:"+glovar.TOPTRADERSLIST)
    TIRAR_DE_CACHE=true;
    setTodo(glovar.TOPTRADERSLIST)
    setIsFetching(false)
    }
  }
  catch(Exx) {}

  if(!TIRAR_DE_CACHE) 
  {
    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
  
    
    fetch(glovar.URL_BACKEND_PROD+'/api/toptraders', { method:'GET', //mode: 'no-cors',
   //fetch('https://p1tback-production.up.railway.app/api/toptraders', { method:'GET', //mode: 'no-cors',
  headers: headers,
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {

     //Aqui tenemos que ordenarlo por rent.
    try { json.sort(function(a,b){ return parseFloat(b.rentab)  - parseFloat(a.rentab);}) }
    catch(Exxpp) {}
    
    setTodo(json)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  }


  },[]);
 
 return { todoTopTraders, isFetchingTopTraders }
   

 //return { todoTopTraders }
 
 }
 

/*
export const useFetchTodoTopTraders = () => {

  const [todoTopTraders, setTodoTopTraders] = useState([]);
  const [isFetchingTopTraders, setIsFetchingToptraders] = useState(true);
 
  useEffect(() => {
 


   const dataToptraders = [
    { id:1, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:2, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:3, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:4, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:5, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:6, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:7, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:8, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:9, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:10, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:12, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:12, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:13, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:14, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:15, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:16, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:17, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:18, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    { id:19, name:'PeterSmith', ops:112, opsbenef : 69, follows:5688,  rent:178.89  },
    { id:20, name:'12shady_mn', ops:786, opsbenef : 258, follows:456,  rent:66.55  },
    { id:21, name:'Eva_trading', ops:62, opsbenef : 9, follows:1234,  rent:456.19  },
    { id:22, name:'Pablito_007', ops:234, opsbenef : 123, follows:19688,  rent:9.89  },
    { id:23, name:'JamesTCL', ops:45, opsbenef : 33, follows:38,  rent:299.13  },
    { id:24, name:'THOMSONtrading', ops:143, opsbenef : 5, follows:99,  rent:178.89  },
    ];

    setTodoTopTraders(dataToptraders);
    setIsFetchingToptraders(false);

  },[]);


 
 return { todoTopTraders, isFetchingTopTraders }
 
 }*/
 