

import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import { VStack } from "@chakra-ui/layout"; 
import { Image } from "@chakra-ui/image";
import glovar from './glovar';
import { useToast } from '@chakra-ui/react'

import i18n from '../../i18n';
import ReactGA from 'react-ga4';
import React, { useState } from 'react';
import element from "./element";

//import buttonApple from `${process.env.PUBLIC_URL}/assets/btnapple.png`


//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
}



function shareProfile() {
  //window.open(glovar.HOME_URL+"/"+glovar.USER_USERNAME,"_self")
  navigator.clipboard.writeText(glovar.HOME_URL+"/"+glovar.USER_USERNAME) 
}

function shareSymbol(symbol) {
  //window.open(glovar.HOME_URL+"/"+glovar.USER_USERNAME,"_self")
  navigator.clipboard.writeText(glovar.HOME_URL+"/symbol/"+symbol) 
}

function btndownloadApp(app) 
{

  var DEFAULT_LANG="EN"

  try {  DEFAULT_LANG=getNavigatorLanguage().split("-")[0]; } catch (exxx) {}

  if(app==1)  { 
    window.open(glovar.URL_APPSTORE,"_self")
    try { ReactGA.event("btn_download_IOS"); } catch(Exxx) {}
    try { ReactGA.event("btndwload_IOS_"+DEFAULT_LANG); } catch(Exxx) {}
  }
  if(app==2)  {
    window.open(glovar.URL_PLAYSTORE,"_self")
    try { ReactGA.event("btn_download_ANDROID"); } catch(Exxx) {}
    try { ReactGA.event("btndwnload_AND_"+DEFAULT_LANG); } catch(Exxx) {}
    //try { ReactGA.pageview("")}
  }
 }

 function openlinkurl() {

  var DEFAULT_LANG="EN";

  var LINK_URL_SUSCRIPTION=glovar.LINK_URL_TIPOS_SUSCRIPCION_EN;
  try {
    const currentLocale=getNavigatorLanguage().split("-")[0];
    if(currentLocale.includes("es")) 
    {
      LINK_URL_SUSCRIPTION=glovar.LINK_URL_TIPOS_SUSCRIPCION_ES;
      DEFAULT_LANG="ES";
    }
    //console.log("NAVIGATOR LANGUAGE:"+getNavigatorLanguage().split("-")[0])
 
   }
   catch(Exxx) {}


  window.open(LINK_URL_SUSCRIPTION,"_self")

  try { ReactGA.event("btn_PRO1PRWEB_"+DEFAULT_LANG); } catch(Exxx) {}
 }
 

 //Con pro1 PREMIUM WEB podrás consultar la cartera de tus TopTraders favoritos y recibir notificaciones PUSH con toda su actividad en tiempo real.</Text>
        
 var showShareOptions=false;
 var MOBILE_VERSION=0;

  export default ({ mode, mobile, name, symbol }) => {

    if(mobile==1) {
      MOBILE_VERSION=true;
    }

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    //console.log("render");

    const toast = useToast()

    var withicons="11%"
    if(mode==7) 
    {
      return (
      <HStack marginTop={-3} flexDirection="row"  marginRight={0} marginLeft={0}>
      
      {showShareOptions&&<HStack>
    
      <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_whatsapp_black_24dp.png`}
           width={withicons}      onClick={() => 
        {
          window.open('https://web.whatsapp.com/send?text=¡Hola! Te envío la ficha técnica completa de '+name+' en \n https://app.pro1.trading/symbol/'+symbol,"_blank")
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_twitter_black_36dp.png`}
             width={withicons}  onClick={() => 
        {
          window.open('http://twitter.com/share?text=Ficha técnica completa de '+symbol+" en @Pro1trading \n+"+'&url=https://app.pro1.trading/symbol/'+symbol/*+'&hashtags=pro1trading,stocks,portfolio'*/,"_blank")
          //window.open('http://twitter.com/share?text=Este es mi portfolio de valores e historial en @Pro1tradingES+'+"\n+"+'&url=https://app.pro1.trading/'+glovar.USER_USERNAME/*+'&hashtags=pro1trading,stocks,portfolio'*/,"_blank")
    
        }} />

        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_linkedin_black_36dp.png`}
              width={withicons}   onClick={() => 
        {
          window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://app.pro1.trading/symbol/'+symbol+
          '&title=Pro1.trading Portfolio&summary=Ficha técnica completa de '+name+' en Pro1.trading&source=https://app.pro1.trading/symbol/'+symbol,"_blank")
        }} />

        {(1==2)&&<Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_instagram_black_24dp.png`}
              width={withicons}   onClick={() => 
        {
          
        }} />}
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_email_black_24dp.png`}
              width={withicons}   onClick={() => 
        {
          window.open('mailto:?subject=Ficha técnica de '+name+' en Pro1.trading&body=¡Hola! Te envío la ficha técnica de '+name+' en Pro1.trading: \n'+ 
          'https://app.pro1.trading/symbol/'+symbol+' \n')
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_link_black_24dp.png`} width={withicons}
        onClick={() => 
          {
            shareSymbol(symbol);
           //shareProfile();
           toast({
             position: 'bottom-left',
             render: () => (
            <Box color='white' p={3} bg='blue.500'>
              URL copiada en el portapapeles
            </Box>
           ),
          })
        
        }} />
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
      width={withicons} 
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />
        </HStack>}


        {!showShareOptions&&
        <HStack onClick={() => 
          {
            showShareOptions=!showShareOptions;
            //console.log("he pulsaaaaaaaaaa")
            forceUpdate()
           }
          }><Text fontWeight="normal" fontSize={14} _hover={{ cursor: "pointer" }}  extColor="black">{i18n.t("compa_ficha")}</Text>
        <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/ic_share_variant_black_24dp.png`}
      width="12%"
         /></HStack>}
      </HStack>);
    }
    
    if(mode==6) 
    {
      return (
      <HStack marginTop={0} flexDirection="row" /*justifyContent="flex-end"*/  marginRight={0} marginLeft={3} >
      
      {showShareOptions&&<HStack  flexDirection="row" /*justifyContent="flex-end"*/ marginRight="-1%">
    
      <Image _hover={{ cursor: "pointer" }} src='assets/ic_whatsapp_black_24dp.png' 
              width="10%"   onClick={() => 
        {
          window.open('https://web.whatsapp.com/send?text=¡Hola! Te envío mi portfolio de valores e historial en Pro1.trading\n https://app.pro1.trading/'+glovar.USER_USERNAME,"_blank")
        }} />
        <Image _hover={{ cursor: "pointer" }} src='assets/ic_twitter_black_36dp.png'
             width="10%"  onClick={() => 
        {
          window.open('http://twitter.com/share?text=Este es mi portfolio de valores e historial en @Pro1tradingES+'+"\n+"+'&url=https://app.pro1.trading/'+glovar.USER_USERNAME/*+'&hashtags=pro1trading,stocks,portfolio'*/,"_blank")
        }} />

        <Image _hover={{ cursor: "pointer" }} src='assets/ic_linkedin_black_36dp.png'
              width="10%"   onClick={() => 
        {
          window.open('https://www.linkedin.com/shareArticle?mini=true&url=https://app.pro1.trading/'+glovar.USER_USERNAME+
          '&title=Pro1.trading Portfolio&summary=Este es mi portfolio de valores e historial en Pro1.trading&source=https://app.pro1.trading/'+glovar.USER_USERNAME,"_blank")
        }} />

        {(1==2)&&<Image _hover={{ cursor: "pointer" }} src='assets/ic_instagram_black_24dp.png'
              width="10%"   onClick={() => 
        {
          
        }} />}
        <Image _hover={{ cursor: "pointer" }} src='assets/ic_email_black_24dp.png'
              width="10%"   onClick={() => 
        {
          window.open('mailto:?subject=Mi portfolio en Pro1.trading&body=¡Hola! Te envío mi portfolio de valores e historial en Pro1.trading: \n'+ 
          'https://app.pro1.trading/'+glovar.USER_USERNAME)
        }} />
        <Image _hover={{ cursor: "pointer" }} src='assets/ic_link_black_24dp.png' width="10%"  
        onClick={() => 
          {
           shareProfile();
           toast({
             position: 'bottom-left',
             render: () => (
            <Box color='white' p={3} bg='blue.500'>
              URL copiada en el portapapeles
            </Box>
           ),
          })
        
        }} />
        <Image _hover={{ cursor: "pointer" }} src='assets/ic_share_variant_black_24dp.png'
      width="9%"  
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />
        </HStack>}


        {!showShareOptions&&<Image _hover={{ cursor: "pointer" }} src='assets/ic_share_variant_black_24dp.png'
      width="19%"  
        onClick={() => 
        {
          showShareOptions=!showShareOptions;
          //console.log("he pulsaaaaaaaaaa")
          forceUpdate()
         }
        } />}
      </HStack>);
    }
    if(mode==5) {

      if(MOBILE_VERSION) {
        return (
          <VStack>
          <Text  fontSize="m" fontWeight="normal" textAlign="center" p={1} mb={0} ml={2} mr={2} pt={2} textColor="gray.800">
            {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
            <HStack marginTop={4} paddingBottom={2}>
            <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} p={0}  width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
            <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} p={0}  width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
            </HStack>     
          </VStack>
        );
      }
      else {
      return (
        <VStack>
        <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={0} ml={8} mr={8} pt={4} textColor="gray.800">
          {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
          <HStack marginTop={4}>
          <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
          <Image _hover={{ cursor: "pointer" }} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
          </HStack>     
        </VStack>
      );
      }
    }
    if (mode==4) {
      return (
      <VStack align="center">
        
      <Image src={`${process.env.PUBLIC_URL}/assets/logopro1trading.png`} pl={20} pr={20} pt={10} pb={10}  marginTop={0} />
      
      
      <Text  fontSize="xl" fontWeight="normal" textAlign="center" p={0} mb={0} ml={0} pt={0} textColor="gray.800">
        {i18n.t("webapp_only_desktop")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
        <VStack marginTop={12}>
        <Image _hover={{ cursor: "pointer" }} pt={5} src={`${process.env.PUBLIC_URL}/assets/btnapple.png`} onClick={() => btndownloadApp(1)} />
        <Image _hover={{ cursor: "pointer" }} pt={2} src={`${process.env.PUBLIC_URL}/assets/btnandroid.png`} onClick={() => btndownloadApp(2)}  />
        </VStack>
     
        

      </VStack>
       );
    }
    if(mode==3) {

      var classButtton="formField";
      if(mobile) {
        classButtton="mobformField"; //Button";
      }

      return (
      <VStack marginTop="2pc">
        
       
       
      <Text  fontSize="m" fontWeight="semibold" textAlign="center" p={0} mb={3} ml={0} pt={0}  textColor="gray.600">
      {i18n.t("descript_pro1premiumweb")}</Text>
        
        <div onClick={() =>openlinkurl()}>
        <Image _hover={{ cursor: "pointer" }} src='assets/candado.png'p={0} width="6pc" mb={3}   />
        </div>
     
        <div className={classButtton} onClick={() =>openlinkurl()}>
          <button className="formFieldButton">{i18n.t("quiero_pro1premiumweb")}</button>{" "}
          
        </div>

      </VStack>
 
    );
    }


    if(mode==2) {
      return (
      <VStack marginTop="1pc">
        
      <Image src='assets/outline_add_circle.png'p={0}  marginTop={-2} />
      
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={10} ml={0} pt={0} textColor="gray.800">
        {i18n.t("add_activo_cartera_app")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
        <HStack marginTop="140px">
        <Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
        <Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
        </HStack>
     
      </VStack>
 
    );
    }

    else {
    return (
        
    
      <VStack>
        
      <Image src='assets/outline_add_circle.png'p={0}  marginTop={-2} />
      
      <Text  fontSize="m" fontWeight="normal" textAlign="center" p={4} mb={0} ml={0} pt={0} textColor="gray.800">
        {i18n.t("add_activo_cartera_app")}<br></br><br></br><b>{i18n.t("download_free")}</b></Text>
        <HStack marginTop={4}>
        <Image _hover={{ cursor: "pointer" }} src='assets/btnapple.png'p={0} width="40%" marginLeft="5%" marginRight="6%"  onClick={() => btndownloadApp(1)} />
        <Image _hover={{ cursor: "pointer" }} src='assets/btnandroid.png'p={0} width="40%" marginRight="8%" onClick={() => btndownloadApp(2)}  />
        </HStack>
     
      </VStack>
 
    );
    }
  };
  