import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
//import {signInWithEmailAndPassword, sendEmailVerification,sendPasswordResetEmail} from 'firebase/auth'
//import {auth} from './firebase'
import {useNavigate} from 'react-router-dom'
//import {useAuthValue} from './AuthContext'

//import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
//import { Stack } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus } from "react-icons/fi";
//import { HStack } from "@chakra-ui/layout";
//import { Avatar } from "@chakra-ui/avatar";
//import { Image } from "@chakra-ui/image";

//import glovar from './components/glovar';
import glovar from './components/content/glovar';

//import { Spacer } from "@chakra-ui/layout";
//import { Flex } from "@chakra-ui/layout";

import "./App.css";
//import { getToken, removeUserSession, setUserSession } from './utils/Common';
import i18n from './i18n';


function Login(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 

  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  //const {setTimeActive} = useAuthValue()
  const navigate = useNavigate()

  const forgotPassword = e => {
    e.preventDefault()

    //console.log("email:"+email)
    //console.log("password:"+password)
    //sendPasswordResetEmail(auth,email)

    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
    //headers.set('Parameters', 'Basic ' + btoa(email));
    headers.set('Parameters', 'Basic ' + btoa(email + ":" + email));
  
    fetch(glovar.URL_BACKEND_PROD+'/api/resetpass', { method:'GET', //mode: 'no-cors',
    //fetch("http://localhost:3010"+'/api/resetpass', { method:'GET', //mode: 'no-cors',
    //fetch(glovar.URL_BACKEND_PROD+'/api/resetpass', { method:'GET', //mode: 'no-cors',
    headers: headers,
    })
    .then(res => res.json())
    .then(res => {
      //console.log(res)
      if(res=="OK, sended") {
      setSuccess(i18n.t("we_have_sent_email")+" "+email+" "+i18n.t("with_link_reset_pass"));
      }
      else if(res=="auth/invalid-email") 
      {
          setError("ERROR: "+i18n.t("only_email_try_again"));  //Please try again or contact support if you are unable to access your account");
      }
      else {
        //setError("Errorx:"+err)
        setError("Error")
      }
      
    })
    .catch((err) => {  
      //console.log("error forget:"+JSON.stringify(err)); 
      //{"code":"auth/user-not-found","customData":{},"name":"FirebaseError"}

      try {
        if(err.code=="auth/user-not-found") {
          setError("ERROR: "+i18n.t("email_try_again"));  //Please try again or contact support if you are unable to access your account");
        }
        else {
          //setError("Errorx:"+err)
        setError("Error")
        }
      }
      catch(Excp) {}//setError("Error")}
      setError(err.message)
    })

    
 

 /*sendPasswordResetEmail(auth,email)
    //auth().signInWithEmailAndPassword(email, password)
    .then(() => 
    {

    {

      setSuccess(i18n.t("we_have_sent_email")+" "+email+" "+i18n.t("with_link_reset_pass"));
    }
    })
    //.catch(err => alert(err.message))
    //.catch(err => setError(err.message))
    .catch((err) => {  
      //console.log(JSON.stringify(err)); 
      //{"code":"auth/user-not-found","customData":{},"name":"FirebaseError"}

      try {
        if(err.code=="auth/user-not-found") {
          setError("ERROR: "+i18n.t("email_try_again"));  //Please try again or contact support if you are unable to access your account");
        }
        else {
          //setError("Errorx:"+err)
          setError("Error")
        }
      }
      catch(Excp) {setError("Error")}
      //setError(err.message)
    }
    
   )*/
  }

 
  return (
    <Box>
      <SimpleGrid columns={6} gap={3} >
      <GridItem colSpan={4} background={"#000000"/*"#F8F8F8"*/} height="100vh">
      <img  className="verticalhorizontal" src='assets/plogo.png' w={100}  />
    </GridItem>
    <GridItem colSpan={2}  height="100vh" >
    <div className="formCenter" background={"yellow"}>
    <Text style={{fontSize:"1.8em", fontWeight:"bold", marginBottom:"1pc" }}>{i18n.t("forgotpass_q")}</Text>
    {!success &&<Text style={{fontSize:"0.97em", fontWeight:"normal", marginBottom:"2pc", marginRight:"4pc" }}>
     {i18n.t("enter_email_associated")}
     </Text>}

     {success &&  <p>
      
     <div className='auth__success'><Text style={{fontSize:"0.97em", fontWeight:"normal", margin:"0.3pc" }}>{success}</Text></div>
     <Link to="/login">
     <button className="formFieldButton80">
     {i18n.t("done_back_signin")}
     </button>
 </Link>

     </p>}



    {error && !success && <div className='auth__error'>{error}</div>}
    {!success && <form className="formFields" onSubmit={forgotPassword}>
        <div className="formField">
          <label className="formFieldLabel" htmlFor="email">
            E-Mail
          </label>
          <input
            type="email"
            id="email"
            className="formFieldInput"
            placeholder={i18n.t("intro_email")}
            name="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
            //value={this.state.email}
            //onChange={this.handleChange}


          />
        </div>
       

     

        <div className="formField">
          <button className="formFieldButton">{i18n.t("request_pass_reset")}</button>{" "}
          
        </div>
        
        <Text
      fontWeight="bold"
      size="13"
      marginLeft="20%"
    >
      <Link to='/login'>{i18n.t("remember_sign")}</Link>
    </Text>
       
      </form>}


    </div>
    </GridItem>
    </SimpleGrid>
    </Box>
  );
}

export default Login