import {useState, useEffect} from 'react'
import glovar from '../content/glovar';


export const useFetchTodoRSS = (listRSS,tag) => {

  const [todoRSS, setTodoRSS] = useState([]);
  const [isFetchingRSS, setIsFetchingRSS] = useState(true);

   
 
  //const MAX_VIDEOS = 9000;
  var listCanalNews_ES= [
  //const youtubeRssFeed = "https://api.rss2json.com/v1/api.json?rss_url=https://youtube.com/feeds/videos.xml?channel_id=UCZUJ0nkrzeKkse3RLdedxHQ";
  //const cincodiasRssFeed = //glovar.URL_cincodiasRssFeed//"https://api.rss2json.com/v1/api.json?rss_url=https://cincodias.elpais.com/rss/cincodias/ultimas_noticias.xml";
  //{ id:0, lang:'ES', checked:true, canalID:2, rss_id : 2, 
  // rss_source:'www.pro1.trading',  rss_url:'https://api.rss2json.com/v1/api.json?rss_url=https://pro1.trading/es/category/analisis/feed/'  },
  
  
  { id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  
  rss_source:'CincoDías Ult. Noticias', rss_url : glovar.URL_cincodiasRssFeed },  //rss_url : 'https://cincodias.elpais.com/rss/cincodias/ultimas_noticias.xml' };

  //const cincodiasPortadaRssFeed = //glovar.URL_cincodiasPortadaRssFeed//"https://api.rss2json.com/v1/api.json?rss_url=https://cincodias.elpais.com/rss/cincodias/portada.xml";
  { id:1, lang:'ES', checked:true, canalID:3, rss_id : 3, 
  rss_source:'CincoDías Portada',   rss_url : glovar.URL_cincodiasPortadaRssFeed }, //'https://cincodias.elpais.com/rss/cincodias/portada.xml' };
  //const cincodiasMercadosRssFeed = 
  { id:3, lang:'ES', checked:false, canalID:5, rss_id : 3,    
    rss_source:'CincoDías Mercados', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://cincodias.elpais.com/seccion/rss/mercados/' },
  { id:4, lang:'ES', checked:false, canalID:6, rss_id : 3,   
    rss_source:'CincoDías Ibex35', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://cincodias.elpais.com/tag/rss/ibex_35/' },
  { id:5, lang:'ES', checked:false, canalID:7, rss_id : 3,    
    rss_source:'CincoDías Compañías', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://cincodias.elpais.com/seccion/rss/companias/' },
  
     //falla el thumbnail
    { id:7, lang:'ES', checked:true, canalID:9, rss_id : 4,    
    rss_source:'Cotizalia Mercados', 
    rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mercados/&api_key='+glovar.API_KEY_RSS2JSON },
    { id:8, lang:'ES', checked:true, canalID:10, 
    rss_id : 4,    rss_source:'Cotizalia Economía', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/economia/&api_key='+glovar.API_KEY_RSS2JSON },
    { id:9, lang:'ES', checked:true, canalID:11, rss_id : 4,    
    rss_source:'Cotizalía Empresas', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/empresas/&api_key='+glovar.API_KEY_RSS2JSON },
    //{ id:10, lang:'ES', checked:false, canalID:12, rss_id : 4,   
    // rss_source:'Cotizalia España', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/espana/&api_key='+glovar.API_KEY_RSS2JSON },
    { id:11, lang:'ES', checked:false, canalID:13, rss_id : 4,    
    rss_source:'Cotizalia Mundo', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mundo/&api_key='+glovar.API_KEY_RSS2JSON },
    { id:12, lang:'ES', checked:false, canalID:14, rss_id : 4,    
    rss_source:'Cotizalia Fondos', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mercados/fondos-de-inversion/&api_key='+glovar.API_KEY_RSS2JSONs },
    

  ];

    
  var listCanalNews_FR = [
    //{ id:1, lang:'ES', checked:true, canalID:24, rss_id : 9, rss_source:'Le Monde Une',   rss_url : 'https://www.lemonde.fr/rss/une.xml' },
    //{ id:2, lang:'ES', checked:true, canalID:25, rss_id : 9, rss_source:'Le Monde En continu',   rss_url : 'https://www.lemonde.fr/rss/en_continu.xml' },
    //{ id:3, lang:'ES', checked:true, canalID:26, rss_id : 9, rss_source:'Le Monde Economie',   rss_url : 'https://www.lemonde.fr/economie/rss_full.xml' },
    //{ id:4, lang:'ES', checked:false, canalID:27, rss_id : 9, rss_source:'Le Monde Enterprises',   rss_url : 'https://www.lemonde.fr/entreprises/rss_full.xml' },
    //{ id:5, lang:'ES', checked:false, canalID:28, rss_id : 9, rss_source:'Le Monde Argent',   rss_url : 'https://www.lemonde.fr/argent/rss_full.xml' },

    { id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFMBusiness Actualites',   rss_url : glovar.URL_API_RSS2JSON+'https://bfmbusiness.bfmtv.com/rss/info/flux-rss/flux-toutes-les-actualites/' },
    //{ id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFM Bourse',   rss_url : 'https://www.tradingsat.com/rssbourse.xml' },
    //{ id:7, lang:'ES', checked:false, canalID:29, rss_id : 3, rss_source:'ABC Bourse',   rss_url : 'https://www.abcbourse.com/rss/displaynewsrss' },
    //{ id:8, lang:'ES', checked:true, canalID:30, rss_id : 3, rss_source:'Capital',   rss_url : 'https://www.capital.fr/rss' },
    { id:9, lang:'ES', checked:true, canalID:4, rss_id : 3, rss_source:'20minutes economie',   rss_url : glovar.URL_API_RSS2JSON+'https://www.20minutes.fr/feeds/rss-economie.xml' },
    { id:10, lang:'ES', checked:true, canalID:5, rss_id : 3, rss_source:'FranceTV Info',   rss_url : glovar.URL_API_RSS2JSON+'https://www.francetvinfo.fr/economie.rss' },
    { id:11, lang:'ES', checked:true, canalID:6, rss_id : 3, rss_source:'FranceTV Titres',   rss_url : glovar.URL_API_RSS2JSON+'https://www.francetvinfo.fr/titres.rss' },
    //{ id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Marches',   rss_url : 'https://www.francetvinfo.fr/economie/bourse/marches.rss' },
    { id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Enterprises',   rss_url : glovar.URL_API_RSS2JSON+'https://www.francetvinfo.fr/economie/entreprises.rss' },
    { id:13, lang:'ES', checked:true, canalID:8, rss_id : 3, rss_source:'FranceTV Monde',   rss_url : glovar.URL_API_RSS2JSON+'https://www.francetvinfo.fr/monde.rss' },
    { id:14, lang:'ES', checked:true, canalID:9, rss_id : 3, rss_source:'FranceTV France',   rss_url : glovar.URL_API_RSS2JSON+'https://www.francetvinfo.fr/france.rss' },
  ];

  var listCanalNews_DE= [
    { id:1, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'Spiegel News',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/index.rss' },
    { id:2, lang:'ES', checked:true, canalID:4, rss_id : 3, rss_source:'Spiegel Politik',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/politik/index.rss' },
    { id:3, lang:'ES', checked:true, canalID:5, rss_id : 3, rss_source:'Spiegel Wirtschaft',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/wirtschaft/index.rss' },
    { id:4, lang:'ES', checked:true, canalID:6, rss_id : 3, rss_source:'Spiegel Ausland',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/ausland/index.rss' },
    { id:5, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'Spiegel Netzwelt',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/netzwelt/index.rss' },
    { id:6, lang:'ES', checked:true, canalID:8, rss_id : 3, rss_source:'Spiegel Panorama',   rss_url : glovar.URL_API_RSS2JSON+'https://www.spiegel.de/panorama/index.rss' },
  
    //Süddeutsche Zeitung
{ id:7, lang:'EN', checked:true, canalID:24, rss_id : 6,    rss_source:'SZ Top-themen', rss_url : glovar.URL_API_RSS2JSON+'https://rss.sueddeutsche.de/rss/Topthemen' },
{ id:8, lang:'EN', checked:true, canalID:25, rss_id : 6,    rss_source:'SZ Wirtschaft', rss_url : glovar.URL_API_RSS2JSON+'https://rss.sueddeutsche.de/rss/Wirtschaft' },
{ id:9, lang:'EN', checked:true, canalID:26, rss_id : 6,    rss_source:'SZ Politik', rss_url : glovar.URL_API_RSS2JSON+'https://rss.sueddeutsche.de/rss/Politik' },
//{ id:10, lang:'EN', checked:true, canalID:27, rss_id : 6,    rss_source:'SZ Panorama', rss_url : glovar.URL_API_RSS2JSON+'https://rss.sueddeutsche.de/rss/Panorama' },
//{ id:11, lang:'EN', checked:false, canalID:28, rss_id : 6,    rss_source:'SZ Alles', rss_url : glovar.URL_API_RSS2JSON+'https://rss.sueddeutsche.de/app/service/rss/alles/index.rss?output=rss' },


  ];


    var listCanalNews_EN= [
    { id:16, lang:'EN', checked:false, canalID:18, rss_id : 6,    rss_source:'CNBC Top News', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/100003114/device/rss/rss.html' },
  { id:17, lang:'EN', checked:false, canalID:19, rss_id : 6,    rss_source:'CNBC Business', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/10001147/device/rss/rss.html' },
  { id:18, lang:'EN', checked:false, canalID:20, rss_id : 6,    rss_source:'CNBC Finance', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/10000664/device/rss/rss.html' },
  { id:19, lang:'EN', checked:false, canalID:21, rss_id : 6,    rss_source:'CNBC Tecnology', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/19854910/device/rss/rss.html' },
  { id:20, lang:'EN', checked:false, canalID:22, rss_id : 6,    rss_source:'CNBC US News', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/15837362/device/rss/rss.html' },
  { id:21, lang:'EN', checked:false, canalID:23, rss_id : 6,    rss_source:'CNBC Europe News', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://www.cnbc.com/id/19794221/device/rss/rss.html' },
  

    //falla el thumbnail
    /*{ id:7, lang:'ES', checked:true, canalID:9, rss_id : 4,    rss_source:'Cotizalia Mercados', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mercados/' },
    { id:8, lang:'ES', checked:true, canalID:10, rss_id : 4,    rss_source:'Cotizalia Economía', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/economia/' },
    { id:9, lang:'ES', checked:true, canalID:11, rss_id : 4,    rss_source:'Cotizalía Empresas', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/empresas/' },
    { id:10, lang:'ES', checked:false, canalID:12, rss_id : 4,    rss_source:'Cotizalia España', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/espana/' },
    { id:11, lang:'ES', checked:false, canalID:13, rss_id : 4,    rss_source:'Cotizalia Mundo', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mundo/' },
    { id:12, lang:'ES', checked:false, canalID:14, rss_id : 4,    rss_source:'Cotizalia Fondos', rss_url : 'https://api.rss2json.com/v1/api.json?rss_url=https://rss.elconfidencial.com/mercados/fondos-de-inversion/' },
    */
  ];
  //{ id:0, lang:'ES', checked:true, canalID:2, rss_id : 2,  rss_source:'www.pro1.trading',  rss_url:'https://pro1.trading/category/analisis/feed/'  },
  /*{ id:1, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'CincoDías Portada',   rss_url : 'https://cincodias.elpais.com/rss/cincodias/portada.xml' },
  { id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías Ult. Noticias',  rss_url : 'https://cincodias.elpais.com/rss/cincodias/ultimas_noticias.xml' },
  { id:3, lang:'ES', checked:false, canalID:5, rss_id : 3,    rss_source:'CincoDías Mercados', rss_url : 'https://cincodias.elpais.com/seccion/rss/mercados/' },
  { id:4, lang:'ES', checked:false, canalID:6, rss_id : 3,   rss_source:'CincoDías Ibex35', rss_url : 'https://cincodias.elpais.com/tag/rss/ibex_35/' },
  { id:5, lang:'ES', checked:false, canalID:7, rss_id : 3,    rss_source:'CincoDías Compañías', rss_url : 'https://cincodias.elpais.com/seccion/rss/companias/' },
  { id:6, lang:'ES', checked:false, canalID:8, rss_id : 3,    rss_source:'CincoDías Smartlife', rss_url : 'https://cincodias.elpais.com/rss/cincodias/smartlife-f01.xml' },
  
  { id:7, lang:'ES', checked:true, canalID:9, rss_id : 4,    rss_source:'Cotizalia Mercados', rss_url : 'https://rss.elconfidencial.com/mercados/' },
  { id:8, lang:'ES', checked:true, canalID:10, rss_id : 4,    rss_source:'Cotizalia Economía', rss_url : 'https://rss.elconfidencial.com/economia/' },
  { id:9, lang:'ES', checked:true, canalID:11, rss_id : 4,    rss_source:'Cotizalía Empresas', rss_url : 'https://rss.elconfidencial.com/empresas/' },
  { id:10, lang:'ES', checked:false, canalID:12, rss_id : 4,    rss_source:'Cotizalia España', rss_url : 'https://rss.elconfidencial.com/espana/' },
  { id:11, lang:'ES', checked:false, canalID:13, rss_id : 4,    rss_source:'Cotizalia Mundo', rss_url : 'https://rss.elconfidencial.com/mundo/' },
  { id:12, lang:'ES', checked:false, canalID:14, rss_id : 4,    rss_source:'Cotizalia Fondos', rss_url : 'https://rss.elconfidencial.com/mercados/fondos-de-inversion/' },*/
  

  let URL_FEATCHING=2;

 

  useEffect(() => {

    
   //#####################//
   //## RSS News STOCK  ##//
   //#####################//
   if(tag==true)  {
   
    for(let j=0;j<listRSS.length;j++)
    {
    //console.log("fetch:"+listRSS[j].rss_url)
    fetch(listRSS[j].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    .then((newVideos) => {
      for (let ii = 0; ii < newVideos.length; ii++) {
        
        newVideos[ii].rss_id=listRSS[j].rss_id;
        newVideos[ii].subtitle=listRSS[j].rss_source;//+" "+listRSS[j].name;//"CincoDías Últimas noticias"
      } 
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    
    })
  
    .finally(() => {
      //URL_FEATCHING=URL_FEATCHING-1;
      //if(URL_FEATCHING==0) 
      {
      setIsFetchingRSS(false);
      }
     })
    .catch((error) => console.log(""));
    }
   }

   //#####################//
   //## RSS NEWS GLOBAL ##//
   //#####################//
   else {
    //console.log("length todoRSS:"+todoRSS.length)

  if(glovar.USER_LANG_PRO1=="ES") {
    for(let j=0;j<listCanalNews_ES.length;j++)
    {

    fetch(listCanalNews_ES[j].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {
      //console.log("newVideos length:"+newVideos.length)
      for (let ii = 0; ii < newVideos.length; ii++) {
        //console.log("title:"+newVideos[ii].title)
        newVideos[ii].rss_id=listCanalNews_ES[j].rss_id;
        newVideos[ii].subtitle=listCanalNews_ES[j].rss_source;//"CincoDías Últimas noticias"
      } 
      //  setTodoRSS(newVideos) 
        // setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    
      //setTodoRSS(todoRSS.concat(newVideos));
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    //.catch((error) => console.log(""));
    .catch((error) => {})
    }
  }

  else if(glovar.USER_LANG_PRO1=="FR") {
    for(let j=0;j<listCanalNews_FR.length;j++)
    {

    fetch(listCanalNews_FR[j].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {
      //console.log("newVideos length:"+newVideos.length)
      for (let ii = 0; ii < newVideos.length; ii++) {
        //console.log("title:"+newVideos[ii].title)
        newVideos[ii].rss_id=listCanalNews_FR[j].rss_id;
        newVideos[ii].subtitle=listCanalNews_FR[j].rss_source;//"CincoDías Últimas noticias"
      } 
      //  setTodoRSS(newVideos) 
        // setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    
      //setTodoRSS(todoRSS.concat(newVideos));
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    //.catch((error) => console.log(""));
    .catch((error) => {})
    }
  }

  else if(glovar.USER_LANG_PRO1=="DE") {
    for(let j=0;j<listCanalNews_DE.length;j++)
    {

    fetch(listCanalNews_DE[j].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {
      //console.log("newVideos length:"+newVideos.length)
      for (let ii = 0; ii < newVideos.length; ii++) {
        //console.log("title:"+newVideos[ii].title)
        newVideos[ii].rss_id=listCanalNews_DE[j].rss_id;
        newVideos[ii].subtitle=listCanalNews_DE[j].rss_source;//"CincoDías Últimas noticias"
      } 
      //  setTodoRSS(newVideos) 
        // setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    
      //setTodoRSS(todoRSS.concat(newVideos));
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    //.catch((error) => console.log(""));
    .catch((error) => {})
    }
  }

  //if(glovar.USER_LANG_PRO1=="ES") {
  else {
    for(let j=0;j<listCanalNews_EN.length;j++)
    {

    fetch(listCanalNews_EN[j].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {
      //console.log("newVideos length:"+newVideos.length)
      for (let ii = 0; ii < newVideos.length; ii++) {
        //console.log("title:"+newVideos[ii].title)
        newVideos[ii].rss_id=listCanalNews_EN[j].rss_id;
        newVideos[ii].subtitle=listCanalNews_EN[j].rss_source;//"CincoDías Últimas noticias"
      } 
      //  setTodoRSS(newVideos) 
        // setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    
      //setTodoRSS(todoRSS.concat(newVideos));
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    //.catch((error) => console.log(""));
    .catch((error) => {})
    }
  }

}

    /*
    fetch(listCanalNews_ES[1].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {

      for (let ii = 0; ii < newVideos.length; ii++) {
        newVideos[ii].subtitle=listCanalNews_ES[1].rss_source;//"CincoDías Portada"
      } 
      //console.log("newVideos:"+JSON.stringify(newVideos))
      //setTodoRSS(newVideos)
      //setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      //setTodoRSS(todoRSS.concat(newVideos));
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    .catch((error) => console.log(""));

    fetch(listCanalNews_ES[2].rss_url, { headers: { Accept: "application/json" } })
    .then((res) => res.json())
    .then((data) => data.items.filter((item) => item.title.length > 0))
    //.then((newVideos1) =>  newVideos1.filter((item) => !todoRSS.includes(item.link)))
    .then((newVideos) => {

      for (let ii = 0; ii < newVideos.length; ii++) {
        newVideos[ii].subtitle=listCanalNews_ES[2].rss_source//"CincoDías Mercados"
      } 
      //console.log("newVideos:"+JSON.stringify(newVideos))
      //setTodoRSS(newVideos)
      //setTodoRSS(todoRSS => todoRSS.concat(newVideos))
      //setTodoRSS(todoRSS.concat(newVideos));
      setTodoRSS(todoRSS => [...todoRSS, ...newVideos])
    //newVideos.slice(0, MAX_VIDEOS) 
    })
    //.then((videos) => setTodoRSS(videos))
    .finally(() => {
      URL_FEATCHING=URL_FEATCHING-1;
      if(URL_FEATCHING==0) {
      setIsFetchingRSS(false);
      }
     })
    .catch((error) => console.log(""));
    */
    

   
  }
  
  
  ,[]);

  
 return { todoRSS, isFetchingRSS }
 
 }
 