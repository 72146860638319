import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
import PortfolioListItem from "./PortfolioListItem";
import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import gsap from "gsap";
//import { useFetchTodoPortfolio } from "../utils/useFetchTodoPortfolio";
import { useFetchTodo } from "../utils/useFetchTodo";
import Element from "./element";
import glovar from './glovar';
import { getMONEDADivisa } from './glovar'

export default ({}) => {
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  let ARRAY_COTIS="";
  ARRAY_COTIS=glovar.ARRAY_COTIS_MORE_POPULAR;

  //const { todo, isFetching } = useFetchTodo("^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X")
  const { todo, isFetching } = useFetchTodo(ARRAY_COTIS,0)
 
    if (!isFetching) {

      return (
        
        todo.map((todo) => {
          var m=getMONEDADivisa(todo.currency,todo.quoteType);
          return (
          <Element element={todo} name={todo.shortName} price={todo.regularMarketPrice}
                   diff={todo.regularMarketChangePercent} 
                   pordiff={todo.regularMarketChange}
                   moneda={m}></Element>
          );  
        }) 
       );
      }
    //const  todoTopTraders = useFetchTodoToptraders()
}
