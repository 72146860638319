

import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import eventBus from "../eventBus";
//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

function showElement(element) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showElement", { el: element });
  //console.log('commmm pos:'+pos)
  } 

  export default ({ element, name, price, diff, pordiff, moneda }) => {

    
    //console.log(JSON.stringify(element));
    
    let color1="#1FB31F"
    let color2="#FFFFFF"

    var subtitle="subtitle"
    var title=name;
    var percent=Number(diff).toFixed(2);
    var change=Number(pordiff).toFixed(2);
    var loss=Number(diff).toFixed(2);
    var coti=price;
    var loss=(diff<0);

    var simbol="";

    try {
    if(coti<1) 
    {
      
        var text = coti.toString();
        var index = text.indexOf(".");
        if(index>=1) {
        var index2 = ((text.length - index - 1));
        if(index2>5) { coti=coti.toFixed(5)} 
        //console.log("decimaleeeeeeeeeeees:"+index2)
        }
    }
    }
    catch(Exxx) {}

    //const icon = () => { return(<FiArrowDown />); }

    if(diff>0) {
        color1="#1FB31F"
          color2="#FFFFFF"
          simbol="+"
    }
    else {
        color1="#D61A18"
        color2="#FFFFFF"
    }

    //####################//
    //# Check names      #//
    //####################//
    if(title=="IBEX 35...") {title="IBEX 35"; }
    if(title=="ESTX 50 PR.EUR") { title="EuroStoxx 50"}

    return (
        <Box m={3}  onClick={() => showElement(element)}  _hover={{ cursor: "pointer" }} /*_hover={{ cursor: "pointer" }}*/>
    <div>
    <Flex alignItems="center" height="1.8pc">
           <Box ml={0}>
        <Heading fontSize="md"  w={"10pc"} textAlign="initial">{title}</Heading>
      </Box>
      
      <HStack  w={"10pc"}>
      <Text
          fontSize="12"
          textColor={loss ? "red" : "green"}
          fontWeight="normal"
          align="right"
          w={"2.5pc"}
        >
          {simbol}{change}
        </Text>
      <Box
        borderRadius="lg"
        fontSize="13"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="1px"
        w={"4pc"}
        h={"1.5pc"}
        bg={color1}
        textColor={color2}
      >
        {simbol}{percent}%
      </Box>
      </HStack>

      <Box ml={0} mr={0}  >
      <Heading fontSize="md" textAlign="right" w="6pc">{coti}{moneda}</Heading>
      </Box>
    </Flex>
    </div>
  </Box>
    );
  };
  