import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { BiBitcoin } from "react-icons/bi";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import glovar from './glovar';
import { getMONEDADivisa  } from './glovar'
//import eventBus from "./eventBus";
import { FiUser, FiSettings, FiSearch } from "react-icons/fi";
import eventBus from "../eventBus";
import i18n from "../../i18n"
import { Avatar } from "@chakra-ui/avatar";
import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import React, { useState } from 'react';

var contadorTraders=0;
var TOPTRADER;
var MODE_FOLLOW;



function changeMenu(toptraderPos) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showTopTrader2", { tt: glovar.TOPTRADERSLIST[toptraderPos-1] });
  //console.log('commmm pos:'+pos)
  //console.log(glovar.TOPTRADERSLIST[toptraderPos])
  } 

function comm(pos) {
  //glovar.CURRENT_MENU_ID=3;
  //eventBus.dispatch("showTopTrader", { pos: (pos-1) });
  //console.log('commmm pos:'+pos)

  } 

function removeRequest(user_remove,mode) {
   //MODE_FOLLOWERS:1,
  //MODE_FOLLOWING:2,

  var MODE_FOLLOWUSER=mode;

  //console.log("MODE_FOLLOW:"+MODE_FOLLOW)
  //if(MODE_FOLLOW==glovar.MODE_FOLLOWERS) 
  if(MODE_FOLLOWUSER==glovar.MODE_PRE_FOLLOWERS) 
  {
      //console.log("borrado FOLLOWER!!!")
      try 
      {
            var headers = new Headers(); 
   
            headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
            headers.set('Content-Type', 'application/json');
   
            const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delprefollowing', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:user_remove,//glovar.USER_USERNAME,//TOPTRADER.name,
                    name_prefollowing:glovar.USER_USERNAME//user_remove//TOPTRADER.name
                 }) 
            })

            //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
            var toRemove = user_remove;//glovar.USER_USERNAME;
            var index = glovar.SHOW_MY_PROFILE.list_prefollowers.indexOf(toRemove);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
              glovar.SHOW_MY_PROFILE.list_prefollowers.splice(index, 1);
            }

            glovar.DONT_REFRESH_FETCH_TODO_USER=true;


            const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delprefollower', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:glovar.USER_USERNAME,//user_remove,//TOPTRADER.name,
                    name_prefollower:user_remove//glovar.USER_USERNAME
                 }) 
            })


           
            //console.log("prefollower OK! ");  //+glovar.CURRENT_USER.name)
            
          }
          catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
    
   //console.log("BORRADO MODE pre FOLLOWER!")
  }
  else  if(MODE_FOLLOWUSER==glovar.MODE_FOLLOWERS) 
  {
      //console.log("borrado FOLLOWER!!!")
      try 
      {
            var headers = new Headers(); 
   
            headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
            headers.set('Content-Type', 'application/json');
   
            const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delfollowing', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:user_remove,//glovar.USER_USERNAME,//TOPTRADER.name,
                    name_following:glovar.USER_USERNAME//user_remove//TOPTRADER.name
                 }) 
            })

            //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
            var toRemove = user_remove;//glovar.USER_USERNAME;
            var index = glovar.SHOW_MY_PROFILE.list_followers.indexOf(toRemove);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
              glovar.SHOW_MY_PROFILE.list_followers.splice(index, 1);
            }

            glovar.DONT_REFRESH_FETCH_TODO_USER=true;


            const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delfollower', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:glovar.USER_USERNAME,//user_remove,//TOPTRADER.name,
                    name_follower:user_remove//glovar.USER_USERNAME
                 }) 
            })


           
            //console.log("follower OK! ");  //+glovar.CURRENT_USER.name)
            
          }
          catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
    
   //console.log("BORRADO MODE pre FOLLOWER!")
  }
  //else if(MODE_FOLLOW==glovar.MODE_FOLLOWING) {
    else if(MODE_FOLLOWUSER==glovar.MODE_PRE_FOLLOWING) {

      try 
      {
            var headers = new Headers(); 
   
            headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
            headers.set('Content-Type', 'application/json');
   
            const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delprefollowing', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:glovar.USER_USERNAME,//TOPTRADER.name,
                    name_prefollowing:user_remove//TOPTRADER.name
                 }) 
            })

            //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
            var toRemove = user_remove;//glovar.USER_USERNAME;
            var index = glovar.SHOW_MY_PROFILE.list_prefollowing.indexOf(toRemove);
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
              glovar.SHOW_MY_PROFILE.list_prefollowing.splice(index, 1);
            }

            glovar.DONT_REFRESH_FETCH_TODO_USER=true;


            const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
            //glovar.URL_BACKEND_PROD
            +'/api/delprefollower', {
                  //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                  method: "PUT",  
                  headers: headers,                   
                  body: 
                  JSON.stringify({ 
                    name:user_remove,//TOPTRADER.name,
                    name_prefollower:glovar.USER_USERNAME
                 }) 
            })


            /*console.log("prefollowing OK! ");*/  //+glovar.CURRENT_USER.name)
      }
      catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }

    
      //console.log("borrado de server!!!!")

  }
  else if(MODE_FOLLOWUSER==glovar.MODE_FOLLOWING) {

    try 
    {
          var headers = new Headers(); 
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
 
          const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
          //glovar.URL_BACKEND_PROD
          +'/api/delfollowing', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                method: "PUT",  
                headers: headers,                   
                body: 
                JSON.stringify({ 
                  name:glovar.USER_USERNAME,//TOPTRADER.name,
                  name_following:user_remove//TOPTRADER.name
               }) 
          })

          //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
          var toRemove = user_remove;//glovar.USER_USERNAME;
          var index = glovar.SHOW_MY_PROFILE.list_following.indexOf(toRemove);
          if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
            glovar.SHOW_MY_PROFILE.list_following.splice(index, 1);
          }

          glovar.DONT_REFRESH_FETCH_TODO_USER=true;


          const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
          //glovar.URL_BACKEND_PROD
          +'/api/delfollower', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                method: "PUT",  
                headers: headers,                   
                body: 
                JSON.stringify({ 
                  name:user_remove,//TOPTRADER.name,
                  name_follower:glovar.USER_USERNAME
               }) 
          })


          //console.log("prefollowing OK! ");  //+glovar.CURRENT_USER.name)
    }
    catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }

  
    //console.log("borrado de server!!!!")

     }
  //console.log("00 glovar.SHOW_MY_PROFILE.list_prefollowers:"+glovar.SHOW_MY_PROFILE.list_prefollowers)

}

function addRequest(user_add,mode) {
  //MODE_FOLLOWERS:1,
 //MODE_FOLLOWING:2,

 var MODE_FOLLOWUSER=mode;

 //console.log("MODE_FOLLOW:"+MODE_FOLLOW)
 //if(MODE_FOLLOW==glovar.MODE_FOLLOWERS) 
 if(MODE_FOLLOWUSER==glovar.MODE_FOLLOWERS) 
 {
  
     //console.log("add FOLLOWER!!!")
     try 
     {
           var headers = new Headers(); 
  
           headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
           headers.set('Content-Type', 'application/json');
  
           const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
           //glovar.URL_BACKEND_PROD
           +'/api/addfollower', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 method: "PUT",  
                 headers: headers,                   
                 body: 
                 JSON.stringify({ 
                   name:glovar.USER_USERNAME,//glovar.USER_USERNAME,//TOPTRADER.name,
                   name_follower:user_add//user_remove//TOPTRADER.name
                }) 
           })

           //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
           var toRemove = user_add;//glovar.USER_USERNAME;
           var index = glovar.SHOW_MY_PROFILE.list_prefollowers.indexOf(toRemove);
           if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
             glovar.SHOW_MY_PROFILE.list_prefollowers.splice(index, 1);
           }
           glovar.SHOW_MY_PROFILE.list_followers.push(user_add)


           const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
           //glovar.URL_BACKEND_PROD
           +'/api/addfollowing', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 method: "PUT",  
                 headers: headers,                   
                 body: 
                 JSON.stringify({ 
                   name:user_add,//glovar.USER_USERNAME,//user_remove,//TOPTRADER.name,
                   name_following:glovar.USER_USERNAME//user_remove//glovar.USER_USERNAME
                }) 
           })


           glovar.DONT_REFRESH_FETCH_TODO_USER=true;

           //console.log("follower OK! ");  //+glovar.CURRENT_USER.name)
           
         }
         catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
   
  //console.log("BORRADO MODE FOLLOWER!")
 }
 //else if(MODE_FOLLOW==glovar.MODE_FOLLOWING) {
   else if(MODE_FOLLOWUSER==glovar.MODE_FOLLOWING) {

    try 
    {
          var headers = new Headers(); 
 
          headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
          headers.set('Content-Type', 'application/json');
 
          const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
          //glovar.URL_BACKEND_PROD
          +'/api/addfollowing', {
                //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                method: "PUT",  
                headers: headers,                   
                body: 
                JSON.stringify({ 
                  name:glovar.USER_USERNAME,//glovar.USER_USERNAME,//TOPTRADER.name,
                  name_following:user_add//user_remove//TOPTRADER.name
               }) 
          })

          //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
          var toRemove = user_add;//glovar.USER_USERNAME;
          var index = glovar.SHOW_MY_PROFILE.list_prefollowing.indexOf(toRemove);
          if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
            glovar.SHOW_MY_PROFILE.list_prefollowing.splice(index, 1);
          }
          glovar.SHOW_MY_PROFILE.list_following.push(user_add)
          glovar.DONT_REFRESH_FETCH_TODO_USER=true;

          const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
           //glovar.URL_BACKEND_PROD
           +'/api/addfollower', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 method: "PUT",  
                 headers: headers,                   
                 body: 
                 JSON.stringify({ 
                   name:user_add,//glovar.USER_USERNAME,//user_remove,//TOPTRADER.name,
                   name_follower:glovar.USER_USERNAME//user_remove//glovar.USER_USERNAME
                }) 
           })


          //console.log("following OK! ");  //+glovar.CURRENT_USER.name)
          
        }
        catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
   
     //console.log("add following")

 }
 //console.log("00 glovar.SHOW_MY_PROFILE.list_prefollowers:"+glovar.SHOW_MY_PROFILE.list_prefollowers)

}

/*MODE_TITLE_FOLLOWERS:5,
MODE_TITLE_FOLLOWING:6,
MODE_TITLE_PRE_FOLLOWERS:7,
MODE_TITLE_PRE_FOLLOWING:8,*/



const Wallet = ({
  icon,
  title,
  subtitle,
  type,
  percent,
  followers,
  change,
  loss,
  color1,
  color2,
  pos,
  modefollow
}) => (
  <Box mr={1} mt={0} mb={0}   >
    
    {(type==glovar.MODE_TITLE_FOLLOWERS)&&<Box backgroundColor="lightgray"  mt={0}>
    <Text fontWeight="semibold" fontSize={12}   mb={0} ml={1} textColor="black">{i18n.t("todos_seguidores")}</Text>
      </Box>}

      {(type==glovar.MODE_TITLE_FOLLOWING)&&<Box backgroundColor="lightgray">
        <Text fontWeight="semibold"  fontSize={12}  mt={2} mb={0} ml={1} pt={0} textColor="black">{i18n.t("usu_seguidos")}</Text>
      </Box>}

      {(type==glovar.MODE_TITLE_PRE_FOLLOWERS)&&<Box backgroundColor="lightgray">
        <Text fontWeight="semibold" fontSize={12}  t={0} mb={0} ml={1} pt={-1} textColor="black">{i18n.t("sol_seguimiento")}</Text>
      </Box>}

      {(type==glovar.MODE_TITLE_PRE_FOLLOWING)&&<Box backgroundColor="lightgray">
        <Text fontWeight="semibold"  fontSize={12}  t={0} mb={0} ml={1} pt={0} textColor="black">{i18n.t("peti_seguimiento")}</Text>
      </Box>}

    {(type<glovar.MODE_TITLE_FOLLOWERS)&&<Flex mt={2} mb={2}>

    
    <Avatar   w={7} h={7} _hover={{ cursor: "pointer" }} onClick= {() =>   window.location.replace('/'+title)}/>
        



           <Box ml={2} mt={0.5} w={"50%"} _hover={{ cursor: "pointer" }} onClick= {() =>   window.location.replace('/'+title)}>
        <Heading fontSize={15}>{title}</Heading>
        <Text fontSize="12" fontWeight="normal">
            {subtitle}
          </Text>
      </Box>
      

      {(type==glovar.MODE_PRE_FOLLOWERS)&&
<Button w="22%" h={7} bg="black" fontWeight="semibold" textColor="white"  fontSize={12}
          _hover={{
            background: "black",
            //color: "teal.500",
          }}
          
          onClick={() => {
            {
              try {
                addRequest(title,modefollow)

               }
               catch(Exx) {}
             
                   
              //toast.success('Has solicitado seguir a '+TOPTRADER.name, { position: toast.POSITION.TOP_RIGHT, color:"#00AA00", backgroundColor: '#AAAA00' });
              //addItemToCart()
              //if(MODE_FOLLOW==glovar.MODE_FOLLOWING) {
                if(modefollow==glovar.MODE_FOLLOWING) {
                glovar.DEFAULT_INDEX_TABS_FOLLOWS=1;
              }
              else {
                glovar.DEFAULT_INDEX_TABS_FOLLOWS=0;
              }

              eventBus.dispatch("showRefreshHomefront", { });
              //window.location.reload(true)
            }
          }}>
           {i18n.t("Aceptar")}
          </Button>}

          <Button w="22%" h={7}   ml={2} fontWeight="semibold" textColor="black"  fontSize={12} variantColor="teal" variant="outline"
          _hover={{
            background: "white",
            //color: "teal.500",
          }}
          
          onClick={() => {
            {
              try {
                removeRequest(title,type);//modefollow)

               }
               catch(Exx) {}
             
                   
              //toast.success('Has solicitado seguir a '+TOPTRADER.name, { position: toast.POSITION.TOP_RIGHT, color:"#00AA00", backgroundColor: '#AAAA00' });
              //addItemToCart()
              //if(MODE_FOLLOW==glovar.MODE_FOLLOWING) {
                if(modefollow==glovar.MODE_FOLLOWING) {
                glovar.DEFAULT_INDEX_TABS_FOLLOWS=1;
              }
              else {
                glovar.DEFAULT_INDEX_TABS_FOLLOWS=0;
              }

              eventBus.dispatch("showRefreshHomefront", { });
              //window.location.reload(true)
            }
          }}>
            {i18n.t("Eliminar")}
          </Button>
     
    </Flex>}
   
  </Box>
);


function Item(props) {
  //console.log(props.value)
  //console.log("entro en item!!!")
  //console.log(props.model.mode)

  /*
  //if(props.value.idCartera) return <li>{props.value.idCartera}</li>;
  //else return null;

  //if(props.value.stock=="Micron") { return null; }
  //if(props.value.stock=="Citigroup") { return null; }
  //if(props.value.stock=="Verisign") { return null; }
  //if(props.value.stock=="Qualcomm") { return null; }


  
  var moneda=props.value.moneda;
  //contadorTraders=contadorTraders+0.5;

  try { 
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    contadorTraders=contadorTraders+0.5;
    //console.log("developmeeeeent")
    //console.log("NODE_ENV:"+process.env.NODE_ENV)
   } else {
    contadorTraders=contadorTraders+1;
    // production code
    //console.log("producttiooon")
   }
  }
  catch(Exx) { contadorTraders=contadorTraders+1; }
  */

  //try { moneda=getMONEDADivisa(props.value.currency)} catch (Exxx) { console.log("petada moneda:"+Exxx)}

  //if(props.value.regularMarketChange>0) 
  {
   
    return (
<Wallet
          //title="TEF.MC"
          //title={glovar.TOPTRADERS_PRO1[glovar.TOPTRADERS_PRO1.length-2].idCartera}
          //title={props.value.idCartera}
          modefollow={props.model.mode}
          type={props.value.type}
          pos={contadorTraders}
          title={props.value.title}
          subtitle={props.value.symbol}
          icon={<FiArrowUp />}
          //change={"+"+Number(props.value.profit).toFixed(0)+moneda}
          //percent={Number(props.value.rentab).toFixed(2)+"%"}
          followers={props.value.followers}
          //loss
          color1="#1FB31F"
          color2="#FFFFFF"
        />
    );
  }

}


/*function MyList() {

  return (
   <ul>
     
       {glovar.TOPTRADERSLIST.map((item) => <Item key={item.id} value={item} />)}

     
   </ul>
 );
}*/

function MyContactList(mode) {

  contadorTraders=0;
  //console.log("mode:"+JSON.stringify(mode))
  
  //MODE_FOLLOWERS:1,
  //MODE_FOLLOWING:2,
  /*
  var ContactList=TOPTRADER.list_prefollowers;
  ContactList.push(TOPTRADER.list_followers);
  if(MODE_FOLLOW==2) {
    ContactList=TOPTRADER.list_prefollowing;
    if(TOPTRADER.list_following.length>0) { ContactList.push(TOPTRADER.list_following); }
  }*/

  var ContactList=[];

  /*MODE_TITLE_FOLLOWERS:5,
   MODE_TITLE_FOLLOWING:6,
   MODE_TITLE_PRE_FOLLOWERS:7,
   MODE_TITLE_PRE_FOLLOWING:8,*/

  

  if(MODE_FOLLOW==1) {
    if(TOPTRADER.list_prefollowers.length>0) {
      let objeto = { title:"", type: glovar.MODE_TITLE_PRE_FOLLOWERS}
      ContactList.push(objeto)
    }

   TOPTRADER.list_prefollowers.forEach(function(item) {
    let objeto = { title: item, type: glovar.MODE_PRE_FOLLOWERS}
    //var feed = {created_at: "2017-03-14T01:00:32Z", entry_id: 33358, field1: "4", field2: "4", field3: "0"};
    ContactList.push(objeto)
  })

  if(TOPTRADER.list_followers.length>0) {
    let objeto = { title:"", type: glovar.MODE_TITLE_FOLLOWERS}
    ContactList.push(objeto)
  }

   TOPTRADER.list_followers.forEach(function(item) {
    let objeto = { title: item, type:  glovar.MODE_FOLLOWERS}
    ContactList.push(objeto)
  })
  }
  else if(MODE_FOLLOW==2) {

    if(TOPTRADER.list_prefollowing.length>0) {
      let objeto = { title:"", type: glovar.MODE_TITLE_PRE_FOLLOWING}
      ContactList.push(objeto)
    }

    TOPTRADER.list_prefollowing.forEach(function(item) {
      let objeto = { title: item, type: glovar.MODE_PRE_FOLLOWING}
      ContactList.push(objeto)
  })

  if(TOPTRADER.list_following.length>0) {
    let objeto = { title:"", type: glovar.MODE_TITLE_FOLLOWING}
    ContactList.push(objeto)
  }

  TOPTRADER.list_following.forEach(function(item) {
    let objeto = { title: item, type: glovar.MODE_FOLLOWING}
    ContactList.push(objeto)
  })
  }


  //console.log("ContactList:"+ContactList)

  if(!ContactList)
  {
    return null;
  }

  {
  for (let i=0; i<ContactList.length;i++)
  {
    return (
      ContactList.map((item) => <Item key={item.id} value={item} model={mode} />)
    );

  }
}
}

export default({ toptrader, modelist }) => {

  //Por defecto dejamos que sea siempre el de la izquierda (una vez se ha pintado inicialmente el de la derecha)
  glovar.DEFAULT_INDEX_TABS_FOLLOWS=0;

  //console.log(modelist)
  TOPTRADER=toptrader;
  MODE_FOLLOW= modelist;
  try {
  return (
   
    
      <div class="row container_contacts">
        <MyContactList mode={modelist}/>
        </div>
        
          
       
        
      
        
     
   
  );
  }
  catch(Exx) { return null}
};
