import { Button } from "@chakra-ui/button";
import { Heading } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import glovar from './glovar';
import { FiUser, FiMenu, FiCheck, FiSettings, FiSearch } from "react-icons/fi";
import { Flex } from "@chakra-ui/layout";
import { SimpleGrid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import i18n from '../../i18n'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eventBus from "../eventBus";
import React, { useState } from 'react';
import { Tooltip } from '@chakra-ui/react'

import {  AdComponent468,AdComponent600, AdComponent320 } from '../../utils/Common';


export default ({ toptrader,toptraderpos, current_portfolio_ED, hide_follow, hideProfile, hideData, hidefollow,mobile}) => {

  //var TOPTRADER=glovar.TOPTRADERS_PRO1[toptraderpos];

  //var TOPTRADER={ name:"PeterSMITH", profit: 12031, countriesID:"0", op_cerradas:18, op_win:9, followers:786,  rentab:16.55, profit:566.78, id:1, capital:100000, moneda:"€", 
  //rentmedia_op:18.55, invertido:88.74 };


 
  var OWN_USER=hide_follow;
  var TOPTRADER=toptrader;
  var STATUS_FOLLOW=0;

  //try { console.log("TOPTRADER.list_prefollowers:"+TOPTRADER.list_prefollowers) } catch(Exxx) {}
  //try { console.log("glovar.SHOW_MY_PROFILE.list_prefollowers:"+glovar.SHOW_MY_PROFILE.list_prefollowers) } catch(Exxx) {}

  try { if(TOPTRADER.list_prefollowers.includes(glovar.USER_USERNAME)) {  STATUS_FOLLOW=1; /*console.log("STATUS_FOLLOW:"+STATUS_FOLLOW);*/ } } catch(Exxx) {}
  //if(TOPTRADER.list_followers.includes(glovar.USER_USERNAME)) { STATUS_FOLLOW=2; }
 
  //if(status_followed>0) { STATUS_FOLLOW=status_followed; } else { STATUS_FOLLOW=STATUS_FOLLOW; }
  //console.log(TOPTRADER)

  var simbol="+";
  if(TOPTRADER.profit<0) {simbol=""; }

  var AVATAR_SRC='/assets/'+glovar.AVATAR_TOPTRADERS[TOPTRADER.id-1];
  //var AVATAR_SRC='/assets/pro1.jpg';

  //console.log("TOPTRADER:"+TOPTRADER.id)
  //console.log("AVATAR_SRC:"+AVATAR_SRC)

  var colorText="black";
  var colorTextData="black";
  var colorRentab=(TOPTRADER.rentab>=0) ? "#1FB31F" : "#D61A18";


  var item_capital=(TOPTRADER.capital+TOPTRADER.moneda)
  var item_invertido="+"+Number(TOPTRADER.invertido).toFixed(2);
  var item_benef=simbol+Number(TOPTRADER.profit).toFixed(2);
  var item_rentab=Number(TOPTRADER.rentmedia_op).toFixed(2);
  var item_btnfollow="black"
  var follow_enabled=true;


  var MOBILE_VERSION=0;
  var OFFSET_COL=0;

  var MOBILE_UI=0;
  if(mobile==1) {
    MOBILE_VERSION=true;
    MOBILE_UI=1;
    OFFSET_COL=2;
  }

  if(TOPTRADER.rentmedia_op>0) {
    item_rentab="+"+item_rentab
  }

  if(hideProfile) { 
    colorText="#CCCCCC"
    colorRentab="#CCCCCC"
    colorTextData="#CCCCCC"
  }

  if(hideData || hideProfile) {
    //colorTextData="#CCCCCC"
    item_capital=TOPTRADER.moneda
    item_invertido=""
    item_benef=""
    item_rentab=""
  }

  if(hidefollow || hideProfile) {
    item_btnfollow="#CCCCCC"
    follow_enabled=false;
   }
  
   let NO_MOBILE_NO_HIDE= new Boolean((!MOBILE_VERSION&&!hideProfile));
   let MOBILE_NO_HIDE = (MOBILE_VERSION&&!hideProfile);


  return (
    <Box p={2} borderRadius="3xl" bg="white" textColor={colorText}>
      <SimpleGrid columns={14} gap={0}v bg="white">
      <GridItem colSpan={2} bg="white">
        {NO_MOBILE_NO_HIDE==true&&<Avatar src={AVATAR_SRC}  w={20} h={20} />}
        {MOBILE_NO_HIDE==true&&<Avatar src={AVATAR_SRC}  w={14} h={14} />}
        
      
      </GridItem>

            <GridItem colSpan={10-OFFSET_COL} bg="white">
            {!MOBILE_VERSION&&<Heading mt={0}> {TOPTRADER.name}</Heading>}
            {(MOBILE_VERSION==true)&&<Text ml={2} fontSize={17} fontWeight="bold" > {TOPTRADER.name}</Text>}

      <HStack w="5pc" spacing="0.5" mt="0.5pc" mb="0.5pc">
      {(TOPTRADER.countriesID.length>0)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[0]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>1)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[1]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>2)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[2]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>3)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[3]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>4)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[4]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>5)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[5]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>6)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[6]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>7)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[7]]} w={7}></Image>}
      {(TOPTRADER.countriesID.length>8)&&<Image src={glovar.FLAGS_COUNTRIES_ID[TOPTRADER.countriesID[8]]} w={7}></Image>}
      </HStack>
      

      
      {MOBILE_UI==0&&<Flex alignItems="center">
      <Box w="10%"><Flex alignItems="center"><FiMenu/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.op_cerradas}</Text></Flex></Box>
      <Box w="10%"><Flex alignItems="center"><FiCheck/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.op_win}</Text></Flex></Box>
      <Box w="10%"><Flex alignItems="center"><FiUser/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.followers}</Text></Flex></Box>
      </Flex>}

      {MOBILE_UI==1&&<Flex alignItems="center">
      <Box w="20%"><Flex alignItems="center"><FiMenu/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.op_cerradas}</Text></Flex></Box>
      <Box w="20%"><Flex alignItems="center"><FiCheck/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.op_win}</Text></Flex></Box>
      <Box w="20%"><Flex alignItems="center"><FiUser/><Text fontSize="14" fontWeight="bold" ml={1} >{TOPTRADER.followers}</Text></Flex></Box>
      </Flex>}

      </GridItem> 
      
      <GridItem colSpan={2+OFFSET_COL} bg="white"  >
      <Box
        borderRadius="lg"
        fontSize="17"
        ml={"0pc"}
        fontWeight="bold"
        textAlign="center"
        w={"5.5pc"}
        
        
        p={1}
        bg={colorRentab}//{(TOPTRADER.rentab>=0) ? "#1FB31F" : "#D61A18"}
        textColor="white"
      >
        {(TOPTRADER.rentab>=0) ? "+" : "-"}{TOPTRADER.rentab.toFixed(2)}%
      </Box>
     
            </GridItem>

      </SimpleGrid>
      
      {(glovar.MODE_USER<2)&&
      <HStack  background="lightgray"  marginLeft="20px" marginRight="20px" rounded={9} mt="0.7pc" justifyContent='space-around'>
        {MOBILE_UI==0&&<AdComponent468/>}
        {MOBILE_UI==1&&<AdComponent320/>}
        </HStack>}
        
      <HStack mt={4} color={colorTextData}>
      <Box w="25%">
          <Heading size="sms">{item_capital}</Heading>
          <Text fontSize="xs" >{i18n.t("capi_total")}</Text>
        </Box>
        <Box w="25%">
          <Heading size="sms">{item_invertido}%</Heading>
          <Text fontSize="xs">{i18n.t("invertido")}</Text>
        </Box>
        <Box w="25%">
          <Heading size="sms">{item_benef}{TOPTRADER.moneda}</Heading>
          <Text fontSize="xs">{i18n.t("profitloss")}</Text>
        </Box>
        <Box w="25%">
          <Heading size="sms">{item_rentab}%</Heading>
          <Text fontSize="xs">{i18n.t("rent_media")}</Text>
        </Box>
      </HStack>

      {/* Buttons */}
      {!OWN_USER&&<HStack mt={4}>
        <Box w="100%">
          {(STATUS_FOLLOW==0)&&<Button w="full" bg={item_btnfollow} fontWeight="semibold" textColor="white" disabled={!follow_enabled} 
          _hover={{
            background: "black",
            //color: "teal.500",
          }}
          
          onClick={() => {
            if(glovar.MODE_USER==0) {

              try {
                eventBus.dispatch("btnFollow_m0", { data: glovar.USER_LANG_PRO1  });
              }
              catch(Exx) {}
             
              <ToastContainer  toastStyle={{ backgroundColor: '#AA0000' }} theme="colored"/>
            toast.error('Para poder seguir a un usuario primero debes iniciar sesión.', { position: toast.POSITION.TOP_RIGHT, color:"red"});
            }
            else {
              
              try {
                eventBus.dispatch("btnFollow_m1", { data1: glovar.USER_USERNAME, data2: TOPTRADER.name   });
              }
              catch(Exx) {}
             

              <ToastContainer  toastStyle={{ backgroundColor: '#AAAA00' }} theme="colored"/>
              STATUS_FOLLOW=1;

                try 
                {
                      var headers = new Headers(); 
             
                      //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
                      //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)
             
                      headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
                      headers.set('Content-Type', 'application/json');
             
                      const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
                      //glovar.URL_BACKEND_PROD
                      +'/api/addprefollowing', {
                            //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                            method: "PUT",  
                            headers: headers,                   
                            body: 
                            JSON.stringify({ 
                              name:glovar.USER_USERNAME,//TOPTRADER.name,
                              name_prefollowing:TOPTRADER.name
                           }) 
                      })

                      try { glovar.SHOW_MY_PROFILE.list_prefollowing.push(TOPTRADER.name) } catch(Exx) {}
                      glovar.DONT_REFRESH_FETCH_TODO_USER=true;

                      const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
                      //glovar.URL_BACKEND_PROD
                      +'/api/addprefollower', {
                            //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                            method: "PUT",  
                            headers: headers,                   
                            body: 
                            JSON.stringify({ 
                              name:TOPTRADER.name,
                              name_prefollower:glovar.USER_USERNAME
                           }) 
                      })

                      try { glovar.SHOW_MY_PROFILE.list_prefollowers.push(glovar.USER_USERNAME) } catch(Exx) {} 
                      glovar.DONT_REFRESH_FETCH_TODO_USER=true;
 
                      
             
                      //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
                }
                catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
                   
                   
              //toast.success('Has solicitado seguir a '+TOPTRADER.name, { position: toast.POSITION.TOP_RIGHT, color:"#00AA00", backgroundColor: '#AAAA00' });
              //addItemToCart()
              eventBus.dispatch("showRefreshHomefront", { });
              //window.location.reload(true)
            }
          }}>
            Follow
          </Button>}

          {(STATUS_FOLLOW==1)&&<Tooltip hasArrow label='Eliminar petición de seguimiento' bg='gray'><Button w="full" bg="gray" fontWeight="semibold" textColor="white" //disabled={!follow_enabled} 
          _hover={{ cursor: "pointer", bg:"gray",  textColor:"white" }}
          
          onClick={() => {
            STATUS_FOLLOW=0;
            //remove the following petition
            try 
            {
                  var headers = new Headers(); 
         
                  headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
                  headers.set('Content-Type', 'application/json');
         
                  const response = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
                  //glovar.URL_BACKEND_PROD
                  +'/api/delprefollowing', {
                        //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                        method: "PUT",  
                        headers: headers,                   
                        body: 
                        JSON.stringify({ 
                          name:glovar.USER_USERNAME,//TOPTRADER.name,
                          name_prefollowing:TOPTRADER.name
                       }) 
                  })

                  //try { glovar.SHOW_MY_PROFILE.list_prefollowing.pull(TOPTRADER.name) } catch(Exx) {}
                  var toRemove = glovar.USER_USERNAME;
                  var index = glovar.SHOW_MY_PROFILE.list_prefollowing.indexOf(toRemove);
                  if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                    glovar.SHOW_MY_PROFILE.list_prefollowing.splice(index, 1);
                  }

                  glovar.DONT_REFRESH_FETCH_TODO_USER=true;


                  const response2 = fetch(glovar.URL_BACKEND_PROD//"http://localhost:3010"
                  //glovar.URL_BACKEND_PROD
                  +'/api/delprefollower', {
                        //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                        method: "PUT",  
                        headers: headers,                   
                        body: 
                        JSON.stringify({ 
                          name:TOPTRADER.name,
                          name_prefollower:glovar.USER_USERNAME
                       }) 
                  })


                  //try { glovar.SHOW_MY_PROFILE.list_prefollowers.pull(glovar.USER_USERNAME) } catch(Exx) { console.log("petada pull:"+Exx)}

                  var toRemove = glovar.USER_USERNAME;
                  var index = glovar.SHOW_MY_PROFILE.list_prefollowers.indexOf(toRemove);
                  if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                    glovar.SHOW_MY_PROFILE.list_prefollowers.splice(index, 1);
                  }
                  //console.log("00 glovar.SHOW_MY_PROFILE.list_prefollowers:"+glovar.SHOW_MY_PROFILE.list_prefollowers)
                  glovar.DONT_REFRESH_FETCH_TODO_USER=true;

         
                  //console.log("prefollowing OK! ");  //+glovar.CURRENT_USER.name)
            }
            catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }


            eventBus.dispatch("showRefreshHomefront", { });
          }}>
           Waiting for accept
          </Button></Tooltip>}
          



        </Box>

        <ToastContainer/>
        
      </HStack>}

      
    </Box>
  );
};
