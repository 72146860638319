

import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { HStack } from "@chakra-ui/layout"; 
//import { VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";
import glovar from './glovar';
//import { getMONEDADivisa  } from './glovar'

function showElement(element) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showElement", { el: element });
  //console.log('commmm pos:'+pos)
  } 

  export default ({Toptrader, index, grayScale, hidetit, mobilever }) => {

    var MOBILE_VERSION=mobilever;
    var contadorTraders=0;
var SHOW_SCREEN_OPTIONS=false;
var POS_SHOW_OPTIONS=0;

var offsetmobile=0;
if(MOBILE_VERSION) {
  offsetmobile=2;
}
try { if(Toptrader.stock) {Toptrader.title=Toptrader.stock} } catch(Exxx) {}
//try { if(Toptrader.stock) {Toptrader.title=Toptrader.stock}

//Toptrader.title="topoer"
    /*const Wallet = ({*/
      //icon,
      let title="title";
      let acciones=15600;
      let sentido=1;
      //let subtitle="subtitle";
      let salida=45.69;
      let entrada=16.55;
      let benef=566.78;
      let rent=4.55;
      //let coti=36.78;
      //let change,
      //let loss,
      let obj=99.99;
      let stop=6.67;
      //color1,
      //color2,
      var fent="09-09-22";
      var pos=index;
      var fsal="09-09-22";
    /*}) =>*/
    
     let moneda=""

     try { moneda=Toptrader.m} catch(Exception) {}
     

    let diff=Toptrader.benef;//1
    
    let posranking=index+1;
    
    let color1="#1FB31F"
    let color2="#FFFFFF"

    var subtitle="subtitle"
    //var title=name;
    //var percent=Number(diff).toFixed(2);
    //var change=Number(diff).toFixed(2);
    //var loss=Number(diff).toFixed(2);
    //var coti=3444; //price;
    var loss=(diff<0);

    var coti=0;
    
    

    if(!Toptrader.json) {
      coti=0;
    }
    else {
      try { if(Toptrader.json.regularMarketPrice) { coti=Toptrader.json.regularMarketPrice; } } catch(Exxx) {}
    }

    try { benef=Toptrader.benef.toFixed(0); } catch(Exxx) {}
    try { rent=Toptrader.rent.toFixed(2); } catch(Exxx) {}
    
    let simbol="";
    if(rent>=0) simbol="+"; else simbol=" ";

    let colorTitle="#000000";
    let colorText="black"
    //let coti=0;

    //const icon = () => { return(<FiArrowDown />); }

    if(diff>0) {
        color1="#1FB31F"
          color2="#FFFFFF"
    }
    else {
        color1="#D61A18"
        color2="#FFFFFF"
    }

    if(grayScale) {
      color1="#CCCCCC"
        color2="#FFFFFF"
        colorTitle="#CCCCCC"
        colorText="gray.100"
    }

    var toptraderTit=Toptrader.acciones;
    if(hidetit) {
      toptraderTit=""
      benef=""
    }

    return(
      <Box mr={0} mt={0} mb={0} color={colorText} _hover={{ cursor: "pointer" }} onClick={() => showElement(Toptrader.json)}>
        <Flex alignItems="center"  ml={0} mr={2-offsetmobile} mb={3}>
             
    
          <Box ml={0} w={"13pc"}>
              
          {MOBILE_VERSION&&<Heading fontSize="sm"  w={"8pc"}>{Toptrader.title}</Heading>}
          {!MOBILE_VERSION&&<Heading fontSize="md"  w={"10pc"}>{Toptrader.title}</Heading>}

              <Text fontSize="13" fontWeight="thin">
                {Toptrader.fent}
              </Text>
              {MOBILE_VERSION&&<Box
            borderRadius="lg"
            fontSize="11"
            fontWeight="semibold"
            textAlign="center"
            mr={4}
            w={"3pc"}
            p={0.5}
            bg={colorTitle}
            textColor="#FFFFFF"
          >
             {Toptrader.sentido==2 ? "-"+toptraderTit : "+"+toptraderTit}
          </Box>}
            </Box>
          
            
    
    
            {!MOBILE_VERSION&&<Box
            borderRadius="lg"
            fontSize="12"
            fontWeight="semibold"
            textAlign="center"
            mr={4}
            w={"4pc"}
            p={1}
            bg={colorTitle}
            textColor="#FFFFFF"
          >
             {Toptrader.sentido==2 ? "-"+toptraderTit : "+"+toptraderTit}
          </Box>}
          
          <Box ml={0} w={"6pc"} >
            <Text fontSize="15" textAlign="center" fontWeight="normal">
            {Toptrader.entrada}{moneda}
            {MOBILE_VERSION&&<Text fontSize="15" textAlign="center" fontWeight="bold">
              {coti}{moneda}
            </Text>}
            </Text>
          </Box>
         
          <Box   ml={0} w={"7pc"} >
          {!MOBILE_VERSION&&<Text fontSize="15" textAlign="center" fontWeight="bold">
              {coti}{moneda}
            </Text>}
          </Box>
          <Spacer />


          {!MOBILE_VERSION&&<Box textAlign={"center"} mr={0} w={"10pc"}>
            <Text fontSize="14" fontWeight="normal">
              {Toptrader.obj}{moneda} - {Toptrader.stop}{moneda}
            </Text>
                  </Box>}

                  {MOBILE_VERSION&&<Box textAlign={"center"}   mr={0} w={"10pc"}>
            <Text fontSize="14" fontWeight="normal"> {Toptrader.obj}{moneda}  </Text>
            <Text fontSize="14" fontWeight="normal"> {Toptrader.stop}{moneda}</Text>
            
                  </Box>}
                  <Spacer />
          <Box
            borderRadius="lg"
            fontSize="14"
            fontWeight="semibold"
            textAlign="center"
            w={"10.5pc"}
            minW={"5.5pc"}
            p={1}
            ml={0}
            bg={color1}
            textColor={color2}
          >
            {simbol}{benef}{Toptrader.m}     {simbol }{rent}%
          </Box>
        </Flex>
    
        {(SHOW_SCREEN_OPTIONS && POS_SHOW_OPTIONS==pos )&&<Flex alignItems="center">
             
    
      
    
        {!glovar.APP_STATE_RUNNING&&<Box
            _hover={{ cursor: "pointer" }}
            p={1.5}
            m={1}
            marginTop={2}
            ml={0}
            borderRadius="md"
            bg="gray"//bg="#000000"
            textColor="white"
            //onClick={() => showEditPos(pos)}
            
          >
            <Image src={"assets/ic_pencil_white_24dp.png"} w={6}></Image>
          </Box>}
          {!glovar.APP_STATE_RUNNING&&<Box
            _hover={{ cursor: "pointer" }}
            p={1.5}
            m={1}
            marginTop={2}
            ml={2}
            borderRadius="md"
            bg="gray"
            textColor="white"
            //onClick={() => showClosePos(pos)}
          >
            <Image src={"assets/ic_exit_to_app_white_24dp.png"} w={6}></Image>
          </Box>}
    
    
          {glovar.APP_STATE_RUNNING&&<Box
            p={1.5}
            m={1}
            marginTop={2}
            ml={0}
            borderRadius="md"
            bg="#DDDDDD"
            textColor="white"
          >
            <Image src={"assets/ic_pencil_white_24dp.png"} w={6}></Image>
          </Box>}
          {glovar.APP_STATE_RUNNING&&<Box
            p={1.5}
            m={1}
            marginTop={2}
            ml={2}
            borderRadius="md"
            bg="#DDDDDD"
            textColor="white"
          >
            <Image src={"assets/ic_exit_to_app_white_24dp.png"} w={6}></Image>
          </Box>}
    
          {glovar.APP_STATE_RUNNING&&<Text style={{fontSize:"0.9em", fontWeight:"normal", marginLeft:"1pc", textAlign:"center",  alignContent:"center"}}>
          INFO: Para poder editar tu portfolio es necesario que salgas de la APP.
        </Text>}
    
    
         </Flex>
            }
      </Box>
    
    
    
    );
  };
  